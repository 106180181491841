import * as React from 'react';
const SvgSourceCredits = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    viewBox='0 0 15 15'
    {...props}
  >
    <path
      fill="#581845"
      d="M9.001 7.698q-.146.19-.249.403a3 3 0 0 0-.175.454 4.7 4.7 0 0 0-1.385-.784A4.6 4.6 0 0 0 5.625 7.5q-.645 0-1.245.168a4.9 4.9 0 0 0-1.12.47q-.52.3-.946.732a5 5 0 0 0-.732.952 4.67 4.67 0 0 0-.645 2.366H0q0-.879.256-1.692.257-.813.74-1.502A6 6 0 0 1 2.14 7.771q.66-.534 1.494-.842a3.6 3.6 0 0 1-.733-.6q-.321-.345-.556-.763A3.4 3.4 0 0 1 2 4.695a4.6 4.6 0 0 1-.125-.945q0-.776.293-1.458t.806-1.186Q3.487.6 4.167.3 4.85 0 5.625 0a3.701 3.701 0 0 1 2.644 1.099q.506.512.806 1.193.3.682.3 1.458 0 .483-.117.945a3.5 3.5 0 0 1-.352.871 4 4 0 0 1-.556.755 3.8 3.8 0 0 1-.74.608A5.7 5.7 0 0 1 9 7.698M2.813 3.75q0 .585.22 1.091.219.505.6.894a2.76 2.76 0 0 0 1.992.827q.58 0 1.091-.22.513-.219.894-.6.38-.381.608-.893a2.6 2.6 0 0 0 .22-1.099q0-.58-.22-1.091a2.82 2.82 0 0 0-1.502-1.502 2.6 2.6 0 0 0-1.091-.22q-.585 0-1.091.22a2.9 2.9 0 0 0-.894.6 2.7 2.7 0 0 0-.608.902q-.22.52-.22 1.091m11.25 6.563V15H8.436v-4.687h.938v-.938q0-.388.146-.725.147-.338.403-.6.256-.263.594-.404.336-.138.732-.146.388 0 .725.146.338.147.6.403.264.256.404.594.138.336.146.732v.938zm-3.75 0h1.874v-.938a.9.9 0 0 0-.27-.66 1 1 0 0 0-.3-.204.8.8 0 0 0-.367-.073.9.9 0 0 0-.66.27 1 1 0 0 0-.204.3.8.8 0 0 0-.073.367zm2.812.937h-3.75v2.813h3.75z"
    />
  </svg>
);
export default SvgSourceCredits;
