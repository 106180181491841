import classNames from 'classnames/bind';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import TooltipLite from 'react-tooltip-lite';
import {
  jobCreateNonDs,
  meetingScheduler,
  reports,
} from '../../../appRoutesPath';
import SvgRight1 from '../../../icons/SvgRight1';
import SvgCommunication from '../../../icons/SvgCommunication';
import SvgDashboard from '../../../icons/SvgDashboard';
import SvgExpand from '../../../icons/SvgExpant';
import SvgJobPost from '../../../icons/SvgJobPost';
import SvgSetting from '../../../icons/SvgSetting';
import SvgMyAccount from '../../../icons/SvgMyAccount';
import SvgUserSearch from '../../../icons/SvgUserSearch';
import SvgSubcriptioncrown from '../../../icons/Subscriptioncrown';
import { LEAVE_THIS_SITE } from '../../constValue';
import Button from '../../../uikit/Button/Button';
import { routesPath } from '../../../routes/routesPath';
import { AppDispatch, RootState } from '../../../store';
import Flex from '../../../uikit/Flex/Flex';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import { isEmpty } from '../../../uikit/helper';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Text from '../../../uikit/Text/Text';
import SvgCollapse from '../../../icons/Svgcollapse';
import SvgCircle from '../../../icons/SvgCircle';
import { permissionMiddleWare } from '../../Login/store/middleware/loginMiddleWare';
import { manageSubscriptionMiddleWare } from '../../accountsettingsmodule/managesubscription/store/managesubscriptionmiddleware';
import {
  backendProcessMiddleWare,
  creditsPurchaseMiddleWare,
} from '../../ordersummarymodule/store/ordersummarymiddleware';
import { tourdataget } from '../../tour/store/middleware/middleware';
import SvgCanSourcing from '../../../icons/SvgCanSourcing';
import SvgSetupIcon from '../../../icons/SvgSetupIcon';
import SvgReport from '../../../icons/SvgReport';
import SvgDocument from '../../../icons/SvgDocument';
import SvgSupport from '../../../icons/SvgSupport';
import SvgHelp from '../../../icons/SvgHelp';
import SvgJobTitleIcon from '../../../icons/SvgJobTitleIcon';
import SvgViewJobs from '../../../icons/SvgViewJobs';
import SvgApplicationTour from '../../../icons/applicationtour';
import SvgTimerClock from '../../../icons/SvgTimerClock';
import styles from './notification.module.css';
import { SubsriptionMiddleWare } from './store/navbarmiddleware';

const cx = classNames.bind(styles);
type props = {
  changes: (arg: boolean) => void;
  data: () => void;
  setopensubcription?: (val: any) => void;
  isBulktab?: any;
  settour?: any;
  tour?: any;
  isCandidateTab?: any;
  setCandidateTab?: any;
};

const Sidebar = ({
  changes,
  data,
  setopensubcription,
  isBulktab,
  settour,
  tour,
  isCandidateTab,
  setCandidateTab,
}: props) => {
  const dispatch: AppDispatch = useDispatch();
  const version = process.env.REACT_APP_VERSION;
  const [isjdavailablecount, setjdavailablecount] = useState(2);

  //const [Select, setSelect] = useState('/');
  const [Expent, setExpent] = useState();
  const { pathname } = useLocation();
  const changeurl = sessionStorage.getItem('changingurl');
  const [checkplan, setcheckplan] = useState(false);
  const [isbulktabhandle, setbulktabhandle] = useState(null);
  const [isCandidatetabhandle, setCandidatetabhandle] = useState(null);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 43,
  );
  const [menuHeight, setmenuHeight] = useState(window.innerHeight - 143);
  const [Freebann, setFreebann] = useState(true);

  // useEffect(()=> {
  //   if (expire_details?.plan_id === 1 || plan_id) {

  //   }
  // })

  // {console.log('Tab Navigation ===> ', isCandidateTab)}
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 43);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize1 = () => {
      setmenuHeight(window.innerHeight - 143);
    };
    window.addEventListener('resize', handleResize1);
    return () => {
      window.removeEventListener('resize', handleResize1);
    };
  }, []);

  useEffect(() => {
    setbulktabhandle((prevIsBulktab) => {
      if (isBulktab === '2') {
        return prevIsBulktab;
      } else {
        return isBulktab;
      }
    });
  }, [isBulktab]);

  useEffect(() => {
    setCandidatetabhandle(isCandidateTab);
  }, [isCandidateTab]);

  {
    console.log('isCandidateTab', isCandidatetabhandle);
  }

  // PopupDropdown Set State

  const [isJobsPopupDropdownOpen, setJobsPopupDropdownOpen] = useState(false);
  const [isViewJobsPopupDropdownOpen, setViewJobsPopupDropdownOpen] =
    useState(false);
  const [isCandiPopupDropdownOpen, setCandiPopupDropdownOpen] = useState(false);
  const [isSourcePopupDropdownOpen, setSourcePopupDropdownOpen] =
    useState(false);
  const [isCommPopupDropdownOpen, setCommPopupDropdownOpen] = useState(false);
  const [isBrandPopupDropdownOpen, setBrandPopupDropdownOpen] = useState(false);
  const [isMyaccPopupDropdownOpen, setMyaccPopupDropdownOpen] = useState(false);

  // SessionStorage Values and Dropdown Set states for DropDown menus

  const initialIsJobsDropdownOpen =
    sessionStorage.getItem('JobsDropdown') === '1';
  const [isJobsDropdownOpen, setJobsDropdownOpen] = useState(
    initialIsJobsDropdownOpen,
  );

  const initialIsViewJobsDropdownOpen =
    sessionStorage.getItem('ViewJobsDropdown') === '1';
  const [isViewJobsDropdownOpen, setViewJobsDropdownOpen] = useState(
    initialIsViewJobsDropdownOpen,
  );

  const initialIsSourcingDropdownOpen =
    sessionStorage.getItem('SourcingCandidateDropdown') === '1';
  const [isSourcingDropdownOpen, setSourcingDropdownOpen] = useState(
    initialIsSourcingDropdownOpen,
  );

  const initialIsCandiDropdownOpen =
    sessionStorage.getItem('CandidateDropdown') === '1';
  const [isCandiDropdownOpen, setCandiDropdownOpen] = useState(
    initialIsCandiDropdownOpen,
  );

  const initialIsCommDropdownOpen =
    sessionStorage.getItem('CommunicationDropdown') === '1';
  const [isCommDropdownOpen, setCommDropdownOpen] = useState(
    initialIsCommDropdownOpen,
  );

  const initialIsBrandDropdownOpen =
    sessionStorage.getItem('BrandDropdown') === '1';
  const [isBrandDropdownOpen, setBrandDropdownOpen] = useState(
    initialIsBrandDropdownOpen,
  );

  const initialIsMyaccDropdownOpen =
    sessionStorage.getItem('MyAccountDropdown') === '1';
  const [isMyaccDropdownOpen, setMyaccDropdownOpen] = useState(
    initialIsMyaccDropdownOpen,
  );
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isTooltipOpensetting, setIsTooltipOpensetting] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredsetting, setIsHoveredsetting] = useState(false);

  // applying delay time for tooltip opening.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isHovered) {
      // Set a timeout to open the tooltip after the delay
      timeout = setTimeout(() => {
        setIsTooltipOpen(true);
      }, 1000); // 1 seconds delay
    } else {
      // Clear the timeout if the cursor leaves the component
      clearTimeout(timeout);
      // Close the tooltip immediately
      setIsTooltipOpen(false);
    }

    // Cleanup function to clear the timeout when the component unmounts or is updated
    return () => clearTimeout(timeout);
  }, [isHovered]);

  // applying delay time for tooltip opening.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isHoveredsetting) {
      // Set a timeout to open the tooltip after the delay
      timeout = setTimeout(() => {
        setIsTooltipOpensetting(true);
      }, 1000); // 1 seconds delay
    } else {
      // Clear the timeout if the cursor leaves the component
      clearTimeout(timeout);
      // Close the tooltip immediately
      setIsTooltipOpensetting(false);
    }

    // Cleanup function to clear the timeout when the component unmounts or is updated
    return () => clearTimeout(timeout);
  }, [isHoveredsetting]);

  // handle function for when the cursor enter into the children they set as true.
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseEntersetting = () => {
    setIsHoveredsetting(true);
  };

  // handle function for when the cursor leave from the children they set as false.
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseLeavesetting = () => {
    setIsHoveredsetting(false);
  };
  // dispatching the API's when the user buy the add-on
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const history = useHistory();
  const session: any = query.get('session');
  const session_id: any = query.get('session_id');

  const [tourstate, settourstate] = useState(true);

  useEffect(() => {
    if (status) {
      if (tour === 'Build Your Careers Page') {
        setBrandDropdownOpen(true);
        setJobsDropdownOpen(false);
        setViewJobsDropdownOpen(false);
        setCandiDropdownOpen(false);
        setCommDropdownOpen(false);
        setMyaccDropdownOpen(false);
      } else if (tour === 'Profile') {
        setBrandDropdownOpen(false);
        setJobsDropdownOpen(false);
        setViewJobsDropdownOpen(false);
        setCandiDropdownOpen(false);
        setCommDropdownOpen(false);
        setMyaccDropdownOpen(true);
      } else if (tour === 'Integrations') {
        setBrandDropdownOpen(false);
        setJobsDropdownOpen(false);
        setViewJobsDropdownOpen(false);
        setCandiDropdownOpen(false);
        setCommDropdownOpen(true);
        setMyaccDropdownOpen(false);
      } else if (tour === 'Hiring Workflow') {
        setBrandDropdownOpen(false);
        setJobsDropdownOpen(true);
        setViewJobsDropdownOpen(false);
        setCandiDropdownOpen(false);
        setCommDropdownOpen(false);
        setMyaccDropdownOpen(false);
      }
    }
  }, [tour]);

  useEffect(() => {
    if (!isEmpty(session)) {
      dispatch(creditsPurchaseMiddleWare({ session })).then(() => {
        dispatch(permissionMiddleWare());

        dispatch(SubsriptionMiddleWare())
          .then(() => {
            dispatch(permissionMiddleWare());
          })
          .then(() => {
            if (query.has('session')) {
              query.delete('session');
              history.replace({
                search: query.toString(),
              });
            }
          });
      });
    }
    if (!isEmpty(session_id)) {
      dispatch(backendProcessMiddleWare({ session_id })).then(() => {
        dispatch(SubsriptionMiddleWare());
        dispatch(manageSubscriptionMiddleWare())
          .then(() => {
            dispatch(permissionMiddleWare());
            dispatch(SubsriptionMiddleWare());
          })
          .then(() => {
            if (query.has('session_id')) {
              query.delete('session_id');
              history.replace({
                search: query.toString(),
              });
            }
          })
          .then(() => {
            dispatch(permissionMiddleWare());
          });
      });
    }
  }, [session]);

  // Toggle functionality for Dropdown and Popup
  const toggleJobsDropdown = () => {
    if (Expent === '0') {
      const jobsdropdownvalue = !isJobsDropdownOpen;
      setJobsDropdownOpen(jobsdropdownvalue);
      sessionStorage.setItem('JobsDropdown', jobsdropdownvalue ? '1' : '0');
    } else if (Expent === '1') {
      setJobsPopupDropdownOpen(!isJobsPopupDropdownOpen);
      setViewJobsPopupDropdownOpen(false);
      setCandiPopupDropdownOpen(false);
      setCommPopupDropdownOpen(false);
      setBrandPopupDropdownOpen(false);
      setMyaccPopupDropdownOpen(false);
      setSourcePopupDropdownOpen(false);
    }
  };
  const toggleViewJobsDropdown = () => {
    if (Expent === '0') {
      const viewjobsdropdownvalue = !isViewJobsDropdownOpen;
      setViewJobsDropdownOpen(viewjobsdropdownvalue);
      sessionStorage.setItem(
        'ViewJobsDropdown',
        viewjobsdropdownvalue ? '1' : '0',
      );
    } else if (Expent === '1') {
      setViewJobsPopupDropdownOpen(!isViewJobsPopupDropdownOpen);
      setViewJobsDropdownOpen(false);
      setCandiPopupDropdownOpen(false);
      setCommPopupDropdownOpen(false);
      setBrandPopupDropdownOpen(false);
      setMyaccPopupDropdownOpen(false);
      setSourcePopupDropdownOpen(false);
    }
  };
  const toggleCandiDropdown = () => {
    if (Expent === '0') {
      const candidropdownvalue = !isCandiDropdownOpen;
      setCandiDropdownOpen(candidropdownvalue);
      sessionStorage.setItem(
        'CandidateDropdown',
        candidropdownvalue ? '1' : '0',
      );
    } else if (Expent === '1') {
      setCandiPopupDropdownOpen(!isCandiPopupDropdownOpen);
      setJobsPopupDropdownOpen(false);
      setViewJobsPopupDropdownOpen(false);
      setCommPopupDropdownOpen(false);
      setBrandPopupDropdownOpen(false);
      setMyaccPopupDropdownOpen(false);
      setSourcePopupDropdownOpen(false);
    }
  };

  const toggleSourcingDropdown = () => {
    if (Expent === '0') {
      const sourcedropdownvalue = !isSourcingDropdownOpen;
      setSourcingDropdownOpen(sourcedropdownvalue);
      sessionStorage.setItem(
        'SourcingCandidateDropdown',
        sourcedropdownvalue ? '1' : '0',
      );
    } else if (Expent === '1') {
      setSourcePopupDropdownOpen(!isSourcePopupDropdownOpen);
      setCandiPopupDropdownOpen(false);
      setJobsPopupDropdownOpen(false);
      setViewJobsPopupDropdownOpen(false);
      setCommPopupDropdownOpen(false);
      setBrandPopupDropdownOpen(false);
      setMyaccPopupDropdownOpen(false);
    }
  };

  const toggleCommDropdown = () => {
    if (Expent === '0') {
      const commdropdownvalue = !isCommDropdownOpen;
      setCommDropdownOpen(commdropdownvalue);
      sessionStorage.setItem(
        'CommunicationDropdown',
        commdropdownvalue ? '1' : '0',
      );
    } else if (Expent === '1') {
      setCommPopupDropdownOpen(!isCommPopupDropdownOpen);
      setSourcePopupDropdownOpen(false);
      setJobsPopupDropdownOpen(false);
      setViewJobsPopupDropdownOpen(false);
      setBrandPopupDropdownOpen(false);
      setMyaccPopupDropdownOpen(false);
      setCandiPopupDropdownOpen(false);
    }
  };
  const toggleBrandDropdown = () => {
    if (Expent === '0') {
      const branddropdownvalue = !isBrandDropdownOpen;
      setBrandDropdownOpen(branddropdownvalue);
      sessionStorage.setItem('BrandDropdown', branddropdownvalue ? '1' : '0');
    } else if (Expent === '1') {
      setBrandPopupDropdownOpen(!isBrandPopupDropdownOpen);
      setSourcePopupDropdownOpen(false);
      setMyaccPopupDropdownOpen(false);
      setViewJobsPopupDropdownOpen(false);
      setCandiPopupDropdownOpen(false);
      setJobsPopupDropdownOpen(false);
      setCommPopupDropdownOpen(false);
    }
  };
  const toggleMyaccDropdown = () => {
    if (Expent === '0') {
      const myaccdropdownvalue = !isMyaccDropdownOpen;
      setMyaccDropdownOpen(myaccdropdownvalue);
      sessionStorage.setItem(
        'MyAccountDropdown',
        myaccdropdownvalue ? '1' : '0',
      );
    } else if (Expent === '1') {
      setMyaccPopupDropdownOpen(!isMyaccPopupDropdownOpen);
      setSourcePopupDropdownOpen(false);
      setBrandPopupDropdownOpen(false);
      setCandiPopupDropdownOpen(false);
      setViewJobsPopupDropdownOpen(false);
      setJobsPopupDropdownOpen(false);
      setCommPopupDropdownOpen(false);
    }
  };

  const documents = () => {
    window.open('https://zita.ai/knowledgebase', '_blank');
  };
  const help_click = () => {
    window.open(
      'https://share.hsforms.com/1_3IRNtXMTI6Xa3AGdP_pVwqmqrf',
      '_blank',
    );
  };

  const handleNavigate = (val) => {};
  useEffect(() => {
    const toggle: any =
      sessionStorage.getItem('EmpToggle') === null
        ? '0'
        : sessionStorage.getItem('EmpToggle');
    setExpent(toggle);
  }, [Expent]);

  const handlecheck = (val) => {
    setExpent(val);
    data();
    sessionStorage.setItem('EmpToggle', val);
    setJobsDropdownOpen(false);
    setViewJobsDropdownOpen(false);
    setCandiDropdownOpen(false);
    setCommDropdownOpen(false);
    setBrandDropdownOpen(false);
    setMyaccDropdownOpen(false);
    setSourcingDropdownOpen(false);
    sessionStorage.setItem('JobsDropdown', '0');
    sessionStorage.setItem('ViewJobsDropdown', '0');
    sessionStorage.setItem('CandidateDropdown', '0');
    sessionStorage.setItem('CommunicationDropdown', '0');
    sessionStorage.setItem('BrandDropdown', '0');
    sessionStorage.setItem('MyAccountDropdown', '0');
    sessionStorage.setItem('SourcingCandidateDropdown', '0');
    setJobsPopupDropdownOpen(false);
    setViewJobsPopupDropdownOpen(false);
    setCandiPopupDropdownOpen(false);
    setCommPopupDropdownOpen(false);
    setBrandPopupDropdownOpen(false);
    setMyaccPopupDropdownOpen(false);
    setSourcePopupDropdownOpen(false);
  };

  useEffect(() => {
    dispatch(SubsriptionMiddleWare());
  }, []);

  const careerPageURL = localStorage.getItem('career_page_url');

  const {
    permission,
    is_plan,
    plan_id,
    super_user,
    roles,
    current_plan,
    current_jd_count,
    status,
    expire_details,
  } = useSelector(
    ({
      permissionReducers,
      userProfileReducers,
      SubscriptionReducers,
      TourdataReducer,
    }: RootState) => {
      return {
        permission: permissionReducers.Permission,
        is_plan: permissionReducers.is_plan,
        isProfile: userProfileReducers.profile,
        plan_id: permissionReducers.plan_id,
        super_user: permissionReducers.super_user,
        roles: permissionReducers.roles,
        current_plan: SubscriptionReducers.current_plan,
        current_jd_count: SubscriptionReducers.current_jd_count,
        datas: permissionReducers.data,
        status: TourdataReducer.is_steps,
        expire_details: SubscriptionReducers.expire_details,
      };
    },
  );

  //dispatching subcription middleware
  useEffect(() => {
    dispatch(SubsriptionMiddleWare());
  }, [current_plan, current_jd_count]);

  const accountPath = '/account_setting';
  useEffect(() => {
    if (plan_id !== 1 && plan_id !== 0) {
      setcheckplan(true);
    }
  }, [plan_id]);

  // Dropdownmenu Line styling for Hiring User
  let hiringclassName;
  if (
    permission.includes('bulkImport_candidates') &&
    permission.includes('talent_sourcing')
  ) {
    hiringclassName = styles.candidatesdropdownlinehire4;
  } else if (permission.includes('bulkImport_candidates')) {
    hiringclassName = styles.candidatesdropdownlinehire3;
  } else if (permission.includes('talent_sourcing')) {
    hiringclassName = styles.candidatesdropdownlinehire2;
  } else {
    hiringclassName = styles.candidatesdropdownlinehire;
  }

  let SourcehiringclassName;
  if (permission.includes('talent_sourcing')) {
    SourcehiringclassName = styles.sourcingdropdownlinehire2;
  } else {
    SourcehiringclassName = styles.sourcingdropdownlinehire;
  }

  const handleProfile = () => {
    var currentUrl = window.location.href;
    var lastValue = currentUrl.split('/').pop();

    if (lastValue !== 'account_setting') {
      window.location.href = '/account_setting';
    }
  };

  const overviewdropdownRef = useRef(null);
  const jobsdropdownRef = useRef(null);
  const viewjobsdropdownRef = useRef(null);
  const candidatesdropdownRef = useRef(null);
  const sourcedropdownRef = useRef(null);
  const communicationdropdownRef = useRef(null);
  const branddropdownRef = useRef(null);
  const myaccountdropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      jobsdropdownRef.current &&
      !jobsdropdownRef.current.contains(event.target)
    ) {
      setJobsPopupDropdownOpen(false);
    } else if (
      viewjobsdropdownRef.current &&
      !viewjobsdropdownRef.current.contains(event.target)
    ) {
      setViewJobsPopupDropdownOpen(false);
    } else if (
      candidatesdropdownRef.current &&
      !candidatesdropdownRef.current.contains(event.target)
    ) {
      setCandiPopupDropdownOpen(false);
    } else if (
      sourcedropdownRef.current &&
      !sourcedropdownRef.current.contains(event.target)
    ) {
      setSourcePopupDropdownOpen(false);
    } else if (
      communicationdropdownRef.current &&
      !communicationdropdownRef.current.contains(event.target)
    ) {
      setCommPopupDropdownOpen(false);
    } else if (
      branddropdownRef.current &&
      !branddropdownRef.current.contains(event.target)
    ) {
      setBrandPopupDropdownOpen(false);
    } else if (
      myaccountdropdownRef.current &&
      !myaccountdropdownRef.current.contains(event.target)
    ) {
      setMyaccPopupDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    if (isJobsPopupDropdownOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isJobsPopupDropdownOpen]);

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    if (isCandiPopupDropdownOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isCandiPopupDropdownOpen]);

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    if (isSourcePopupDropdownOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isSourcePopupDropdownOpen]);

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    if (isCommPopupDropdownOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isCommPopupDropdownOpen]);

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    if (isBrandPopupDropdownOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isBrandPopupDropdownOpen]);

  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    if (isMyaccPopupDropdownOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isMyaccPopupDropdownOpen]);

  const clearTab = () => {
    sessionStorage.removeItem('superUserTab');
    sessionStorage.removeItem('superUserFalseTab');
    sessionStorage.removeItem('superUserTabTwo');
    sessionStorage.removeItem('template');
    sessionStorage.removeItem('pipeline');
    sessionStorage.removeItem('button');
    sessionStorage.removeItem('wk_id');
  };

  const clearTabs = (e) => {
    e.stopPropagation();
    if (window.confirm(LEAVE_THIS_SITE)) {
      history.push('/');
    } else {
      e.preventDefault();
    }
  };

  return (
    <>
      <div
        className={Expent === '0' ? styles.sidebar : styles.sidebarmini}
        style={{
          marginTop: '50px',
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'space-between',
          height: Expent === '0' && componentHeight,
        }}
      >
        <div
          className={
            Expent === '0' ? styles.sidemenucontent : styles.sidemenucontentmini
          }
          // style={{ height: expire_details?.plan_id === 1 && '82%' }}
          style={{
            height:
              expire_details?.plan_id === 1 && is_plan
                ? ''
                : expire_details?.plan_id !== 1 && is_plan
                ? menuHeight
                : expire_details?.plan_id !== 1 && !is_plan && menuHeight,
          }}
        >
          <ul style={{ width: '100%' }}>
            <Flex>
              {/* DashBoard */}
              {is_plan ? (
                changes ? (
                  <Flex
                    title={Expent === '0' ? '' : 'Dashboard'}
                    direction_tooltip="right"
                  >
                    <li className={pathname === '/' ? styles.select_row : ''}>
                      <LinkWrapper
                        className={styles.hoverview}
                        onClick={clearTabs}
                      >
                        <text
                          style={{
                            marginLeft: '-6px',
                            position: 'relative',
                            bottom: '1px',
                          }}
                        >
                          <SvgDashboard height={28} width={28} />
                        </text>
                        <Text
                          onClick={() => handleNavigate(1)}
                          className={
                            Expent === '0' ? styles.text : styles.classpan
                          }
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Dashboard
                        </Text>
                      </LinkWrapper>
                    </li>
                  </Flex>
                ) : (
                  <Flex
                    title={Expent === '0' ? '' : 'Dashboard'}
                    direction_tooltip="right"
                  >
                    <li className={pathname === '/' ? styles.select_row : ''}>
                      <LinkWrapper
                        className={styles.hoverview}
                        onClick={clearTab}
                        // onClick={changeurlss}
                        to={is_plan ? '/' : accountPath}
                      >
                        <text
                          style={{
                            marginLeft: '-6px',
                            position: 'relative',
                            bottom: '1px',
                          }}
                        >
                          <SvgDashboard height={28} width={28} />
                        </text>
                        <Text
                          onClick={() => handleNavigate(1)}
                          className={
                            Expent === '0' ? styles.text : styles.classpan
                          }
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Dashboard
                        </Text>
                      </LinkWrapper>
                    </li>
                  </Flex>
                )
              ) : (
                <Flex
                  title={Expent === '0' ? '' : 'Dashboard'}
                  direction_tooltip="right"
                >
                  <li className={pathname === '/' ? styles.select_row : ''}>
                    <a
                      className={styles.hoverview}
                      href={' '}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <text
                        style={{
                          marginLeft: '-6px',
                          position: 'relative',
                          bottom: '1px',
                        }}
                      >
                        <SvgDashboard height={28} width={28} />
                      </text>
                      <Text
                        onClick={() => handleNavigate(1)}
                        className={
                          Expent === '0' ? styles.text : styles.classpan
                        }
                        color="primary"
                        style={{ color: '#581845', marginRight: '10px' }}
                      >
                        Dashboard
                      </Text>
                    </a>
                  </li>
                </Flex>
              )}
              {/* Reports */}
              {permission.includes('reports') ? (
                <>
                  {is_plan ? (
                    changes ? (
                      <Flex
                        title={
                          plan_id === 1
                            ? 'Please subscribe to any of the paid plans to view the job metrics.'
                            : Expent === '0'
                            ? ''
                            : 'Analytics'
                        }
                        direction_tooltip="right"
                      >
                        <li
                          style={
                            plan_id === 1
                              ? { cursor: 'not-allowed !important' }
                              : null
                          }
                          className={
                            pathname.includes('/reports') && plan_id === 1
                              ? styles.select_row
                              : plan_id === 1
                              ? styles.select_item
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.hoverview}
                            onClick={clearTabs}
                            to={
                              plan_id === 1
                                ? '/'
                                : plan_id !== 1 && is_plan
                                ? reports
                                : accountPath
                            }
                          >
                            <text
                              style={{ position: 'relative', bottom: '2px' }}
                            >
                              <SvgReport fill={'none'} />
                            </text>
                            <Text
                              onClick={() => handleNavigate(6)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '19px',
                              }}
                            >
                              Analytics
                            </Text>
                          </LinkWrapper>
                        </li>
                      </Flex>
                    ) : (
                      <Flex
                        title={
                          plan_id === 1
                            ? 'Please subscribe to any of the paid plans to view the job metrics.'
                            : Expent === '0'
                            ? ''
                            : 'Analyticsss'
                        }
                        marginRight={0}
                        direction_tooltip="right"
                      >
                        <li
                          style={
                            plan_id === 1
                              ? { cursor: 'not-allowed !important' }
                              : null
                          }
                          className={
                            pathname.includes('/reports')
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={
                              plan_id === 1
                                ? styles.selectitem
                                : styles.hoverview
                            }
                            onClick={clearTab}
                            to={
                              plan_id === 1
                                ? '#'
                                : plan_id !== 1 && is_plan
                                ? reports
                                : accountPath
                            }
                          >
                            <text
                              style={{ position: 'relative', bottom: '2px' }}
                            >
                              <SvgReport fill={'none'} />
                            </text>
                            <Text
                              onClick={() => handleNavigate(6)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                                marginLeft: '19px',
                              }}
                            >
                              Analytics
                            </Text>
                          </LinkWrapper>
                        </li>
                      </Flex>
                    )
                  ) : (
                    <Flex
                      title={Expent === '0' ? '' : 'Analytics'}
                      direction_tooltip="right"
                    >
                      <li
                        className={
                          pathname.includes('/reports')
                            ? styles.select_row
                            : styles.select_item
                        }
                        style={{ cursor: 'not-allowed' }}
                      >
                        <a
                          className={styles.hoverview}
                          href={' '}
                          style={{ cursor: 'not-allowed' }}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <text style={{ position: 'relative', bottom: '2px' }}>
                            <SvgReport fill={'none'} />
                          </text>
                          <Text
                            onClick={() => handleNavigate(6)}
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{
                              color: '#581845',
                              marginRight: '10px',
                              marginLeft: '19px',
                              cursor: 'not-allowed',
                            }}
                          >
                            Analytics
                          </Text>
                        </a>
                      </li>
                    </Flex>
                  )}
                </>
              ) : (
                <CustomTooltip
                  title="Analytics"
                  style={{ marginRight: '0px' }}
                  direction_tooltip="right"
                >
                  <li
                    className={
                      pathname.includes('/reports')
                        ? styles.select_row
                        : styles.select_item
                    }
                    style={{ cursor: 'not-allowed' }}
                  >
                    <a
                      className={styles.hoverview}
                      href={' '}
                      style={{ cursor: 'not-allowed' }}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <text style={{ position: 'relative', bottom: '2px' }}>
                        <SvgReport fill={'none'} />
                      </text>
                      <Text
                        onClick={() => handleNavigate(6)}
                        className={
                          Expent === '0' ? styles.text : styles.classpan
                        }
                        color="primary"
                        style={{
                          color: '#581845',
                          marginRight: '10px',
                          marginLeft: '19px',
                          cursor: 'not-allowed',
                        }}
                      >
                        Analytics
                      </Text>
                    </a>
                  </li>
                </CustomTooltip>
              )}
            </Flex>

            {/* Manage Jobs Dropdown */}
            {/* <CustomTooltip> */}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'Manage Jobs'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isJobsPopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleJobsDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text style={{ verticalAlign: 'middle' }}>
                    <SvgJobPost height={16} width={16} />
                  </text>
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '19px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    Manage Jobs
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isJobsDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {/* </CustomTooltip> */}
            {isJobsDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <Flex
                    className={styles.jobsdropdownlinesuperadmin}
                    style={{ display: isJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {super_user === false && roles === 'Admin' && (
                  <Flex
                    className={styles.jobsdropdownlinehadmin}
                    style={{ display: isJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'Hiring' && (
                  <Flex
                    className={
                      permission.includes('create_post')
                        ? styles.jobsdropdownlinehire
                        : styles.jobsdropdownlinehire1
                    }
                    style={{ display: isJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'HR' && (
                  <Flex
                    className={styles.jobsdropdownlinehr}
                    style={{ display: isJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div style={{ marginLeft: '4px' }}>
                    {/* Post New Jobs */}
                    {permission.includes('create_post') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Post New Job">
                            <li
                              className={
                                pathname === jobCreateNonDs
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              {current_jd_count === 0 &&
                              current_jd_count !== null ? (
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Flex
                                    onClick={() => setopensubcription(true)}
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                      marginRight: '5px',
                                      fontSize: '13px',
                                      cursor: 'pointer',
                                    }}
                                    row
                                    marginTop={5}
                                    marginBottom={5}
                                    center
                                  >
                                    <Flex style={{ cursor: 'pointer' }}>
                                      <Text color="theme">Post New Job</Text>
                                    </Flex>
                                    <Flex
                                      marginLeft={5}
                                      marginBottom={4}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {' '}
                                      <SvgSubcriptioncrown
                                        height={14}
                                        width={14}
                                        fill=""
                                      />
                                    </Flex>
                                  </Flex>
                                </LinkWrapper>
                              ) : (
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={clearTabs}
                                  to={is_plan ? jobCreateNonDs : accountPath}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Post New Job
                                  </Text>
                                </LinkWrapper>
                              )}
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Post New Job">
                            <li
                              className={
                                pathname === jobCreateNonDs
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              {current_jd_count === 0 &&
                              current_jd_count !== null ? (
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Flex
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                      marginRight: '5px',
                                      fontSize: '13px',
                                      cursor: 'pointer',
                                      bottom: 0,
                                    }}
                                    row
                                    marginTop={5}
                                    marginBottom={5}
                                    center
                                    onClick={() => setopensubcription(true)}
                                  >
                                    <Flex style={{ cursor: 'pointer' }}>
                                      <Text color="theme">Post New Job</Text>
                                    </Flex>
                                    <Flex
                                      marginLeft={5}
                                      marginBottom={4}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {' '}
                                      <SvgSubcriptioncrown
                                        height={14}
                                        width={14}
                                        fill=""
                                      />
                                    </Flex>
                                  </Flex>
                                </LinkWrapper>
                              ) : (
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={clearTab}
                                  to={is_plan ? jobCreateNonDs : accountPath}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Post New Job
                                  </Text>
                                </LinkWrapper>
                              )}
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Post New Job">
                          <li
                            className={
                              pathname === jobCreateNonDs
                                ? styles.select_row
                                : ''
                            }
                          >
                            {current_jd_count === 0 &&
                            current_jd_count !== null ? (
                              <a
                                className={styles.subhoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Flex
                                  onClick={() => setopensubcription(true)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                    marginRight: '5px',
                                    fontSize: '13px',
                                    cursor: 'pointer',
                                  }}
                                  row
                                  marginTop={5}
                                  marginBottom={5}
                                  center
                                >
                                  <Flex style={{ cursor: 'pointer' }}>
                                    <Text color="theme">Post New Job</Text>
                                  </Flex>
                                  <Flex
                                    marginLeft={5}
                                    marginBottom={4}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {' '}
                                    <SvgSubcriptioncrown
                                      height={14}
                                      width={14}
                                      fill=""
                                    />
                                  </Flex>
                                </Flex>
                              </a>
                            ) : (
                              <a
                                className={styles.subhoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Post New Job
                                </Text>
                              </a>
                            )}
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}
                    {/* Customized WorkFlow */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Workflow Settings">
                        <li
                          className={
                            // workflowtab &&
                            pathname === '/account_setting/hiring_workflow'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Hiring Workflow
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Workflow Settings">
                        <li
                          className={
                            // workflowtab &&
                            pathname === '/account_setting/hiring_workflow'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={() => {
                              clearTab();
                            }}
                            to={
                              is_plan
                                ? '/account_setting/hiring_workflow'
                                : accountPath
                            }
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Hiring Workflow
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Workflow Settings">
                      <li
                        className={
                          // workflowtab &&
                          pathname === '/account_setting/hiring_workflow'
                            ? styles.select_row
                            : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            Hiring Workflow
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                  </div>
                </Flex>
              </Flex>
            )}

            {/* <CustomTooltip title="View Jobs"> */}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'View Jobs'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isViewJobsPopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleViewJobsDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text style={{ verticalAlign: 'middle', marginLeft: '-3px' }}>
                    <SvgJobTitleIcon width={22} height={22} fill={'#581845'} />
                  </text>
                  {/* <text style={{ verticalAlign: 'middle', marginLeft: '-3px' }}>
                    <SvgViewJobs width={23} height={23}/>
                  </text> */}
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '16px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    View Jobs
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isViewJobsDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {/* </CustomTooltip> */}
            {isViewJobsDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <Flex
                    className={styles.viewjobsdropdownlinesuperadmin}
                    style={{ display: isViewJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {super_user === false && roles === 'Admin' && (
                  <Flex
                    className={styles.viewjobsdropdownlinehadmin}
                    style={{ display: isViewJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'Hiring' && (
                  <Flex
                    className={
                      permission.includes('create_post')
                        ? styles.viewjobsdropdownlinehire
                        : styles.viewjobsdropdownlinehire1
                    }
                    style={{ display: isViewJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'HR' && (
                  <Flex
                    className={styles.viewjobsdropdownlinehr}
                    style={{ display: isViewJobsDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div style={{ marginLeft: '4px' }}>
                    {/* Job Posting */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Job Listings">
                        <li
                          className={
                            pathname === '/job_list' ||
                            pathname.includes('/job_view') ||
                            pathname.includes('/zita_match_candidate') ||
                            pathname.includes('/applicant_pipe_line')
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                            to={
                              is_plan ? routesPath.MY_JOB_POSTING : accountPath
                            }
                          >
                            <Text
                              onClick={() => handleNavigate(2)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              Job Listings
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Job Listings">
                        <li
                          className={
                            pathname === '/job_list' ||
                            pathname.includes('/job_view') ||
                            pathname.includes('/zita_match_candidate') ||
                            pathname.includes('/applicant_pipe_line')
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTab}
                            to={
                              is_plan ? routesPath.MY_JOB_POSTING : accountPath
                            }
                          >
                            <Text
                              onClick={() => handleNavigate(2)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              Job Listings
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Job Listings">
                      <li
                        className={
                          pathname === '/job_list' ||
                          pathname.includes('/job_view') ||
                          pathname.includes('/zita_match_candidate') ||
                          pathname.includes('/applicant_pipe_line')
                            ? styles.select_row
                            : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            onClick={() => handleNavigate(2)}
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            Job Listings
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                    {/* Job Workflow */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Job Workflow">
                        <li
                          className={
                            pathname === '/jobs_workflow'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                            to={is_plan ? '/jobs_workflow' : accountPath}
                          >
                            <Text
                              onClick={() => handleNavigate(2)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              Jobs Workflow
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Job Workflow">
                        <li
                          className={
                            pathname === '/jobs_workflow'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTab}
                            to={is_plan ? '/jobs_workflow' : accountPath}
                          >
                            <Text
                              onClick={() => handleNavigate(2)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              Jobs Workflow
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Job Workflow">
                      <li
                        className={
                          pathname === '/jobs_workflow' ? styles.select_row : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            onClick={() => handleNavigate(2)}
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            Jobs Workflow
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                  </div>
                </Flex>
              </Flex>
            )}

            {/* Candidates Dropdown */}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'Manage Candidates'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isCandiPopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleCandiDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text style={{ verticalAlign: 'middle', marginLeft: '-2px' }}>
                    <SvgUserSearch fill={'#581845'} width={22} height={22} />
                  </text>
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '15px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    Manage Candidates
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isCandiDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {isCandiDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <Flex
                    className={styles.candidatesdropdownlinesuperadmin}
                    style={{ display: isCandiDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {super_user === false && roles === 'Admin' && (
                  <Flex
                    className={styles.candidatesdropdownlineadmin}
                    style={{ display: isCandiDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}

                {roles === 'Hiring' && (
                  <Flex
                    className={hiringclassName}
                    style={{ display: isCandiDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'HR' && (
                  <Flex
                    className={styles.candidatesdropdownlinehr}
                    style={{ display: isCandiDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div style={{ marginLeft: '4px' }}>
                    {permission.includes('bulkImport_candidates') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Add Candidates">
                            <li
                              className={
                                pathname === '/bulk_import'
                                  ? Number(isbulktabhandle) === 0
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={
                                  is_plan ? '/bulk_import?tab=0' : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Add Candidates
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Add Candidates">
                            <li
                              className={
                                pathname === '/bulk_import'
                                  ? Number(isbulktabhandle) === 0
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan ? '/bulk_import?tab=0' : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Add Candidates
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Add Candidates">
                          <li
                            className={
                              pathname === '/bulk_import'
                                ? Number(isbulktabhandle) === 0
                                  ? styles.select_row
                                  : ''
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(5)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Add Candidates
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}
                    {permission.includes('bulkImport_candidates') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Add New Applicants">
                            <li
                              className={
                                pathname === '/bulk_import'
                                  ? Number(isbulktabhandle) === 1
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={
                                  is_plan ? '/bulk_import?tab=1' : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Add New Applicants
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Add New Applicants">
                            <li
                              className={
                                pathname === '/bulk_import'
                                  ? Number(isbulktabhandle) === 1
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan ? '/bulk_import?tab=1' : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Add New Applicants
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Add New Applicants">
                          <li
                            className={
                              pathname === '/bulk_import'
                                ? Number(isbulktabhandle) === 1
                                  ? styles.select_row
                                  : ''
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(5)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Add New Applicants
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}
                    {/* {permission.includes('talent_sourcing') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Source Candidates">
                            <li
                              className={
                                pathname === '/source_candidates'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={
                                  is_plan
                                    ? routesPath.TALENT_SOURCING
                                    : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(4)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Source Candidates
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Source Candidates">
                            <li
                              className={
                                pathname === '/source_candidates'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? routesPath.TALENT_SOURCING
                                    : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(4)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Source Candidates
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Source Candidates">
                          <li
                            className={
                              pathname === '/source_candidates'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(4)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Source Candidates
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )} */}
                    {permission.includes('my_database') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="All Candidates">
                            <li
                              className={
                                pathname === '/all_candidates'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                to={
                                  is_plan ? routesPath.MYDATABASE : accountPath
                                }
                                onClick={clearTabs}
                              >
                                <Text
                                  onClick={() => handleNavigate(3)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  All Candidates
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="All Candidates">
                            <li
                              className={
                                pathname === '/all_candidates'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                to={
                                  is_plan ? routesPath.MYDATABASE : accountPath
                                }
                                onClick={clearTab}
                              >
                                <Text
                                  onClick={() => handleNavigate(3)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  All Candidates
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="All Candidates">
                          <li
                            className={
                              pathname === '/all_candidates'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(3)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                All Candidates
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}
                  </div>
                </Flex>
              </Flex>
            )}

            {/* Candidates Sourcing Dropdown */}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'Candidate Sourcing'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isSourcePopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleSourcingDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text
                    style={{
                      verticalAlign: 'middle',
                      marginLeft: '-1px',
                      marginTop: '2px',
                    }}
                  >
                    <SvgCanSourcing fill={'#581845'} width={20} height={20} />
                  </text>
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '15px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    Candidate Sourcing
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isSourcingDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {/* Candidate Sourcing */}
            {isSourcingDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <Flex
                    className={styles.sourcingdropdownlinesuperadmin}
                    style={{ display: isSourcingDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {super_user === false && roles === 'Admin' && (
                  <Flex
                    className={styles.sourcingdropdownlineadmin}
                    style={{ display: isSourcingDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'HR' && (
                  <Flex
                    className={styles.sourcingdropdownlinehr}
                    style={{ display: isSourcingDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'Hiring' && (
                  <Flex
                    className={hiringclassName}
                    style={{ display: isSourcingDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div style={{ marginLeft: '4px' }}>
                    {permission.includes('talent_sourcing') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            <li
                              className={
                                pathname === '/candidates_sourcing'
                                  ? Number(isCandidatetabhandle) === 0
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={
                                  is_plan
                                    ? '/candidates_sourcing?tab=0'
                                    : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Resume Library
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Add Candidates">
                            <li
                              className={
                                pathname === '/candidates_sourcing'
                                  ? Number(isCandidatetabhandle) === 0
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? '/candidates_sourcing?tab=0'
                                    : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Resume Library
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Add Candidates">
                          <li
                            className={
                              pathname === '/candidates_sourcing'
                                ? Number(isCandidatetabhandle) === 0
                                  ? styles.select_row
                                  : ''
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(5)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Resume Library
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}

                    {permission.includes('talent_sourcing') && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Add New Applicants">
                            <li
                              className={
                                pathname === '/candidates_sourcing'
                                  ? Number(isCandidatetabhandle) === 1
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={
                                  is_plan
                                    ? '/candidates_sourcing?tab=1'
                                    : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Linkedin Sourcing
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Add New Applicants">
                            <li
                              className={
                                pathname === '/candidates_sourcing'
                                  ? Number(isCandidatetabhandle) === 1
                                    ? styles.select_row
                                    : ''
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? '/candidates_sourcing?tab=1'
                                    : accountPath
                                }
                              >
                                <Text
                                  onClick={() => handleNavigate(5)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Linkedin Sourcing
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Add New Applicants">
                          <li
                            className={
                              pathname === '/candidates_sourcing'
                                ? Number(isCandidatetabhandle) === 1
                                  ? styles.select_row
                                  : ''
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(5)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Linkedin Sourcing
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}

                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Source Candidates">
                        <li
                          className={
                            pathname === '/candidates_sourcing'
                              ? (
                                  permission.includes('talent_sourcing')
                                    ? Number(isCandidatetabhandle) === 2
                                    : Number(isCandidatetabhandle) === 0
                                )
                                ? styles.select_row
                                : ''
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                            to={
                              is_plan
                                ? permission.includes('talent_sourcing')
                                  ? '/candidates_sourcing?tab=2'
                                  : '/candidates_sourcing?tab=0'
                                : accountPath
                            }
                          >
                            <Text
                              onClick={() => handleNavigate(4)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Unlocked Candidates
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Source Candidates">
                        <li
                          className={
                            pathname === '/candidates_sourcing'
                              ? (
                                  permission.includes('talent_sourcing')
                                    ? Number(isCandidatetabhandle) === 2
                                    : Number(isCandidatetabhandle) === 0
                                )
                                ? styles.select_row
                                : ''
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTab}
                            to={
                              is_plan
                                ? permission.includes('talent_sourcing')
                                  ? '/candidates_sourcing?tab=2'
                                  : '/candidates_sourcing?tab=0'
                                : accountPath
                            }
                          >
                            <Text
                              onClick={() => handleNavigate(4)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Unlocked Candidates
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Source Candidates">
                      <li
                        className={
                          pathname === '/candidates_sourcing'
                            ? (
                                permission.includes('talent_sourcing')
                                  ? Number(isCandidatetabhandle) === 2
                                  : Number(isCandidatetabhandle) === 0
                              )
                              ? styles.select_row
                              : ''
                            : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            onClick={() => handleNavigate(4)}
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{
                              color: '#581845',
                              marginLeft: '4px',
                            }}
                          >
                            Unlocked Candidates
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                  </div>
                </Flex>
              </Flex>
            )}

            {/* Communications Dropdown */}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'Communications'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isCommPopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleCommDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text style={{ verticalAlign: 'middle' }}>
                    <SvgCommunication width={21} height={21} />
                  </text>
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '14px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    Communications
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isCommDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {isCommDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <Flex
                    className={styles.communicationdropdownlinesuperadmin}
                    style={{ display: isCommDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {super_user === false && roles === 'Admin' && (
                  <Flex
                    className={styles.communicationdropdownlineadmin}
                    style={{ display: isCommDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'Hiring' && (
                  <Flex
                    className={styles.communicationdropdownlinehire}
                    style={{ display: isCommDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'HR' && (
                  <Flex
                    className={styles.communicationdropdownlinehr}
                    style={{ display: isCommDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div style={{ marginLeft: '4px' }}>
                    {/* Mailbox */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="MailBox">
                        <li
                          className={
                            pathname === '/mail' ? styles.select_row : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                            to={is_plan ? routesPath.MAIL : accountPath}
                          >
                            <Text
                              onClick={() => handleNavigate(7)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              Mailbox
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="MailBox">
                        <li
                          className={
                            pathname === '/mail' ? styles.select_row : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTab}
                            to={is_plan ? routesPath.MAIL : accountPath}
                          >
                            <Text
                              onClick={() => handleNavigate(7)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              Mailbox
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="MailBox">
                      <li
                        className={
                          pathname === '/mail' ? styles.select_row : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            onClick={() => handleNavigate(7)}
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            Mailbox
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                    {/* Interview Scheduler */}
                    {
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title=" Interview Scheduler">
                            <li
                              className={
                                pathname === meetingScheduler
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={is_plan ? meetingScheduler : accountPath}
                              >
                                <Text
                                  onClick={() => handleNavigate(8)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Event Scheduler
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title=" Interview Scheduler">
                            <li
                              className={
                                pathname === meetingScheduler
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={is_plan ? meetingScheduler : accountPath}
                              >
                                <Text
                                  onClick={() => handleNavigate(8)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Event Scheduler
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title=" Interview Scheduler">
                          <li
                            className={
                              pathname === meetingScheduler
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(8)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Event Scheduler
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    }
                    {/* Calendar */}
                    {
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Calendar">
                            <li
                              className={
                                pathname === '/calendar'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                                to={is_plan ? routesPath.CALENDAR : accountPath}
                              >
                                <Text
                                  onClick={() => handleNavigate(7)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Calendar
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Calendar">
                            <li
                              className={
                                pathname === '/calendar'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={is_plan ? routesPath.CALENDAR : accountPath}
                              >
                                <Text
                                  onClick={() => handleNavigate(7)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Calendar
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Calendar">
                          <li
                            className={
                              pathname === '/calendar' ? styles.select_row : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(7)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Calendar
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    }
                    {/* Integrations */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Integrations">
                        <li
                          className={
                            pathname === '/account_setting/integration'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Integrations
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Integrations">
                        <li
                          className={
                            pathname === '/account_setting/integration'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={() => {
                              clearTab();
                            }}
                            to={'/account_setting/integration'}
                          >
                            <Text
                              onClick={() => handleNavigate(1)}
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Integrations
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Integrations">
                      <li
                        className={
                          pathname === '/account_setting/integration'
                            ? styles.select_row
                            : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            Integrations
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                    {/* {super_user === true && roles === 'Admin' && (
                      <>
                        {is_plan ? (
                          changes ? (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTab') ===
                                  '4' &&
                                  pathname.includes('/account_setting')
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.hoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTab') ===
                                  '4' &&
                                  pathname.includes('/account_setting')
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.hoverview}
                                onClick={() => {
                                  clearTab();
                                }}
                                to={'/account_setting/settings?tab=4'}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </LinkWrapper>
                            </li>
                          )
                        ) : (
                          <li
                            title="Integrations"
                            className={
                              sessionStorage.getItem('superUserTab') === '4' &&
                                pathname.includes('/account_setting')
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.hoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{ color: '#581845', marginLeft: '4px' }}
                              >
                                Integrations
                              </Text>
                            </a>
                          </li>
                        )}
                      </>
                    )}
                    {super_user === false && roles === 'Admin' && (
                      <>
                        {is_plan ? (
                          changes ? (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTab') === '2'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.hoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTab') === '2'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.hoverview}
                                onClick={() => {
                                  clearTab();
                                }}
                                to={'/account_setting/setting?tab=2'}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </LinkWrapper>
                            </li>
                          )
                        ) : (
                          <li
                            title="Integrations"
                            className={
                              sessionStorage.getItem('superUserTabTwo') === '2'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.hoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                onClick={() => handleNavigate(1)}
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{ color: '#581845', marginLeft: '4px' }}
                              >
                                Integrations
                              </Text>
                            </a>
                          </li>
                        )}
                      </>
                    )}
                    {roles === 'Hiring' &&
                      !permission.includes('manage_account_settings') && (
                        <>
                          {is_plan ? (
                            changes ? (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTabTwo') === '1'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={clearTabs}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            ) : (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTabTwo') === '1'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={() => {
                                    clearTab();
                                  }}
                                  to={'/account_setting/settings?tab=1'}
                                >
                                  <Text
                                    onClick={() => handleNavigate(1)}
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            )
                          ) : (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTabTwo') === '1'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <a
                                className={styles.hoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </a>
                            </li>
                          )}
                        </>
                      )}
                    {roles === 'Hiring' &&
                      permission.includes('manage_account_settings') && (
                        <>
                          {is_plan ? (
                            changes ? (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTab') === '2'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={clearTabs}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            ) : (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTab') === '2'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={() => {
                                    clearTab();
                                  }}
                                  to={'/account_setting/settings?tab=2'}
                                >
                                  <Text
                                    onClick={() => handleNavigate(1)}
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            )
                          ) : (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTab') === '2'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <a
                                className={styles.hoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </a>
                            </li>
                          )}
                        </>
                      )}
                    {roles === 'HR' &&
                      !permission.includes('manage_account_settings') && (
                        <>
                          {is_plan ? (
                            changes ? (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTabTwo') ===
                                    '1'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={clearTabs}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            ) : (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTabTwo') ===
                                    '1'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={() => {
                                    clearTab();
                                  }}
                                  to={'/account_setting/settings?tab=1'}
                                >
                                  <Text
                                    onClick={() => handleNavigate(1)}
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            )
                          ) : (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTabTwo') ===
                                  '1'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <a
                                className={styles.hoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </a>
                            </li>
                          )}
                        </>
                      )}
                    {roles === 'HR' &&
                      permission.includes('manage_account_settings') && (
                        <>
                          {is_plan ? (
                            changes ? (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTab') === '2'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={clearTabs}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            ) : (
                              <li
                                title="Integrations"
                                className={
                                  sessionStorage.getItem('superUserTab') === '2'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.hoverview}
                                  onClick={() => {
                                    clearTab();
                                  }}
                                  to={'/account_setting/settings?tab=2'}
                                >
                                  <Text
                                    onClick={() => handleNavigate(1)}
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Integrations
                                  </Text>
                                </LinkWrapper>
                              </li>
                            )
                          ) : (
                            <li
                              title="Integrations"
                              className={
                                sessionStorage.getItem('superUserTab') === '2'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <a
                                className={styles.hoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Integrations
                                </Text>
                              </a>
                            </li>
                          )}
                        </>
                      )} */}
                  </div>
                </Flex>
              </Flex>
            )}
            {/* Brand Dropdown */}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'Branding'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isBrandPopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleBrandDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text style={{ verticalAlign: 'middle', marginLeft: '0px' }}>
                    <SvgSetupIcon width={18} height={18} />
                  </text>
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '17px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    Branding
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isBrandDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {isBrandDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <Flex
                    className={styles.branddropdownlinesuperadmin}
                    style={{ display: isBrandDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {super_user === false && roles === 'Admin' && (
                  <Flex
                    className={styles.branddropdownlineadmin}
                    style={{ display: isBrandDropdownOpen ? '' : 'none' }}
                  ></Flex>
                )}
                {roles === 'Hiring' &&
                  !permission.includes('manage_account_settings') && (
                    <Flex
                      className={styles.branddropdownlinehire}
                      style={{ display: isBrandDropdownOpen ? '' : 'none' }}
                    ></Flex>
                  )}
                {roles === 'Hiring' &&
                  permission.includes('manage_account_settings') && (
                    <Flex
                      className={styles.branddropdownlinehr2}
                      style={{ display: isBrandDropdownOpen ? '' : 'none' }}
                    ></Flex>
                  )}
                {roles === 'HR' &&
                  !permission.includes('manage_account_settings') && (
                    <Flex
                      className={styles.branddropdownlinehr}
                      style={{ display: isBrandDropdownOpen ? '' : 'none' }}
                    ></Flex>
                  )}
                {roles === 'HR' &&
                  permission.includes('manage_account_settings') && (
                    <Flex
                      className={styles.branddropdownlinehr2}
                      style={{ display: isBrandDropdownOpen ? '' : 'none' }}
                    ></Flex>
                  )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div style={{ marginLeft: '4px' }}>
                    {/* Build Your Careers Page */}
                    {super_user === true && roles === 'Admin' && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Build Careers Page">
                            <li
                              className={
                                pathname ===
                                '/account_setting/build_careers_page'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Build Careers Page
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Build Careers Page">
                            <li
                              className={
                                pathname ===
                                '/account_setting/build_careers_page'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? '/account_setting/build_careers_page'
                                    : accountPath
                                }
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Build Careers Page
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Build Careers Page">
                          <li
                            className={
                              pathname === '/account_setting/build_careers_page'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Build Careers Page
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}
                    {super_user === false && roles === 'Admin' && (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Build Careers Page">
                            <li
                              className={
                                pathname ===
                                '/account_setting/build_careers_page'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Build Careers Page
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Build Careers Page">
                            <li
                              className={
                                pathname ===
                                '/account_setting/build_careers_page'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? '/account_setting/build_careers_page'
                                    : accountPath
                                }
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Build Careers Page
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Build Careers Page">
                          <li
                            className={
                              pathname === '/account_setting/build_careers_page'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Build Careers Page
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    )}
                    {roles === 'HR' &&
                      permission.includes('manage_account_settings') && (
                        <>
                          {is_plan ? (
                            changes ? (
                              // <CustomTooltip title="Build Careers Page">
                              <li
                                className={
                                  pathname ===
                                  '/account_setting/build_careers_page'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={clearTabs}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Build Careers Page
                                  </Text>
                                </LinkWrapper>
                              </li>
                            ) : (
                              // </CustomTooltip>
                              // <CustomTooltip title="Build Careers Page">
                              <li
                                className={
                                  pathname ===
                                  '/account_setting/build_careers_page'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={clearTab}
                                  to={
                                    is_plan
                                      ? '/account_setting/build_careers_page'
                                      : accountPath
                                  }
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Build Careers Page
                                  </Text>
                                </LinkWrapper>
                              </li>
                              // </CustomTooltip>
                            )
                          ) : (
                            // <CustomTooltip title="Build Careers Page">
                            <li
                              className={
                                pathname ===
                                '/account_setting/build_careers_page'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <a
                                className={styles.subhoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Build Careers Page
                                </Text>
                              </a>
                            </li>
                            // </CustomTooltip>
                          )}
                        </>
                      )}
                    {roles === 'Hiring' &&
                      permission.includes('manage_account_settings') && (
                        <>
                          {is_plan ? (
                            changes ? (
                              // <CustomTooltip title="Build Careers Page">
                              <li
                                className={
                                  pathname ===
                                  '/account_setting/build_careers_page'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={clearTabs}
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Build Careers Page
                                  </Text>
                                </LinkWrapper>
                              </li>
                            ) : (
                              // </CustomTooltip>
                              // <CustomTooltip title="Build Careers Page">
                              <li
                                className={
                                  pathname ===
                                  '/account_setting/build_careers_page'
                                    ? styles.select_row
                                    : ''
                                }
                              >
                                <LinkWrapper
                                  className={styles.subhoverview}
                                  onClick={clearTab}
                                  to={
                                    is_plan
                                      ? '/account_setting/build_careers_page'
                                      : accountPath
                                  }
                                >
                                  <Text
                                    className={
                                      Expent === '0'
                                        ? styles.text
                                        : styles.classpan
                                    }
                                    color="primary"
                                    style={{
                                      color: '#581845',
                                      marginLeft: '4px',
                                    }}
                                  >
                                    Build Careers Page
                                  </Text>
                                </LinkWrapper>
                              </li>
                              // </CustomTooltip>
                            )
                          ) : (
                            // <CustomTooltip title="Build Careers Page">
                            <li
                              className={
                                pathname ===
                                '/account_setting/build_careers_page'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <a
                                className={styles.subhoverview}
                                href={' '}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Build Careers Page
                                </Text>
                              </a>
                            </li>
                            // </CustomTooltip>
                          )}
                        </>
                      )}

                    {/* Careers Page */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="View Careers Page">
                        <li
                        // className={pathname === `/${career_page_url}/careers` ? styles.select_row : ''}
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              View Careers Page
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="View Careers Page">
                        <li
                        // className={pathname === `/${career_page_url}/careers` ? styles.select_row : ''}
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTab}
                            target={
                              isEmpty(careerPageURL) ? '_parent' : '_blank'
                            }
                            to={
                              isEmpty(careerPageURL)
                                ? `/account_setting/build_careers_page`
                                : `/${careerPageURL}/careers`
                            }
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{ color: '#581845', marginLeft: '4px' }}
                            >
                              View Careers Page
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="View Careers Page">
                      <li
                      // className={pathname === `/${career_page_url}/careers` ? styles.select_row : ''}
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            View Careers Page
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}

                    {/* Email Notifications */}
                    {/* {super_user === true ? (
                      <>
                        {is_plan ? (
                          changes ? (
                            <li
                              title="Email Notifications"
                              className={
                                sessionStorage.getItem('superUserTab') === '5' &&
                                  pathname.includes('/account_setting')
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.hoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Email Notifications
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            <li
                              title="Email Notifications"
                              className={
                                sessionStorage.getItem('superUserTab') ===
                                  '5' &&

                                  pathname.includes('/account_setting')

                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.hoverview}
                                onClick={clearTab}
                                to={'/account_setting/settings?tab=5'}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Email Notifications
                                </Text>
                              </LinkWrapper>
                            </li>
                          )
                        ) : (
                          <li
                            title="Email Notifications"
                            className={
                              sessionStorage.getItem('superUserTab') === '5' &&
                                pathname.includes('/account_setting')


                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.hoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{ color: '#581845', marginLeft: '4px' }}
                              >
                                Email Notifications
                              </Text>
                            </a>
                          </li>
                        )}
                      </>
                    ) : (
                      ''
                    )} */}
                  </div>
                </Flex>
              </Flex>
            )}
            {/* Account Settings*/}
            <li>
              <Flex
                row
                title={Expent === '0' ? '' : 'Account Settings'}
                className={`
                ${Expent === '0' ? styles.filtername : styles.filternamemini}
                ${isMyaccPopupDropdownOpen ? styles.nameHoverview : ''}
              `}
                onClick={() => {
                  toggleMyaccDropdown();
                }}
                direction_tooltip="right"
              >
                <Flex row>
                  <text style={{ verticalAlign: 'middle' }}>
                    {/* <SvgSetting fill={'#581845'} height={20} width={20} /> */}
                    <SvgMyAccount height={20} width={20} />
                  </text>
                  <Text
                    size={13}
                    color="theme"
                    style={{ cursor: 'Pointer', marginLeft: '15px' }}
                    className={
                      Expent === '0' ? styles.maintext : styles.classpan
                    }
                  >
                    Account Settings
                  </Text>
                </Flex>
                <Flex
                  className={Expent === '0' ? styles.maintext : styles.classpan}
                  style={{
                    transform: isMyaccDropdownOpen && 'rotate(90deg)',
                    transition: 'transform 0.2s ease',
                  }}
                >
                  <SvgRight1 height={10} width={10} fill={'#581845'} />
                </Flex>
              </Flex>
            </li>
            {isMyaccDropdownOpen && (
              <Flex className={styles.liflex}>
                {super_user === true && roles === 'Admin' && (
                  <>
                    <Flex column style={{ marginLeft: '17px' }}>
                      <Flex
                        className={styles.myaccountdropdownlinesuperadmin}
                        style={{ display: isMyaccDropdownOpen ? '' : 'none' }}
                      ></Flex>
                      {/* <Flex style={{ marginLeft: '4px' }}>
                        <SvgCircle width={10} height={10} />
                      </Flex> */}
                    </Flex>
                  </>
                )}
                {super_user === false && roles === 'Admin' && (
                  <>
                    <Flex column style={{ marginLeft: '17px' }}>
                      <Flex
                        className={styles.myaccountdropdownlineadmin}
                        style={{ display: isMyaccDropdownOpen ? '' : 'none' }}
                      ></Flex>
                      {/* <Flex style={{ marginLeft: '4px' }}>
                        <SvgCircle width={10} height={10} />
                      </Flex> */}
                    </Flex>
                  </>
                )}
                {roles === 'Hiring' && (
                  <>
                    <Flex column style={{ marginLeft: '17px' }}>
                      <Flex
                        className={styles.myaccountdropdownlinehire}
                        style={{ display: isMyaccDropdownOpen ? '' : 'none' }}
                      ></Flex>
                      {/* <Flex style={{ marginLeft: '4px' }}>
                        <SvgCircle width={10} height={10} />
                      </Flex> */}
                    </Flex>
                  </>
                )}
                {roles === 'HR' && (
                  <>
                    <Flex column style={{ marginLeft: '17px' }}>
                      <Flex
                        className={styles.myaccountdropdownlinehr}
                        style={{ display: isMyaccDropdownOpen ? '' : 'none' }}
                      ></Flex>
                      {/* <Flex style={{ marginLeft: '4px' }}>
                        <SvgCircle width={10} height={10} />
                      </Flex> */}
                    </Flex>
                  </>
                )}
                <Flex
                  style={{ width: '166px' }}
                  className={Expent === '0' ? '' : styles.classpan}
                >
                  <div
                    style={{
                      marginLeft: '1px',
                    }}
                  >
                    {/* Profiles */}
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="My Profile">
                        <li
                          className={
                            // Myprofiletab &&
                            pathname === '/account_setting/profiles'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTabs}
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Profiles
                            </Text>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="My Profile">
                        <li
                          className={
                            // Myprofiletab &&
                            pathname === '/account_setting/profiles'
                              ? styles.select_row
                              : ''
                          }
                        >
                          <LinkWrapper
                            className={styles.subhoverview}
                            onClick={clearTab}
                            to={'/account_setting/profiles'}
                          >
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginLeft: '4px',
                              }}
                            >
                              Profiles
                            </Text>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="My Profile">
                      <li
                        className={
                          // Myprofiletab &&
                          pathname === '/account_setting/profiles'
                            ? styles.select_row
                            : ''
                        }
                      >
                        <a
                          className={styles.subhoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            className={
                              Expent === '0' ? styles.text : styles.classpan
                            }
                            color="primary"
                            style={{ color: '#581845', marginLeft: '4px' }}
                          >
                            Profiles
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                    {/* Subscription & Billing */}
                    {super_user === true ? (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="My Plan">
                            <li
                              className={
                                pathname === '/account_setting/subscription'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  onClick={() => handleNavigate(1)}
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Subscription & Billing
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="My Plan">
                            <li
                              className={
                                pathname === '/account_setting/subscription'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? '/account_setting/subscription'
                                    : accountPath
                                }
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Subscription & Billing
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="My Plan">
                          <li
                            className={
                              pathname === '/account_setting/subscription'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Subscription & Billing
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {/* Manage Users */}
                    {super_user === true ? (
                      <>
                        {is_plan ? (
                          changes ? (
                            // <CustomTooltip title="Manage Users">
                            <li
                              className={
                                pathname === '/account_setting/manage_user'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTabs}
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Manage Users
                                </Text>
                              </LinkWrapper>
                            </li>
                          ) : (
                            // </CustomTooltip>
                            // <CustomTooltip title="Manage Users">
                            <li
                              className={
                                pathname === '/account_setting/manage_user'
                                  ? styles.select_row
                                  : ''
                              }
                            >
                              <LinkWrapper
                                className={styles.subhoverview}
                                onClick={clearTab}
                                to={
                                  is_plan
                                    ? '/account_setting/manage_user'
                                    : accountPath
                                }
                              >
                                <Text
                                  className={
                                    Expent === '0'
                                      ? styles.text
                                      : styles.classpan
                                  }
                                  color="primary"
                                  style={{
                                    color: '#581845',
                                    marginLeft: '4px',
                                  }}
                                >
                                  Manage Users
                                </Text>
                              </LinkWrapper>
                            </li>
                            // </CustomTooltip>
                          )
                        ) : (
                          // <CustomTooltip title="Manage Users">
                          <li
                            className={
                              pathname === '/account_setting/manage_user'
                                ? styles.select_row
                                : ''
                            }
                          >
                            <a
                              className={styles.subhoverview}
                              href={' '}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Text
                                className={
                                  Expent === '0' ? styles.text : styles.classpan
                                }
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginLeft: '4px',
                                }}
                              >
                                Manage Users
                              </Text>
                            </a>
                          </li>
                          // </CustomTooltip>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </Flex>
              </Flex>
            )}
            {/* Submit Feedback */}
            {is_plan ? (
              changes ? (
                <Flex
                  title={Expent === '0' ? '' : 'Submit Feedback'}
                  direction_tooltip="right"
                >
                  <li>
                    <LinkWrapper
                      className={styles.hoverview}
                      onClick={clearTabs}
                    >
                      <text
                        style={{
                          marginLeft: '-4px',
                          position: 'relative',
                          bottom: 2,
                        }}
                      >
                        <SvgSupport width={26} height={26} />
                      </text>
                      <Text
                        onClick={() => handleNavigate(1)}
                        className={
                          Expent === '0' ? styles.text : styles.classpan
                        }
                        color="primary"
                        style={{ color: '#581845', marginRight: '10px' }}
                      >
                        Submit Feedback
                      </Text>
                    </LinkWrapper>
                  </li>
                </Flex>
              ) : (
                <Flex
                  title={Expent === '0' ? '' : 'Submit Feedback'}
                  direction_tooltip="right"
                >
                  <li>
                    <div
                      role="button"
                      className={styles.supportBtn}
                      onClick={() => {
                        clearTab();
                        help_click();
                      }}
                    >
                      <text
                        style={{
                          marginLeft: '-4px',
                          position: 'relative',
                          bottom: 2,
                        }}
                      >
                        <SvgSupport width={26} height={26} />
                      </text>
                      <Text
                        onClick={() => handleNavigate(1)}
                        className={
                          Expent === '0' ? styles.text : styles.classpan
                        }
                        color="primary"
                        style={{ color: '#581845', marginRight: '10px' }}
                      >
                        Submit Feedback
                      </Text>
                    </div>
                  </li>
                </Flex>
              )
            ) : (
              <Flex
                title={Expent === '0' ? '' : 'Submit Feedback'}
                direction_tooltip="right"
              >
                <li>
                  <a
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <text
                      style={{
                        marginLeft: '-4px',
                        position: 'relative',
                        bottom: 2,
                      }}
                    >
                      <SvgSupport width={26} height={26} />
                    </text>
                    <Text
                      onClick={() => handleNavigate(1)}
                      className={Expent === '0' ? styles.text : styles.classpan}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Submit Feedback
                    </Text>
                  </a>
                </li>
              </Flex>
            )}
            {/* Documentation */}
            {is_plan ? (
              changes ? (
                <Flex
                  title={Expent === '0' ? '' : 'Documentation'}
                  direction_tooltip="right"
                >
                  <li>
                    <LinkWrapper
                      className={styles.hoverview}
                      onClick={clearTabs}
                    >
                      <text
                        style={{
                          marginLeft: '-2px',
                          position: 'relative',
                          bottom: '2px',
                        }}
                      >
                        <SvgDocument width={22} height={22} />
                      </text>
                      <Text
                        onClick={() => handleNavigate(1)}
                        className={
                          Expent === '0' ? styles.text : styles.classpan
                        }
                        color="primary"
                        style={{
                          color: '#581845',
                          marginRight: '10px',
                          marginLeft: '14px',
                        }}
                      >
                        Documentation
                      </Text>
                    </LinkWrapper>
                  </li>
                </Flex>
              ) : (
                <Flex
                  title={Expent === '0' ? '' : 'Documentation'}
                  direction_tooltip="right"
                >
                  <li>
                    <div
                      role="button"
                      className={styles.supportBtn}
                      onClick={() => {
                        clearTab();
                        documents();
                      }}
                    >
                      <text
                        style={{
                          marginLeft: '-2px',
                          position: 'relative',
                          bottom: '2px',
                        }}
                      >
                        <SvgDocument width={22} height={22} />
                      </text>
                      <Text
                        onClick={() => handleNavigate(1)}
                        className={
                          Expent === '0' ? styles.text : styles.classpan
                        }
                        color="primary"
                        style={{
                          color: '#581845',
                          marginRight: '10px',
                          marginLeft: '14px',
                        }}
                      >
                        Documentation
                      </Text>
                    </div>
                  </li>
                </Flex>
              )
            ) : (
              <Flex
                title={Expent === '0' ? '' : 'Documentation'}
                direction_tooltip="right"
              >
                <li>
                  <a
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <text
                      style={{
                        marginLeft: '-2px',
                        position: 'relative',
                        bottom: '2px',
                      }}
                    >
                      <SvgDocument width={22} height={22} />
                    </text>
                    <Text
                      onClick={() => handleNavigate(1)}
                      className={Expent === '0' ? styles.text : styles.classpan}
                      color="primary"
                      style={{
                        color: '#581845',
                        marginRight: '10px',
                        marginLeft: '14px',
                      }}
                    >
                      Documentation
                    </Text>
                  </a>
                </li>
              </Flex>
            )}
            {/* {tour application(upcoming process)} */}
            {/* {is_plan ? (
              changes ? (
                <CustomTooltip title='Application Tour'>
                  <li >
                    <LinkWrapper className={styles.hoverview}
                      onClick={() => {
                        dispatch(tourdataget({
                          restart_status:1,
                          skip_id:1,
                        })).then((res)=>{
                          if(res.payload[0].is_steps){
                            history.push("/")
                          }
                        })
                      }}
                    >
                      <text style={{ marginLeft: '-4px', position: 'relative', bottom: 2 }}>
                        <SvgApplicationTour />
                      </text>
                      <Text
                        onClick={() => handleNavigate(1)}
                        className={Expent === '0' ? styles.text : styles.classpan}
                        color="primary"
                        style={{ color: '#581845', marginRight: '10px' }}
                      >
                        Application Tour
                      </Text>
                    </LinkWrapper>
                  </li>
                </CustomTooltip>
              ) : (
                <CustomTooltip title='Application Tour'>
                  <li>
                    <div
                      role='button'
                      className={styles.supportBtn}
                      onClick={() => {
                        dispatch(tourdataget({
                          restart_status:1,
                          skip_id:1,
                        })).then((res)=>{
                          if(res.payload[0].is_steps){
                            history.push("/")
                          }
                        })
                      }}
                    >
                      <text style={{ marginLeft: '-4px', position: 'relative', bottom: 2 }}>
                        <SvgApplicationTour />
                      </text>
                      <Text
                        className={Expent === '0' ? styles.text : styles.classpan}
                        color="primary"
                        style={{ color: '#581845', marginRight: '10px' }}
                      >
                        Application Tour
                      </Text>
                    </div>
                  </li>
                </CustomTooltip>
              )
            ) : (
              <CustomTooltip title='Application Tour'>
                <li>
                  <a
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <text style={{ marginLeft: '-4px', position: 'relative', bottom: 2 }}>
                      <SvgApplicationTour />
                    </text>
                    <Text
                      onClick={() => handleNavigate(1)}
                      className={Expent === '0' ? styles.text : styles.classpan}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Application Tour
                    </Text>
                  </a>
                </li>
              </CustomTooltip>
            )} */}
          </ul>
        </div>

        <ul className={styles.setting}>
          <Flex marginLeft={Expent === '0' && 3}>
            {is_plan && Expent === '0' && expire_details?.plan_id === 1 ? (
              <Flex style={{ paddingBottom: '10px' }}>
                <Flex row className={styles.freetrailBanner}>
                  <Flex style={{ paddingRight: 5 }}>
                    <SvgTimerClock width={16} height={16} />
                  </Flex>
                  <Text size={13}>
                    Free trial:{' '}
                    <Text size={13} bold>
                      {expire_details?.plan_exp?.days}{' '}
                    </Text>
                    {expire_details?.plan_exp?.days === 1 ? (
                      <>
                        <Text size={13} bold>
                          day
                        </Text>{' '}
                        <Text size={13}> left</Text>
                      </>
                    ) : (
                      <>
                        <Text size={13} bold>
                          days
                        </Text>{' '}
                        <Text size={13}> left</Text>
                      </>
                    )}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              Expent === '0' &&
              expire_details?.plan_id === 1 &&
              expire_details?.plan_exp?.days <= 0 &&
              !is_plan && (
                <Flex style={{ paddingBottom: '10px' }}>
                  <Flex center row className={styles.freetrailBanner}>
                    <Flex style={{ paddingRight: 5 }}>
                      <SvgTimerClock width={16} height={16} />
                    </Flex>
                    <Text size={13}>
                      Free trial{' '}
                      <Text size={13} color="error" bold>
                        expired
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              )
            )}

            {is_plan ? (
              <li
                className={
                  Expent === '0' ? styles.sidebarexpand1 : styles.sidebarmin
                }
              >
                <Flex className={Expent === '0' ? styles.removepadding : ''}>
                  {Expent === '0' ? (
                    <Flex>
                      <Flex row center className={styles.paddingimport1}>
                        <Flex
                          row
                          onClick={() => {
                            clearTab();
                            handleProfile();
                          }}
                        >
                          <SvgSetting fill={'#581845'} height={20} width={20} />
                          <Text
                            className={
                              Expent === '0'
                                ? styles.textsetting
                                : styles.classpan
                            }
                            color="primary"
                            style={{
                              color: '#581845',
                              marginRight: '10px',
                              bottom: 0,
                              cursor: 'pointer',
                            }}
                          >
                            Settings
                          </Text>
                        </Flex>

                        <Flex>
                          <Button
                            style={{
                              height: '19px',
                              width: '19px',
                            }}
                            types="link"
                            // className={styles.collapse}
                            onClick={() => {
                              handlecheck('1');
                              setIsTooltipOpen(false);
                            }}
                          >
                            <TooltipLite
                              background={'#757274'}
                              content="Collapse sidebar"
                              direction="right"
                              distance={10}
                              arrowSize={7}
                              isOpen={isTooltipOpen}
                            >
                              <div
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              >
                                <SvgCollapse height={16} width={16} />
                              </div>
                            </TooltipLite>
                          </Button>
                        </Flex>
                      </Flex>
                      <Flex className={styles.linestyle}></Flex>
                      <Flex className={styles.makeversioncenter}>
                        <Text size={11} style={{ color: '#581845' }}>
                          App Version {version}
                        </Text>
                      </Flex>
                    </Flex>
                  ) : (
                    <div
                      role="button"
                      onClick={() => {
                        clearTab();
                        handleProfile();
                      }}
                    >
                      <TooltipLite
                        background={'#757274'}
                        content="Settings"
                        direction="right"
                        arrowSize={7}
                        isOpen={isTooltipOpensetting}
                      >
                        <div
                          onMouseEnter={handleMouseEntersetting}
                          onMouseLeave={handleMouseLeavesetting}
                        >
                          <SvgSetting fill={'#581845'} height={20} width={20} />
                        </div>
                      </TooltipLite>
                    </div>
                  )}
                </Flex>
              </li>
            ) : (
              <li
                className={
                  Expent === '0' ? styles.sidebarexpand1 : styles.sidebarmin
                }
              >
                <Flex
                  style={{ borderLeft: 'none !important' }}
                  // href={' '}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className={
                    Expent === '0' ? styles.removepadding : styles.removepadding
                  }
                >
                  <Flex style={{ marginLeft: Expent === '0' ? '5px' : '0px' }}>
                    {Expent === '0' ? (
                      <Flex>
                        <Flex row center className={styles.paddingimport1}>
                          <Flex row>
                            <SvgSetting
                              fill={'#581845'}
                              height={20}
                              width={20}
                            />
                            <Text
                              className={
                                Expent === '0'
                                  ? styles.textsetting
                                  : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                                bottom: 0,
                                cursor: 'pointer',
                              }}
                            >
                              Settings
                            </Text>
                          </Flex>
                          <Flex>
                            <Button
                              style={{
                                height: '19px',
                                width: '19px',
                              }}
                              types="link"
                              // className={styles.collapse}
                              onClick={() => {
                                handlecheck('1');
                                setIsTooltipOpen(false);
                              }}
                            >
                              <TooltipLite
                                background={'#757274'}
                                content="Collapse sidebar"
                                direction="right"
                                distance={10}
                                arrowSize={7}
                                isOpen={isTooltipOpen}
                              >
                                <div
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <SvgCollapse height={16} width={16} />
                                </div>
                              </TooltipLite>
                            </Button>
                          </Flex>
                        </Flex>
                        <Flex className={styles.linestyle}></Flex>
                        <Flex className={styles.makeversioncenter}>
                          <Text size={11} style={{ color: '#581845' }}>
                            App Version {version}
                          </Text>
                        </Flex>
                      </Flex>
                    ) : (
                      <TooltipLite
                        background={'#757274'}
                        content="Settings"
                        direction="right"
                        arrowSize={7}
                        isOpen={isTooltipOpensetting}
                      >
                        <div
                          onMouseEnter={handleMouseEntersetting}
                          onMouseLeave={handleMouseLeavesetting}
                          style={{ cursor: 'pointer' }}
                        >
                          <SvgSetting fill={'#581845'} height={20} width={20} />
                        </div>
                      </TooltipLite>
                    )}
                  </Flex>
                </Flex>
              </li>
            )}
            <li
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Expent === '1' && (
                <Button
                  className={styles.Expend}
                  types="link"
                  onClick={() => {
                    handlecheck('0');
                    setIsTooltipOpen(false);
                  }}
                >
                  <TooltipLite
                    background={'#757274'}
                    content="Expand Sidebar"
                    direction="right"
                    arrowSize={7}
                    isOpen={isTooltipOpen}
                  >
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <SvgExpand height={16} width={16} />
                    </div>
                  </TooltipLite>
                </Button>
              )}
            </li>
          </Flex>
        </ul>

        {/* <ul className={styles.setting}>
          {is_plan && Expent === '0' && expire_details?.plan_id === 1 && (
            <Flex style={{ padding: "0px 0px 40px 0px" }} className={styles.makepoation}>
              <Flex row className={styles.freetrailBanner}>
                <Flex style={{ paddingRight: 5 }}>
                  <SvgTimerClock width={16} height={16} />
                </Flex>
                <Text size={13}>
                  Free Trail: <Text size={13} bold>{expire_details?.plan_exp?.days}{' '}</Text>
                  {expire_details?.plan_exp?.days === 1 ? (
                    <>
                      <Text size={13} bold>day</Text> <Text size={13}> left</Text>
                    </>
                  ) : (
                    <>
                      <Text size={13} bold>days</Text> <Text size={13}> left</Text>
                    </>
                  )}
                </Text>
              </Flex>
            </Flex>
          )}
          {is_plan ? (
            <li
              className={
                Expent === '0' ? styles.sidebarexpand : styles.sidebarmin
              }
            >
              <Flex
                onClick={() => {
                  clearTab();
                  handleProfile()
                }}
                className={Expent === '0' ? styles.removepadding : ''}
              >
                {Expent === '0' ?
                  <Flex style={{ width: '155px' }}>
                    <Flex row center className={styles.paddingimport}>
                      <SvgSetting fill={'#581845'} height={20} width={20} />

                      <Text

                        className={Expent === '0' ? styles.textsetting : styles.classpan}
                        color="primary"
                        style={{
                          color: '#581845',
                          marginRight: '10px',
                          bottom: 0,
                          cursor: 'pointer'
                        }}
                      >
                        Settings
                      </Text>
                    </Flex>
                    <Flex className={styles.linestyle}></Flex>
                    <Flex className={styles.makeversioncenter}>
                      <Text size={10} style={{ color: '#581845' }}>App Version : {version}</Text></Flex>
                  </Flex> :
                  <TooltipLite background={'#757274'} content="Settings" arrowSize={7} direction='right' distance={-80} isOpen={isTooltipOpensetting}>
                    <div onMouseEnter={handleMouseEntersetting} onMouseLeave={handleMouseLeavesetting}>
                      <SvgSetting fill={'#581845'} height={20} width={20} />
                    </div>
                  </TooltipLite>}
              </Flex>

            </li>
          ) : (
            <li
              className={
                Expent === '0' ? styles.sidebarexpand : styles.sidebarmin
              }
            >
              <a
                style={{ position: 'relative', marginRight: '10px', bottom: 0 }}
                href={' '}
                onClick={(e) => {
                  e.preventDefault();
                }}
                className={Expent === '0' ? styles.removepadding : styles.removepadding}

              >
                <Flex style={{ marginLeft: Expent === '0' ? '5px' : '0px' }}>
                  {Expent === '0' ?
                    <Flex style={{ width: '155px' }}>
                      <Flex row center className={styles.paddingimport}>
                        <SvgSetting fill={'#581845'} height={20} width={20} />
                        <Text
                          className={
                            Expent === '0' ? styles.textsetting : styles.classpan
                          }
                          color="primary"
                          style={{
                            color: '#581845',
                            marginRight: '10px',
                            bottom: 0,
                            cursor: 'pointer'
                          }}
                        >
                          Settings
                        </Text>
                      </Flex>
                      <Flex className={styles.linestyle}></Flex>
                      <Flex className={styles.makeversioncenter}>
                        <Text size={10} style={{ color: '#581845' }}>App Version : {version}</Text></Flex>
                    </Flex> : (
                      <TooltipLite background={'#757274'} content="Settings" arrowSize={7} direction='right' distance={-80} isOpen={isTooltipOpensetting}>
                        <div onMouseEnter={handleMouseEntersetting} onMouseLeave={handleMouseLeavesetting} style={{ marginLeft: '5px' }}>
                          <SvgSetting fill={'#581845'} height={20} width={20} />
                        </div>
                      </TooltipLite>
                    )}
                </Flex>
              </a>
            </li>
          )}
          <li>
            {Expent === '0' ? (
              <Button
                style={{
                  height: '19px',
                  width: '19px',
                  position: 'relative',
                  bottom: '88px',
                }}
                types="link"
                className={styles.collapse}
                onClick={() => {
                  handlecheck('1');
                  setIsTooltipOpen(false);
                }}
              >
                <TooltipLite
                  background={'#757274'}
                  content="Collapse sidebar"
                  direction="right"
                  distance={10}
                  arrowSize={7}
                  isOpen={isTooltipOpen}
                >
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <SvgCollapse height={16} width={16} />
                  </div>
                </TooltipLite>
              </Button>
            ) : (
              <Button
                className={styles.Expend}
                types="link"
                onClick={() => {
                  handlecheck('0');
                  setIsTooltipOpen(false);
                }}
              >
                <TooltipLite
                  background={'#757274'}
                  content="Expand Sidebar"
                  direction="up"
                  arrowSize={7}
                  isOpen={isTooltipOpen}
                >
                  <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <SvgExpand height={16} width={16} />
                  </div>
                </TooltipLite>
              </Button>
            )}
          </li>

        </ul> */}
      </div>
      <div>
        {isJobsPopupDropdownOpen && (
          <div ref={jobsdropdownRef}>
            <Flex className={styles.jobspopupdropdown}>
              {/* Post New Jobs */}
              {permission.includes('create_post') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Post New Job">
                      <li
                        className={
                          pathname === jobCreateNonDs
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={is_plan ? jobCreateNonDs : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              className={
                                Expent === '0' ? styles.text : styles.classpan
                              }
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Post New Job
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Post New Job">
                      <li
                        className={
                          pathname === jobCreateNonDs
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={is_plan ? jobCreateNonDs : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Post New Job
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Post New Job">
                    <li
                      className={
                        pathname === jobCreateNonDs
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Post New Job
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {/* Hiring Workflow */}
              <>
                {is_plan ? (
                  changes ? (
                    // <CustomTooltip title="Workflow Settings">
                    <li
                      className={
                        pathname === '/account_setting/hiring_workflow'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{ textIndent: '10px' }}
                    >
                      <LinkWrapper onClick={clearTabs}>
                        <div className={styles.hoverview}>
                          <Text
                            className={styles.text}
                            color="primary"
                            style={{ color: '#581845', marginRight: '10px' }}
                          >
                            Hiring Workflow
                          </Text>
                        </div>
                      </LinkWrapper>
                    </li>
                  ) : (
                    // </CustomTooltip>
                    // <CustomTooltip title="Workflow Settings">
                    <li
                      className={
                        pathname === '/account_setting/hiring_workflow'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{ textIndent: '10px' }}
                    >
                      <LinkWrapper
                        onClick={() => {
                          clearTab();
                        }}
                        to={
                          is_plan
                            ? '/account_setting/hiring_workflow'
                            : accountPath
                        }
                      >
                        <div className={styles.hoverview}>
                          <Text
                            className={styles.text}
                            color="primary"
                            style={{ color: '#581845', marginRight: '10px' }}
                          >
                            Hiring Workflow
                          </Text>
                        </div>
                      </LinkWrapper>
                    </li>
                    // </CustomTooltip>
                  )
                ) : (
                  // <CustomTooltip title="Workflow Settings">
                  <li
                    className={
                      pathname === '/account_setting/hiring_workflow'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{
                      width: '100%',
                      height: '100%',
                      alignItems: 'center',
                      display: 'flex',
                      textIndent: '10px',
                    }}
                  >
                    <a
                      style={{ width: '100%' }}
                      className={styles.hoverview}
                      href={' '}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Text
                        className={styles.text}
                        color="primary"
                        style={{ color: '#581845', marginRight: '10px' }}
                      >
                        Hiring Workflow
                      </Text>
                    </a>
                  </li>
                  // </CustomTooltip>
                )}
              </>
            </Flex>
          </div>
        )}
        {isViewJobsPopupDropdownOpen && (
          <div ref={viewjobsdropdownRef}>
            <Flex className={styles.viewjobspopupdropdown}>
              {/* Job Posting */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="Job Listings">
                  <li
                    className={
                      pathname === '/job_list' ||
                      pathname.includes('/job_view') ||
                      pathname.includes('/zita_match_candidate') ||
                      pathname.includes('/applicant_pipe_line')
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTabs}
                      to={is_plan ? routesPath.MY_JOB_POSTING : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(2)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Job Listings
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="Job Listings">
                  <li
                    className={
                      pathname === '/job_list' ||
                      pathname.includes('/job_view') ||
                      pathname.includes('/zita_match_candidate') ||
                      pathname.includes('/applicant_pipe_line')
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTab}
                      to={is_plan ? routesPath.MY_JOB_POSTING : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(2)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Job Listings
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="Job Listings">
                <li
                  className={
                    pathname === '/job_list' ||
                    pathname.includes('/job_view') ||
                    pathname.includes('/zita_match_candidate') ||
                    pathname.includes('/applicant_pipe_line')
                      ? styles.selectpopup_row
                      : ''
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      onClick={() => handleNavigate(2)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Job Listings
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}
              {/* Jobs Workflow */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="Jobs Workflow">
                  <li
                    className={
                      pathname === '/jobs_workflow'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTabs}
                      to={is_plan ? '/jobs_workflow' : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(2)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Jobs Workflow
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="Jobs Workflow">
                  <li
                    className={
                      pathname === '/jobs_workflow'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTab}
                      to={is_plan ? '/jobs_workflow' : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(2)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Jobs Workflow
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="Jobs Workflow">
                <li
                  className={
                    pathname === '/jobs_workflow' ? styles.selectpopup_row : ''
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      onClick={() => handleNavigate(2)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Jobs Workflow
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}
            </Flex>
          </div>
        )}
        {isCandiPopupDropdownOpen && (
          <div ref={candidatesdropdownRef}>
            <Flex className={styles.candipopupdropdown}>
              {/* Add Candidates */}
              {permission.includes('bulkImport_candidates') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Add Candidates">
                      <li
                        className={
                          pathname === '/bulk_import'
                            ? Number(isbulktabhandle) === 0
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={is_plan ? '/bulk_import?tab=0' : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Add Candidates
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Add Candidates">
                      <li
                        className={
                          pathname === '/bulk_import'
                            ? Number(isbulktabhandle) === 0
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={is_plan ? '/bulk_import?tab=0' : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Add Candidates
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Add Candidates">
                    <li
                      className={
                        pathname === '/bulk_import'
                          ? Number(isbulktabhandle) === 0
                            ? styles.selectpopup_row
                            : ''
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(5)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Add Candidates
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {/* Add New Applicants */}
              {permission.includes('bulkImport_candidates') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Add New Applicants">
                      <li
                        className={
                          pathname === '/bulk_import'
                            ? Number(isbulktabhandle) === 1
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={is_plan ? '/bulk_import?tab=1' : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Add New Applicants
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Add New Applicants">
                      <li
                        className={
                          pathname === '/bulk_import'
                            ? Number(isbulktabhandle) === 1
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={is_plan ? '/bulk_import?tab=1' : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Add New Applicants
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Add New Applicants">
                    <li
                      className={
                        pathname === '/bulk_import'
                          ? Number(isbulktabhandle) === 1
                            ? styles.selectpopup_row
                            : ''
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(5)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Add New Applicants
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {/* Sourcing Candidates */}
              {/* {permission.includes('talent_sourcing') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Source Candidates">
                      <li
                        className={
                          pathname === '/source_candidates'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={
                            is_plan ? routesPath.TALENT_SOURCING : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(4)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Source Candidates
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Source Candidates">
                      <li
                        className={
                          pathname === '/source_candidates'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={
                            is_plan ? routesPath.TALENT_SOURCING : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(4)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Source Candidates
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Source Candidates">
                    <li
                      className={
                        pathname === '/source_candidates'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(4)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Source Candidates
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )} */}
              {/* All Candidates */}
              {permission.includes('my_database') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="All Candidates">
                      <li
                        className={
                          pathname === '/all_candidates'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          className={styles.hoverview}
                          to={is_plan ? routesPath.MYDATABASE : accountPath}
                          onClick={clearTabs}
                        >
                          <Text
                            onClick={() => handleNavigate(3)}
                            className={styles.text}
                            color="primary"
                            style={{
                              color: '#581845',
                              marginRight: '10px',
                            }}
                          >
                            All Candidates
                          </Text>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="All Candidates">
                      <li
                        className={
                          pathname === '/all_candidates'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          to={is_plan ? routesPath.MYDATABASE : accountPath}
                          onClick={clearTab}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(3)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              All Candidates
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="All Candidates">
                    <li
                      className={
                        pathname === '/all_candidates'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(3)}
                          className={styles.text}
                          color="primary"
                          style={{
                            color: '#581845',
                            marginRight: '10px',
                          }}
                        >
                          All Candidates
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
            </Flex>
          </div>
        )}
        {isSourcePopupDropdownOpen && (
          <div ref={candidatesdropdownRef}>
            <Flex className={styles.sourcepopupdropdown}>
              {/* Resume Library */}
              {permission.includes('talent_sourcing') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Add Candidates">
                      <li
                        className={
                          pathname === '/candidates_sourcing'
                            ? Number(isCandidatetabhandle) === 0
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={
                            is_plan ? '/candidates_sourcing?tab=0' : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Resume Library
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Add Candidates">
                      <li
                        className={
                          pathname === '/candidates_sourcing'
                            ? Number(isCandidatetabhandle) === 0
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={
                            is_plan ? '/candidates_sourcing?tab=0' : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Resume Library
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Add Candidates">
                    <li
                      className={
                        pathname === '/candidates_sourcing'
                          ? Number(isCandidatetabhandle) === 0
                            ? styles.selectpopup_row
                            : ''
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(5)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Resume Library
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {/* Linkedin Sourcing */}
              {permission.includes('talent_sourcing') && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Add New Applicants">
                      <li
                        className={
                          pathname === '/candidates_sourcing'
                            ? Number(isCandidatetabhandle) === 1
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={
                            is_plan ? '/candidates_sourcing?tab=1' : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Linkedin Sourcing
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Add New Applicants">
                      <li
                        className={
                          pathname === '/candidates_sourcing'
                            ? Number(isCandidatetabhandle) === 1
                              ? styles.selectpopup_row
                              : ''
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={
                            is_plan ? '/candidates_sourcing?tab=1' : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(5)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Linkedin Sourcing
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Add New Applicants">
                    <li
                      className={
                        pathname === '/candidates_sourcing'
                          ? Number(isCandidatetabhandle) === 1
                            ? styles.selectpopup_row
                            : ''
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(5)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Linkedin Sourcing
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {/* Unlocked Candidates */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="Source Candidates">
                  <li
                    className={
                      pathname === '/candidates_sourcing'
                        ? (
                            permission.includes('talent_sourcing')
                              ? Number(isCandidatetabhandle) === 2
                              : Number(isCandidatetabhandle) === 0
                          )
                          ? styles.selectpopup_row
                          : ''
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTabs}
                      to={
                        is_plan
                          ? permission.includes('talent_sourcing')
                            ? '/candidates_sourcing?tab=2'
                            : '/candidates_sourcing?tab=0'
                          : accountPath
                      }
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(4)}
                          className={styles.text}
                          color="primary"
                          style={{
                            color: '#581845',
                            marginRight: '10px',
                          }}
                        >
                          Unlocked Candidates
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="Source Candidates">
                  <li
                    className={
                      pathname === '/candidates_sourcing'
                        ? (
                            permission.includes('talent_sourcing')
                              ? Number(isCandidatetabhandle) === 2
                              : Number(isCandidatetabhandle) === 0
                          )
                          ? styles.selectpopup_row
                          : ''
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTab}
                      to={
                        is_plan
                          ? permission.includes('talent_sourcing')
                            ? '/candidates_sourcing?tab=2'
                            : '/candidates_sourcing?tab=0'
                          : accountPath
                      }
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(4)}
                          className={styles.text}
                          color="primary"
                          style={{
                            color: '#581845',
                            marginRight: '10px',
                          }}
                        >
                          Unlocked Candidates
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="Source Candidates">
                <li
                  className={
                    pathname === '/candidates_sourcing'
                      ? (
                          permission.includes('talent_sourcing')
                            ? Number(isCandidatetabhandle) === 2
                            : Number(isCandidatetabhandle) === 0
                        )
                        ? styles.selectpopup_row
                        : ''
                      : ''
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      onClick={() => handleNavigate(4)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Unlocked Candidates
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}
            </Flex>
          </div>
        )}
        {isCommPopupDropdownOpen && (
          <div ref={communicationdropdownRef}>
            <Flex className={styles.commpopupdropdown}>
              {/* Mailbox */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="MailBox">
                  <li
                    className={
                      pathname === '/mail' ? styles.selectpopup_row : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTabs}
                      to={is_plan ? routesPath.MAIL : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(7)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Mailbox
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="MailBox">
                  <li
                    className={
                      pathname === '/mail' ? styles.selectpopup_row : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTab}
                      to={is_plan ? routesPath.MAIL : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(7)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Mailbox
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="MailBox">
                <li
                  className={pathname === '/mail' ? styles.selectpopup_row : ''}
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      onClick={() => handleNavigate(7)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Mailbox
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}
              {/* Interview Scheduler */}
              {
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title=" Interview Scheduler">
                      <li
                        className={
                          pathname === meetingScheduler
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={is_plan ? meetingScheduler : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(8)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Event Scheduler
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title=" Interview Scheduler">
                      <li
                        className={
                          pathname === meetingScheduler
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          className={styles.hoverview}
                          onClick={clearTab}
                          to={is_plan ? meetingScheduler : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(8)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Event Scheduler
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title=" Interview Scheduler">
                    <li
                      className={
                        pathname === meetingScheduler
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(8)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Event Scheduler
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              }
              {/* Calendar */}
              {
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Calendar">
                      <li
                        className={
                          pathname === '/calendar' ? styles.selectpopup_row : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTabs}
                          to={is_plan ? routesPath.CALENDAR : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(7)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Calendar
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Calendar">
                      <li
                        className={
                          pathname === '/calendar' ? styles.selectpopup_row : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          to={is_plan ? routesPath.CALENDAR : accountPath}
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(7)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Calendar
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Calendar">
                    <li
                      className={
                        pathname === '/calendar' ? styles.selectpopup_row : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          onClick={() => handleNavigate(7)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Calendar
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              }
              {/* Integrations */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="Integrations">
                  <li
                    // className={pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                    className={
                      pathname === '/account_setting/integration'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper onClick={clearTabs}>
                      <div className={styles.hoverview}>
                        <Text
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Integrations
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="Integrations">
                  <li
                    // className={pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                    className={
                      pathname === '/account_setting/integration'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={() => {
                        clearTab();
                      }}
                      to={
                        is_plan ? '/account_setting/integration' : accountPath
                      }
                    >
                      <div className={styles.hoverview}>
                        <Text
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Integrations
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="Integrations">
                <li
                  // className={pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                  className={
                    pathname === '/account_setting/integration'
                      ? styles.selectpopup_row
                      : ''
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      // onClick={() => handleNavigate(1)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Integrations
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}
            </Flex>
          </div>
        )}
        {isBrandPopupDropdownOpen && (
          <div ref={branddropdownRef}>
            <Flex className={styles.brandpopupdropdown}>
              {/* Build Your Careers Page */}
              {super_user === true && roles === 'Admin' && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Build Careers Page">
                      <li
                        className={
                          pathname === '/account_setting/build_careers_page'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper onClick={clearTabs}>
                          <div className={styles.hoverview}>
                            <Text
                              // onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Build Careers Page
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Build Careers Page">
                      <li
                        className={
                          pathname === '/account_setting/build_careers_page'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          // onClick={changeurlss}
                          to={
                            is_plan
                              ? '/account_setting/build_careers_page'
                              : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              // onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Build Careers Page
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Build Careers Page">
                    <li
                      className={
                        pathname.includes('/account_setting/build_careers_page')
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          // onClick={() => handleNavigate(1)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Build Careers Page
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {super_user === false && roles === 'Admin' && (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Build Careers Page">
                      <li
                        className={
                          pathname.includes(
                            '/account_setting/build_careers_page',
                          )
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper onClick={clearTabs}>
                          <div className={styles.hoverview}>
                            <Text
                              // onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Build Careers Page
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Build Careers Page">
                      <li
                        className={
                          pathname.includes(
                            '/account_setting/build_careers_page',
                          )
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={clearTab}
                          // onClick={changeurlss}
                          to={
                            is_plan
                              ? '/account_setting/build_careers_page'
                              : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              // onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Build Careers Page
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Build Careers Page">
                    <li
                      className={
                        pathname === '/account_setting/build_careers_page'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          // onClick={() => handleNavigate(1)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Build Careers Page
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              )}
              {roles === 'HR' &&
                permission.includes('manage_account_settings') && (
                  <>
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Build Careers Page">
                        <li
                          className={
                            pathname === '/account_setting/build_careers_page'
                              ? styles.selectpopup_row
                              : ''
                          }
                          style={{ textIndent: '10px' }}
                        >
                          <LinkWrapper onClick={clearTabs}>
                            <div className={styles.hoverview}>
                              <Text
                                // onClick={() => handleNavigate(1)}
                                className={styles.text}
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginRight: '10px',
                                }}
                              >
                                Build Careers Page
                              </Text>
                            </div>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Build Careers Page">
                        <li
                          className={
                            pathname === '/account_setting/build_careers_page'
                              ? styles.selectpopup_row
                              : ''
                          }
                          style={{ textIndent: '10px' }}
                        >
                          <LinkWrapper
                            onClick={clearTab}
                            // onClick={changeurlss}
                            to={
                              is_plan
                                ? '/account_setting/build_careers_page'
                                : accountPath
                            }
                          >
                            <div className={styles.hoverview}>
                              <Text
                                // onClick={() => handleNavigate(1)}
                                className={styles.text}
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginRight: '10px',
                                }}
                              >
                                Build Careers Page
                              </Text>
                            </div>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Build Careers Page">
                      <li
                        className={
                          pathname === '/account_setting/build_careers_page'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                          alignItems: 'center',
                          display: 'flex',
                          textIndent: '10px',
                        }}
                      >
                        <a
                          style={{ width: '100%' }}
                          className={styles.hoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            // onClick={() => handleNavigate(1)}
                            className={styles.text}
                            color="primary"
                            style={{ color: '#581845', marginRight: '10px' }}
                          >
                            Build Careers Page
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                  </>
                )}
              {roles === 'Hiring' &&
                permission.includes('manage_account_settings') && (
                  <>
                    {is_plan ? (
                      changes ? (
                        // <CustomTooltip title="Build Careers Page">
                        <li
                          className={
                            pathname.includes(
                              '/account_setting/build_careers_page',
                            )
                              ? styles.selectpopup_row
                              : ''
                          }
                          style={{ textIndent: '10px' }}
                        >
                          <LinkWrapper onClick={clearTabs}>
                            <div className={styles.hoverview}>
                              <Text
                                // onClick={() => handleNavigate(1)}
                                className={styles.text}
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginRight: '10px',
                                }}
                              >
                                Build Careers Page
                              </Text>
                            </div>
                          </LinkWrapper>
                        </li>
                      ) : (
                        // </CustomTooltip>
                        // <CustomTooltip title="Build Careers Page">
                        <li
                          className={
                            pathname === '/account_setting/build_careers_page'
                              ? styles.selectpopup_row
                              : ''
                          }
                          style={{ textIndent: '10px' }}
                        >
                          <LinkWrapper
                            onClick={clearTab}
                            // onClick={changeurlss}
                            to={
                              is_plan
                                ? '/account_setting/build_careers_page'
                                : accountPath
                            }
                          >
                            <div className={styles.hoverview}>
                              <Text
                                // onClick={() => handleNavigate(1)}
                                className={styles.text}
                                color="primary"
                                style={{
                                  color: '#581845',
                                  marginRight: '10px',
                                }}
                              >
                                Build Careers Page
                              </Text>
                            </div>
                          </LinkWrapper>
                        </li>
                        // </CustomTooltip>
                      )
                    ) : (
                      // <CustomTooltip title="Build Careers Page">
                      <li
                        className={
                          pathname === '/account_setting/build_careers_page'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{
                          width: '100%',
                          height: '100%',
                          alignItems: 'center',
                          display: 'flex',
                          textIndent: '10px',
                        }}
                      >
                        <a
                          style={{ width: '100%' }}
                          className={styles.hoverview}
                          href={' '}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Text
                            // onClick={() => handleNavigate(1)}
                            className={styles.text}
                            color="primary"
                            style={{ color: '#581845', marginRight: '10px' }}
                          >
                            Build Careers Page
                          </Text>
                        </a>
                      </li>
                      // </CustomTooltip>
                    )}
                  </>
                )}
              {/* Careers Page */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="View Careers Page">
                  <li
                    // className={pathname === '/' ? styles.selectpopup_row : ''}
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper onClick={clearTabs}>
                      <div className={styles.hoverview}>
                        <Text
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          View Careers Page
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="View Careers Page">
                  <li
                    // className={pathname === '/' ? styles.selectpopup_row : ''}
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTab}
                      target={isEmpty(careerPageURL) ? '_parent' : '_blank'}
                      to={
                        isEmpty(careerPageURL)
                          ? `/account_setting/build_careers_page`
                          : `/${careerPageURL}/careers`
                      }
                    >
                      <div className={styles.hoverview}>
                        <Text
                          onClick={() => handleNavigate(1)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          View Careers Page
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="View Careers Page">
                <li
                  // className={pathname === '/' ? styles.selectpopup_row : ''}
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      View Careers Page
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}

              {/* {is_plan ? (
                changes ? (
                  <li
                    title="Email Notifications"
                    className={sessionStorage.getItem('superUserTab') === '5'
                      && pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper onClick={clearTabs}>
                      <div className={styles.hoverview}>
                        <Text
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Email Notifications
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  <li
                    title="Email Notifications"
                    // className={pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                    className={sessionStorage.getItem('superUserTab') === '5'
                      && pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={() => {
                        clearTab();
                      }}
                      to={is_plan ? '/account_setting/settings?tab=5' : accountPath}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Email Notifications
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                )
              ) : (
                <li
                  title="Email Notifications"
                  // className={pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                  className={sessionStorage.getItem('superUserTab') === '5'
                    && pathname.includes('/account_setting') ? styles.selectpopup_row : ''}
                  style={{ textIndent: '10px' }}
                >
                  <a
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      // onClick={() => handleNavigate(1)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Email Notifications
                    </Text>
                  </a>
                </li>
              )} */}
            </Flex>
          </div>
        )}
        {isMyaccPopupDropdownOpen && (
          <div ref={myaccountdropdownRef}>
            <Flex className={styles.myaccpopupdropdown}>
              {/* Profiles */}
              {is_plan ? (
                changes ? (
                  // <CustomTooltip title="Profile">
                  <li
                    className={
                      pathname === '/account_setting/profiles'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper onClick={clearTabs}>
                      <div className={styles.hoverview}>
                        <Text
                          // onClick={() => handleNavigate(9)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Profiles
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                ) : (
                  // </CustomTooltip>
                  // <CustomTooltip title="Profile">
                  <li
                    className={
                      pathname === '/account_setting/profiles'
                        ? styles.selectpopup_row
                        : ''
                    }
                    style={{ textIndent: '10px' }}
                  >
                    <LinkWrapper
                      onClick={clearTab}
                      // onClick={changeurlss}
                      to={'/account_setting/profiles'}
                    >
                      <div className={styles.hoverview}>
                        <Text
                          // onClick={() => handleNavigate(9)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Profiles
                        </Text>
                      </div>
                    </LinkWrapper>
                  </li>
                  // </CustomTooltip>
                )
              ) : (
                // <CustomTooltip title="Profile">
                <li
                  className={
                    pathname === '/account_setting/profiles'
                      ? styles.selectpopup_row
                      : ''
                  }
                  style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    textIndent: '10px',
                  }}
                >
                  <a
                    style={{ width: '100%' }}
                    className={styles.hoverview}
                    href={' '}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Text
                      // onClick={() => handleNavigate(9)}
                      className={styles.text}
                      color="primary"
                      style={{ color: '#581845', marginRight: '10px' }}
                    >
                      Profiles
                    </Text>
                  </a>
                </li>
                // </CustomTooltip>
              )}
              {/* Subscription & Billing */}
              {super_user === true ? (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="My Plan">
                      <li
                        className={
                          pathname === '/account_setting/subscription'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper onClick={clearTabs}>
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Subscription & Billing
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="My Plan">
                      <li
                        className={
                          pathname === '/account_setting/subscription'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={() => {
                            clearTab();
                          }}
                          // onClick={changeurlss}
                          to={
                            is_plan
                              ? '/account_setting/subscription'
                              : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              // onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Subscription & Billing
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="My Plan">
                    <li
                      className={
                        pathname === '/account_setting/subscription'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          // onClick={() => handleNavigate(1)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Subscription & Billing
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              ) : (
                ''
              )}
              {/* Manage Users */}
              {super_user === true ? (
                <>
                  {is_plan ? (
                    changes ? (
                      // <CustomTooltip title="Manage Users">
                      <li
                        className={
                          pathname === '/account_setting/manage_user'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper onClick={clearTabs}>
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Manage Users
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                    ) : (
                      // </CustomTooltip>
                      // <CustomTooltip title="Manage Users">
                      <li
                        className={
                          pathname === '/account_setting/manage_user'
                            ? styles.selectpopup_row
                            : ''
                        }
                        style={{ textIndent: '10px' }}
                      >
                        <LinkWrapper
                          onClick={() => {
                            clearTab();
                          }}
                          // onClick={changeurlss}
                          to={
                            is_plan
                              ? '/account_setting/manage_user'
                              : accountPath
                          }
                        >
                          <div className={styles.hoverview}>
                            <Text
                              onClick={() => handleNavigate(1)}
                              className={styles.text}
                              color="primary"
                              style={{
                                color: '#581845',
                                marginRight: '10px',
                              }}
                            >
                              Manage Users
                            </Text>
                          </div>
                        </LinkWrapper>
                      </li>
                      // </CustomTooltip>
                    )
                  ) : (
                    // <CustomTooltip title="Manage Users">
                    <li
                      className={
                        pathname === '/account_setting/manage_user'
                          ? styles.selectpopup_row
                          : ''
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        display: 'flex',
                        textIndent: '10px',
                      }}
                    >
                      <a
                        style={{ width: '100%' }}
                        className={styles.hoverview}
                        href={' '}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Text
                          // onClick={() => handleNavigate(1)}
                          className={styles.text}
                          color="primary"
                          style={{ color: '#581845', marginRight: '10px' }}
                        >
                          Manage Users
                        </Text>
                      </a>
                    </li>
                    // </CustomTooltip>
                  )}
                </>
              ) : (
                ''
              )}
            </Flex>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
