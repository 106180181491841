import axios from 'axios';
import classNames from 'classnames/bind';
import moment from 'moment';
import { createRef, useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SvgBell from '../../../icons/SvgBell';
import SvgClose from '../../../icons/SvgClose';
import { notificationApi } from '../../../routes/apiRoutes';
import { AppDispatch, RootState } from '../../../store';
import Modal from '../../../uikit/Modal/Modal';
import Button from '../../../uikit/Button/Button';
import Card from '../../../uikit/Card/Card';
import Flex from '../../../uikit/Flex/Flex';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import CustomTooltip from '../../../uikit/Tooltip/tooltip';
import Text from '../../../uikit/Text/Text';
import Toast from '../../../uikit/Toast/Toast';
import ProfileView from '../../applicantpipelinemodule/ProfileView';
import SubscriptionModal from '../../subscriptionmodule/subscriptionmoduleScreen';
import { OthersEntity } from './navbarTypes';
import styles from './notification.module.css';
import {
  notificationDeleteMiddleWare,
  notificationPostMiddleWare,
} from './store/navbarmiddleware';


const cx = classNames.bind(styles);

const Notification = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isData, setData] = useState<any>();
  const myRef = createRef<any>();
  const [isJd, setJd] = useState('0');
  const [isCandi, setCandi] = useState();
  const [isProfile, setProfile] = useState(false);
  const [modelopen, setmodelopen] = useState(false);
  const [check, setcheck] = useState(false);
  const [isMessageTab, setMessageTab] = useState(1);
  const [isopensubcription, setopensubcription] = useState(false);
  const [sendData, setsendData] = useState('');
  const [sendAddon, setsendAddon] = useState(null);
  const [purpose, setpurpose] = useState('');
  const [freetrial, setfreetrial] = useState('');
  const [purpose_addon, setpurpose_addon] = useState('');
  const dropDownRef = useRef(null);
  const history = useHistory();

  const {
    permission,
    super_user,
    roles,
    current_resume_count,
    ai_matched_count,
    active_resume,
  } = useSelector(
    ({ permissionReducers, SubscriptionReducers, applicantPipeLineDataReducers }: RootState) => {
      return {
        permission: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        roles: permissionReducers.roles,
        current_resume_count:SubscriptionReducers.current_resume_count,
        ai_matched_count: applicantPipeLineDataReducers.ai_matched_count,
        active_resume:SubscriptionReducers.active_resume
      };
    },
  );

  useEffect(() => {
    axios.get(notificationApi).then((res) => {
      setData(res.data);
    });
  }, []);
  // notification api call
  const closeDropDown = (e: any) => {
    if (
      dropDownRef.current &&
      modelopen &&
      !dropDownRef.current.contains(e.target)
    ) {
      setmodelopen(false);
    }
  };
  const handleIntegrations = () => {
    sessionStorage.setItem('CommunicationDropdown', '1')
    history.push('/account_setting/integration');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(notificationApi).then((res) => {
        setData(res.data);
      });
    }, 4500);
    return () => clearInterval(interval);
  }, []);

  const handleClickOutside = (event: { target: any }) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (typeof Window !== 'undefined') {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (myRef) {
        if (typeof Window !== 'undefined') {
          document.removeEventListener('click', handleClickOutside, true);
        }
      }
    };
  });

  // notification delete function
  const handleDelete = () => {
    dispatch(notificationDeleteMiddleWare()).then(() => {
      axios.get(notificationApi).then((res) => {
        setData(res.data);
        setmodelopen(false);
        Toast('Notifications cleared successfully.');
      });
    });
    setcheck(true);
    setmodelopen(false);
    setOpen(false);
  };

  // notification read function
  const handleReadNotification = (id: number) => {
    dispatch(notificationPostMiddleWare({ id })).then(() => {
      axios.get(notificationApi).then((res) => {
        setData(res.data);
      });
    });
    setOpen(false);
  };
  const handlecleartab = () => {
    sessionStorage.removeItem('superUserTab');
    sessionStorage.removeItem('superUserFalseTab');
    sessionStorage.removeItem('superUserTabTwo');
  }
  const myfunction = () => {
    {
      setOpen(true);
    }
  };

  const handleclose = () => {
    setOpen(false);
  };
  const godatabase = () => {
    history.push(`/all_candidates`);
  }
  const goMeetingScheduler = (target) => {
    history.push(`/event_scheduler?event=${target}`);
  }

  const handleconditionchecking = (val1,val2) => { 
    
    const newValue = val1
    if(newValue.includes(val2)){
      return true;
    }
    else{
      return false;
    }
   
  }

  const open_subscription = (val, data) => {
    if (data === 0) {
      setsendData('Ai_matching_applicant');

      if (current_resume_count === 0 && ai_matched_count === 0) {
        setpurpose(
          "Advanced AI Matching Limit And AI Resume Parsing Limit Reached: To continue viewing applicants, you must 'Upgrade your plan' or 'Buy additional AI Matching with Descriptive Analytics Credits'and 'Buy additional AI Resume parsing Credits'.",
        );
        setfreetrial(
          'AI Resume Parsing & AI Matching Limit Exceeds: To continue viewing applicants, you must Upgrade your plan',
        );
        setpurpose_addon(
          "Advanced AI Matching Limit And AI Resume Parsing Limit Reached: To continue viewing applicants, you must 'Buy additional AI Matching with Descriptive Analytics Credits'and 'Buy additional AI Resume parsing Credits'.",
        );
        setsendAddon([
          'AI Resume Parsing Credits',
          'AI Resume Matching with Descriptive Analytics',
        ]);
      } else if (current_resume_count === 0) {
        setpurpose(
          "AI Resume Parsing Limit Exceeds: To continue viewing applicants, you must 'Upgrade your plan' or 'Buy additional AI Resume parsing Credits'.",
        );
        setfreetrial(
          "AI Resume Parsing Limit Exceeds: To continue viewing applicants, you must 'Upgrade your plan'.",
        );
        setpurpose_addon(
          "AI Resume Parsing Limit Reached: To continue viewing applicants, you must 'Buy additional AI Resume parsing Credits'.",
        );
        setsendAddon(['AI Resume Parsing Credits']);
      }
    } else {
      setsendData('Ai_comparative');
      setsendAddon(['AI Resume Comparative Analysis & Recommendation to Hire']);
      setpurpose('');
      setfreetrial('');
      setpurpose_addon('');
    }
    setopensubcription(val);
  };



  return (
    <Flex>
      <div ref={myRef} style={{ position: 'relative' }}>
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          addon_name={sendAddon}
          differece_key={[sendData]}
          purpose={purpose}
          purpose_freetrial={freetrial}
          purpose_addon={purpose_addon}
        />
      )}
        <ProfileView
          open={isProfile}
          cancel={() => {
            setProfile(false);
          }}
          jobId={isJd}
          candidateId={isCandi}
          inviteIconNone
          applicantpipeline={false}
          activeState={isMessageTab}
        />
        <CustomTooltip title="Notifications">
          <div
            onClick={() => setOpen(!isOpen)}
            tabIndex={-1}
            onKeyDown={() => { }}
            role="button"
            className={styles.hoverback}
            style={{ position: 'relative', padding: ' 5px' }}
          >
            {isData && isData?.total_unread !== 0 && (
              <div className={styles.countStyle}>
                <Text color="white" style={{ fontSize: 10, marginTop: '2px' }}>
                  {isData && isData.total_unread}
                </Text>
              </div>
            )}
            <SvgBell fill={'#581845'} height={20} width={20} />
          </div>
        </CustomTooltip>
        {isOpen && (
          <Card
            className={
              isData && isData.total !== 0 ? styles.overAll : styles.overAllOne
            }
          >
            {isData && isData?.total !== 0 ? (
              <>
                <Flex row center between className={styles.headerStyle}>
                  <Text color="theme" style={{ fontSize: '14px' }} bold>
                    Notifications
                  </Text>
                  <Flex onClick={handleclose} style={{ cursor: 'pointer' }}>
                    <SvgClose width={10} height={10} fill={'#888888'} />
                  </Flex>
                </Flex>
                <hr className={styles.line} />
                <Flex columnFlex className={styles.scrollStyle}>
                  {isData && isData?.today && isData?.today?.length !== 0 && (
                    <Flex columnFlex className={cx('borderBottom')}>
                      {isData &&
                        isData?.today?.map(
                          (list: OthersEntity, index: number) => {
                            let getPath = '';
                            if (
                              list?.description?.toLowerCase() === 'bulkimport'
                            ) {
                              getPath = `/bulk_import?tab=${list.action_object_object_id === null ? 0 : 1}${list.action_object_object_id === null ? '' : `&jd_ids=${list.action_object_object_id}`}`;
                            }
                            return (
                              <>
                                <Flex key={index + list?.id}>
                                  {index === 0 && (
                                    <Text
                                      style={{
                                        padding: '8px 16px 0px 16px',
                                        fontWeight: 600,
                                        fontSize: '13px',
                                      }}
                                      bold
                                    >
                                      TODAY
                                    </Text>
                                  )}
                                  {/* {list?.description?.toLowerCase() ===
                                  'bulkimport' ? (
                                    <>
                                      {' '}
                                      {index === 0 ? (
                                        ''
                                      ) : (
                                        <hr className={styles.hr_line} />
                                      )}
                                      <LinkWrapper
                                        to={getPath}
                                        onClick={() => {
                                          handleReadNotification(list.id);
                                          handlecleartab();
                                          sessionStorage.setItem('CandidateDropdown', '1');
                                        }}
                                      >
                                        <Flex
                                          row
                                          center
                                          between
                                          className={styles.hoverStyle}
                                        >
                                          <Flex
                                            className={styles.wordContainer}
                                          >
                                            <Text
                                              style={{
                                                fontSize: '13px',
                                              }}
                                            >
                                              {list?.verb}
                                            </Text>
                                          </Flex>
                                          <Flex row>
                                            <Flex end>
                                              <Text
                                                style={{
                                                  marginLeft: 3,
                                                  fontSize: '13px',
                                                  display: 'flex',
                                                  // width: '28%',
                                                }}
                                                color="gray"
                                              >
                                                {moment(
                                                  list?.timestamp,
                                                )?.fromNow()}
                                              </Text>
                                            </Flex>
                                            <Flex width={15}>
                                              {list.unread && (
                                                <div
                                                  className={styles.readStyle}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </LinkWrapper>
                                    </>
                                  ) : list?.description?.toLowerCase() ===
                                    'candidatenotes' ? (
                                    <>
                                      {index === 0 ? (
                                        ''
                                      ) : (
                                        <hr className={styles.hr_line} />
                                      )}
                                      <Flex
                                        onClick={() => {
                                          setCandi(list.target_object_id);
                                          setProfile(true);
                                          setMessageTab(1);
                                          handleReadNotification(list.id);
                                        }}
                                      >
                                        <Flex row className={styles.hoverStyle}>
                                          <Flex
                                            row
                                            between
                                            className={styles.listStyle}
                                          >
                                            <Flex
                                              className={styles.wordContainer}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: '13px',
                                                }}
                                              >
                                                {list.verb}
                                              </Text>
                                            </Flex>
                                            <Flex row>
                                              <Flex end>
                                                <Text
                                                  style={{
                                                    marginLeft: 3,
                                                    fontSize: '13px',
                                                    display: 'flex',
                                                    // width: '28%',
                                                  }}
                                                  color="gray"
                                                >
                                                  {moment(
                                                    list?.timestamp,
                                                  ).fromNow()}
                                                </Text>
                                              </Flex>
                                              <Flex width={15}>
                                                {list.unread && (
                                                  <div
                                                    className={styles.readStyle}
                                                  />
                                                )}
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </>
                                  ) : list?.description?.toLowerCase() ===             //Event Scheduler Code
                                  'event' ? (
                                  <>
                                    {index === 0 ? (
                                      ''
                                    ) : (
                                      <hr className={styles.hr_line} />
                                    )}
                                    <Flex
                                      onClick={() => {
                                        goMeetingScheduler(list.target_object_id)
                                        handleReadNotification(list?.id);
                                      }}
                                    >
                                      <Flex row className={styles.hoverStyle}>
                                        <Flex
                                          row
                                          between
                                          className={styles.listStyle}
                                        >
                                          <Flex
                                            className={styles.wordContainer}
                                          >
                                            <Text
                                              style={{
                                                fontSize: '13px',
                                              }}
                                            >
                                              {list.verb}
                                            </Text>
                                          </Flex>
                                          <Flex row>
                                            <Flex end>
                                              <Text
                                                style={{
                                                  marginLeft: 3,
                                                  fontSize: '13px',
                                                  display: 'flex',
                                                  // width: '28%',
                                                }}
                                                color="gray"
                                              >
                                                {moment(
                                                  list?.timestamp,
                                                ).fromNow()}
                                              </Text>
                                            </Flex>
                                            <Flex width={15}>
                                              {list.unread && (
                                                <div
                                                  className={styles.readStyle}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  </>
                                  ) : list?.description?.toLowerCase() ===
                                      'database' ? (
                                      <>
                                        {index === 0 ? (
                                          ''
                                        ) : (
                                          <hr className={styles.hr_line} />
                                        )}

                                        <Flex
                                          onClick={() => {
                                            // setJd(list.action_object_object_id);
                                            // setCandi(list.target_object_id);
                                            // setProfile(true);
                                            // if (
                                            //   list.description.toLowerCase() ===
                                            //   'messages'
                                            // ) {
                                            //   setMessageTab(1);
                                            // } else {
                                            //   setMessageTab(0);
                                            // }
                                            godatabase()
                                            handleReadNotification(list?.id);
                                          }}
                                        >
                                          <Flex
                                            row
                                            center
                                            between
                                            className={styles.hoverStyle}
                                          >
                                            <Flex
                                              className={styles.wordContainer}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: '13px',
                                                }}
                                              >
                                                {list.verb}
                                              </Text>
                                            </Flex>
                                            <Flex row>
                                              <Flex end>
                                                <Text
                                                  style={{
                                                    marginLeft: 3,
                                                    fontSize: '13px',
                                                    display: 'flex',
                                                    // width: '28%',
                                                  }}
                                                  color="gray"
                                                >
                                                  {moment(
                                                    list.timestamp,
                                                  ).fromNow()}
                                                </Text>
                                              </Flex>
                                              <Flex width={15}>
                                                {list?.unread && (
                                                  <div
                                                    className={styles.readStyle}
                                                  />
                                                )}
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </>
                                  ) : list.description.toLowerCase() ===
                                      'zitamatch' ? (
                                          <>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}
                                            <LinkWrapper
                                              to={`/zita_match_candidate/${list?.action_object_object_id}`}
                                            >
                                              <Flex
                                                onClick={() => {
                                                  // setJd(list.action_object_object_id);
                                                  // setCandi(list.target_object_id);
                                                  // setProfile(true);
                                                  // if (
                                                  //   list.description.toLowerCase() ===
                                                  //   'messages'
                                                  // ) {
                                                  //   setMessageTab(1);
                                                  // } else {
                                                  //   setMessageTab(0);
                                                  // }

                                                  handleReadNotification(list.id);
                                                }}
                                              >
                                                <Flex
                                                  row
                                                  center
                                                  between
                                                  className={styles.hoverStyle}
                                                >
                                                  <Flex
                                                    className={styles.wordContainer}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: '13px',
                                                      }}
                                                    >
                                                      {list?.verb}
                                                    </Text>
                                                  </Flex>
                                                  <Flex row>
                                                    <Flex end>
                                                      <Text
                                                        style={{
                                                          marginLeft: 3,
                                                          fontSize: '13px',
                                                          display: 'flex',
                                                          // width: '28%',
                                                        }}
                                                        color="gray"
                                                      >
                                                        {moment(
                                                          list.timestamp,
                                                        ).fromNow()}
                                                      </Text>
                                                    </Flex>
                                                    <Flex width={15}>
                                                      {list?.unread && (
                                                        <div
                                                          className={styles.readStyle}
                                                        />
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </LinkWrapper>
                                          </>
                                  ) : <>
                                    {index === 0 ? (
                                      ''
                                    ) : (
                                      <hr className={styles.hr_line} />
                                    )}

                                      <Flex
                                        onClick={() => {
                                          setJd(list?.action_object_object_id);
                                          setCandi(list?.target_object_id);
                                          setProfile(true);
                                          if (
                                            list?.description?.toLowerCase() ===
                                            'messages'
                                          ) {
                                            setMessageTab(1);
                                          } else {
                                            setMessageTab(0);
                                          }
                                          handleReadNotification(list.id);
                                        }}
                                      >
                                        <Flex
                                          row
                                          center
                                          between
                                          className={styles.hoverStyle}
                                        >
                                          <Flex
                                            className={styles.wordContainer}
                                          >
                                            <Text
                                              style={{
                                                fontSize: '13px',
                                              }}
                                            >
                                              {list.verb}
                                            </Text>
                                          </Flex>
                                          <Flex row>
                                            <Flex end>
                                              <Text
                                                style={{
                                                  marginLeft: 3,
                                                  fontSize: '13px',
                                                  display: 'flex',
                                                  // width: '28%',
                                                }}
                                                color="gray"
                                              >
                                                {moment(
                                                  list.timestamp,
                                                ).fromNow()}
                                              </Text>
                                            </Flex>
                                            <Flex width={15}>
                                              {list.unread && (
                                                <div
                                                  className={styles.readStyle}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </>))
                                  )} */}

                                  {list?.description?.toLowerCase() !== "null" && list?.description?.toLowerCase() !== "" && list?.description !== null && list?.description !== undefined && (
                                    <>
                                      {list?.description?.toLowerCase() === 'bulkimport' ? (
                                        <>
                                          {' '}
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <LinkWrapper
                                            to={getPath}
                                            onClick={() => {
                                              handleReadNotification(list?.id);
                                              handlecleartab();
                                              sessionStorage.setItem('CandidateDropdown', '1');
                                            }}
                                          >
                                            <Flex
                                              row
                                              center
                                              between
                                              className={styles.hoverStyle}
                                            >
                                              <Flex className={styles.wordContainer}>
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list?.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(list?.timestamp).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div
                                                      className={styles.readStyle}
                                                    />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </LinkWrapper>{' '}
                                        </>
                                      ) : list?.description?.toLowerCase() === 'candidatenotes' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <Flex
                                            onClick={() => {
                                              setCandi(list?.target_object_id);
                                              setProfile(true);
                                              setMessageTab(5);
                                              handleReadNotification(list.id);
                                            }}
                                          >
                                            <Flex
                                              row
                                              center
                                              between
                                              className={styles.hoverStyle}
                                            >
                                              <Flex className={styles.wordContainer}>
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list?.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(list?.timestamp).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div
                                                      className={styles.readStyle}
                                                    />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </>
                                      ) : list?.description?.toLowerCase() === 'database' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <LinkWrapper to={`/all_candidates/${list?.action_object_object_id}`}>
                                          <Flex
                                            onClick={() => {
                                              // godatabase()
                                              localStorage.setItem('notification', 'true');
                                              handleReadNotification(list?.id);
                                            }}
                                          >
                                            <Flex
                                              row
                                              center
                                              between
                                              className={styles.hoverStyle}
                                            >
                                              <Flex
                                                className={styles.wordContainer}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list?.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(list?.timestamp).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div
                                                      className={styles.readStyle}
                                                    />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                          </LinkWrapper>
                                        </>
                                      ) : list?.description?.toLowerCase() === 'event' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <Flex
                                            onClick={() => {
                                              goMeetingScheduler(list.target_object_id)
                                              handleReadNotification(list?.id);
                                            }}
                                          >
                                            <Flex row center between className={styles.hoverStyle}>
                                              <Flex className={styles.wordContainer}>
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(list.timestamp).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div className={styles.readStyle} />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </>
                                      ) : list?.description?.toLowerCase() === 'zitamatch' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <LinkWrapper to={`/zita_match_candidate/${list?.action_object_object_id}`}>
                                            <Flex
                                              onClick={() => {
                                                handleReadNotification(list.id);
                                              }}
                                            >
                                              <Flex row center between className={styles.hoverStyle}>
                                                <Flex className={styles.wordContainer}>
                                                  <Text
                                                    style={{
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    {list?.verb}
                                                  </Text>
                                                </Flex>
                                                <Flex row >
                                                  <Flex end>
                                                    <Text
                                                      style={{
                                                        cursor:'pointer',
                                                        marginLeft: 3,
                                                        fontSize: '13px',
                                                        display: 'flex',
                                                      }}
                                                      color="gray"
                                                    >
                                                      {moment(list.timestamp).fromNow()}
                                                    </Text>
                                                  </Flex>
                                                  <Flex width={15}>
                                                    {list?.unread && (
                                                      <div className={styles.readStyle} />
                                                    )}
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </LinkWrapper>
                                        </>
                                      ) : (
                                        
                                        <>
                                        
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <Flex
                                            onClick={() => {
                                              if (handleconditionchecking(active_resume,Number(list?.target_object_id)) === false) {
                                                setOpen(false)
                                                open_subscription(true, 0)
                                              } else {
                                                setJd(list?.action_object_object_id);
                                                setCandi(list?.target_object_id);
                                                setProfile(true);
                                                if (list?.description?.toLowerCase() === 'messages') {
                                                  setMessageTab(1);
                                                } else {
                                                  setMessageTab(0);
                                                }
                                                handleReadNotification(list.id);
                                              }
                                            }}
                                          >
                                            <Flex row center between className={styles.hoverStyle}>
                                              <Flex className={styles.wordContainer}>
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(list.timestamp).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list.unread && (
                                                    <div className={styles.readStyle} />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </>
                                      )}
                                    </>
                                  )}
                                </Flex>
                              </>
                            );
                          },
                        )}
                    </Flex>
                  )}
                  {isData &&
                    isData?.yesterday &&
                    isData?.yesterday?.length !== 0 && (
                      <Flex columnFlex className={cx('borderBottom')}>
                        {isData &&
                          isData?.yesterday?.map(
                            (list: OthersEntity, index: number) => {
                              let getPath = '';
                              if (
                                list?.description?.toLowerCase() === 'bulkimport'
                              ) {
                                getPath = `/bulk_import?tab=${list.action_object_object_id === null ? 0 : 1}${list.action_object_object_id === null ? '' : `&jd_ids=${list.action_object_object_id}`}`;
                              }
                              return (
                                <Flex key={index + list.id}>
                                  {index === 0 && (
                                    <Text
                                      style={{
                                        padding: '16px 16px 0px 16px',
                                        fontWeight: 600,
                                        fontSize: '13px',
                                      }}
                                      bold
                                    >
                                      YESTERDAY
                                    </Text>
                                  )}
                                  {/* {list?.description?.toLowerCase() ===
                                  'bulkimport' ? (
                                    <>
                                      {' '}
                                      {index === 0 ? (
                                        ''
                                      ) : (
                                        <hr className={styles.hr_line} />
                                      )}
                                      <LinkWrapper
                                        to={getPath}
                                        onClick={() => {
                                          handleReadNotification(list?.id);
                                          handlecleartab();
                                          sessionStorage.setItem('CandidateDropdown', '1');
                                        }}
                                      >
                                        <Flex
                                          row
                                          center
                                          between
                                          className={styles.hoverStyle}
                                        >
                                          <Flex
                                            className={styles.wordContainer}
                                          >
                                            <Text
                                              style={{
                                                fontSize: '13px',
                                              }}
                                            >
                                              {list.verb}
                                            </Text>
                                          </Flex>
                                          <Flex row>
                                            <Flex end>
                                              <Text
                                                style={{
                                                  marginLeft: 3,
                                                  fontSize: '13px',
                                                  display: 'flex',
                                                  // width: '28%',
                                                }}
                                                color="gray"
                                              >
                                                {moment(
                                                  list?.timestamp,
                                                ).fromNow()}
                                              </Text>
                                            </Flex>
                                            <Flex width={15}>
                                              {list.unread && (
                                                <div
                                                  className={styles.readStyle}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </LinkWrapper>{' '}
                                    </>
                                  ) : list?.description?.toLowerCase() ===
                                    'candidatenotes' ? (
                                    <>
                                      {index === 0 ? (
                                        ''
                                      ) : (
                                        <hr className={styles.hr_line} />
                                      )}
                                      <LinkWrapper
                                        onClick={() => {
                                          setCandi(list?.target_object_id);
                                          setProfile(true);
                                          setMessageTab(1);
                                          handleReadNotification(list.id);
                                        }}
                                      >
                                        <Flex
                                          row
                                          center
                                          between
                                          className={styles.hoverStyle}
                                        >
                                          <Flex
                                            className={styles.wordContainer}
                                          >
                                            <Text
                                              style={{
                                                fontSize: '13px',
                                              }}
                                            >
                                              {list?.verb}
                                            </Text>
                                          </Flex>
                                          <Flex row>
                                            <Flex end>
                                              <Text
                                                style={{
                                                  marginLeft: 3,
                                                  fontSize: '13px',
                                                  display: 'flex',
                                                  // width: '28%',
                                                }}
                                                color="gray"
                                              >
                                                {moment(
                                                  list?.timestamp,
                                                ).fromNow()}
                                              </Text>
                                            </Flex>
                                            <Flex width={15}>
                                              {list?.unread && (
                                                <div
                                                  className={styles.readStyle}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </LinkWrapper>{' '}
                                    </>
                                  ) : (
                                    list.description.toLowerCase() ===
                                      'database' ? (<>
                                        {index === 0 ? (
                                          ''
                                        ) : (
                                          <hr className={styles.hr_line} />
                                        )}

                                        <Flex
                                          onClick={() => {
                                            godatabase()
                                            handleReadNotification(list.id);
                                          }}
                                        >
                                          <Flex
                                            row
                                            center
                                            between
                                            className={styles.hoverStyle}
                                          >
                                            <Flex
                                              className={styles.wordContainer}
                                            >
                                              <Text
                                                style={{
                                                  fontSize: '13px',
                                                }}
                                              >
                                                {list.verb}
                                              </Text>
                                            </Flex>
                                            <Flex row>
                                              <Flex end>
                                                <Text
                                                  style={{
                                                    marginLeft: 3,
                                                    fontSize: '13px',
                                                    display: 'flex',
                                                    // width: '28%',
                                                  }}
                                                  color="gray"
                                                >
                                                  {moment(
                                                    list.timestamp,
                                                  ).fromNow()}
                                                </Text>
                                              </Flex>
                                              <Flex width={15}>
                                                {list.unread && (
                                                  <div
                                                    className={styles.readStyle}
                                                  />
                                                )}
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </>) :
                                      (
                                        list.description.toLowerCase() ===
                                          'zitamatch' ? (<>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}
                                            <LinkWrapper
                                              to={`/zita_match_candidate/${list.action_object_object_id}`}
                                            >
                                              <Flex
                                                onClick={() => {
                                                  handleReadNotification(list.id);
                                                }}
                                              >
                                                <Flex
                                                  row
                                                  center
                                                  between
                                                  className={styles.hoverStyle}
                                                >
                                                  <Flex
                                                    className={styles.wordContainer}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: '13px',
                                                      }}
                                                    >
                                                      {list.verb}
                                                    </Text>
                                                  </Flex>
                                                  <Flex row>
                                                    <Flex end>
                                                      <Text
                                                        style={{
                                                          marginLeft: 3,
                                                          fontSize: '13px',
                                                          display: 'flex',
                                                          // width: '28%',
                                                        }}
                                                        color="gray"
                                                      >
                                                        {moment(
                                                          list.timestamp,
                                                        ).fromNow()}
                                                      </Text>
                                                    </Flex>
                                                    <Flex width={15}>
                                                      {list.unread && (
                                                        <div
                                                          className={styles.readStyle}
                                                        />
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </LinkWrapper>
                                          </>) : (
                                          <>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}

                                            <Flex
                                              onClick={() => {
                                                setJd(list.action_object_object_id);
                                                setCandi(list.target_object_id);
                                                setProfile(true);
                                                if (
                                                  list.description.toLowerCase() ===
                                                  'messages'
                                                ) {
                                                  setMessageTab(1);
                                                } else {
                                                  setMessageTab(0);
                                                }
                                                handleReadNotification(list.id);
                                              }}
                                            >
                                              <Flex
                                                row
                                                center
                                                between
                                                className={styles.hoverStyle}
                                              >
                                                <Flex
                                                  className={styles.wordContainer}
                                                >
                                                  <Text
                                                    style={{
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    {list.verb}
                                                  </Text>
                                                </Flex>
                                                <Flex row>
                                                  <Flex end>
                                                    <Text
                                                      style={{
                                                        marginLeft: 3,
                                                        fontSize: '13px',
                                                        display: 'flex',
                                                        // width: '28%',
                                                      }}
                                                      color="gray"
                                                    >
                                                      {moment(
                                                        list.timestamp,
                                                      ).fromNow()}
                                                    </Text>
                                                  </Flex>
                                                  <Flex width={15}>
                                                    {list.unread && (
                                                      <div
                                                        className={styles.readStyle}
                                                      />
                                                    )}
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </>))
                                  )} */}

                                  {list?.description?.toLowerCase() !== "null" && list?.description?.toLowerCase() !== "" && list?.description !== null && list?.description !== undefined && (
                                    <>
                                      {list?.description?.toLowerCase() ===
                                        'bulkimport' ? (
                                        <>
                                          {' '}
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <LinkWrapper
                                            to={getPath}
                                            onClick={() => {
                                              handleReadNotification(list?.id);
                                              handlecleartab();
                                              sessionStorage.setItem('CandidateDropdown', '1');
                                            }}
                                          >
                                            <Flex
                                              row
                                              center
                                              between
                                              className={styles.hoverStyle}
                                            >
                                              <Flex
                                                className={styles.wordContainer}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                      // width: '28%',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(
                                                      list?.timestamp,
                                                    ).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list.unread && (
                                                    <div
                                                      className={styles.readStyle}
                                                    />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </LinkWrapper>{' '}
                                        </>
                                      ) : list?.description?.toLowerCase() ===
                                        'candidatenotes' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <LinkWrapper
                                            onClick={() => {
                                              setCandi(list?.target_object_id);
                                              setProfile(true);
                                              setMessageTab(5);
                                              handleReadNotification(list.id);
                                            }}
                                          >
                                            <Flex
                                              row
                                              center
                                              between
                                              className={styles.hoverStyle}
                                            >
                                              <Flex
                                                className={styles.wordContainer}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list?.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                      // width: '28%',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(
                                                      list?.timestamp,
                                                    ).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div
                                                      className={styles.readStyle}
                                                    />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </LinkWrapper>{' '}
                                        </>
                                      ) : list?.description?.toLowerCase() === 'event' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <Flex
                                            onClick={() => {
                                              goMeetingScheduler(list.target_object_id)
                                              handleReadNotification(list?.id);
                                            }}
                                          >
                                            <Flex row center between className={styles.hoverStyle}>
                                              <Flex className={styles.wordContainer}>
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(list.timestamp).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div className={styles.readStyle} />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </>
                                      ) : (
                                        list.description.toLowerCase() ===
                                          'database' ? (<>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}
                                           <LinkWrapper to={`/all_candidates/${list?.action_object_object_id}`}>
                                            <Flex
                                              onClick={() => {
                                                // godatabase()
                                                localStorage.setItem('notification', 'true');
                                                handleReadNotification(list.id);
                                              }}
                                            >
                                              <Flex
                                                row
                                                center
                                                between
                                                className={styles.hoverStyle}
                                              >
                                                <Flex
                                                  className={styles.wordContainer}
                                                >
                                                  <Text
                                                    style={{
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    {list.verb}
                                                  </Text>
                                                </Flex>
                                                <Flex row style={{cursor:'pointer'}}>
                                                  <Flex end style={{cursor:'pointer'}}>
                                                    <Text
                                                      style={{
                                                        cursor:'pointer',
                                                        marginLeft: 3,
                                                        fontSize: '13px',
                                                        display: 'flex',
                                                        // width: '28%',
                                                      }}
                                                      color="gray"
                                                    >
                                                      {moment(
                                                        list.timestamp,
                                                      ).fromNow()}
                                                    </Text>
                                                  </Flex>
                                                  <Flex width={15} style={{cursor:'pointer'}}>
                                                    {list.unread && (
                                                      <div
                                                        className={styles.readStyle}
                                                      />
                                                    )}
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                            </LinkWrapper>
                                          </>) :
                                          (
                                            list.description.toLowerCase() ===
                                              'zitamatch' ? (<>
                                                {index === 0 ? (
                                                  ''
                                                ) : (
                                                  <hr className={styles.hr_line} />
                                                )}
                                                <LinkWrapper
                                                  to={`/zita_match_candidate/${list.action_object_object_id}`}
                                                >
                                                  <Flex
                                                    onClick={() => {
                                                      handleReadNotification(list.id);
                                                    }}
                                                  >
                                                    <Flex
                                                      row
                                                      center
                                                      between
                                                      className={styles.hoverStyle}
                                                    >
                                                      <Flex
                                                        className={styles.wordContainer}
                                                      >
                                                        <Text
                                                          style={{
                                                            fontSize: '13px',
                                                          }}
                                                        >
                                                          {list.verb}
                                                        </Text>
                                                      </Flex>
                                                      <Flex row>
                                                        <Flex end>
                                                          <Text
                                                            style={{
                                                              cursor:'pointer',
                                                              marginLeft: 3,
                                                              fontSize: '13px',
                                                              display: 'flex',
                                                              // width: '28%',
                                                            }}
                                                            color="gray"
                                                          >
                                                            {moment(
                                                              list.timestamp,
                                                            ).fromNow()}
                                                          </Text>
                                                        </Flex>
                                                        <Flex width={15}>
                                                          {list.unread && (
                                                            <div
                                                              className={styles.readStyle}
                                                            />
                                                          )}
                                                        </Flex>
                                                      </Flex>
                                                    </Flex>
                                                  </Flex>
                                                </LinkWrapper>
                                              </>
                                            ) : list?.description?.toLowerCase() === 'integration' ? (
                                              <>
                                                {index === 0 ? (
                                                  ''
                                                ) : (
                                                  <hr className={styles.hr_line} />
                                                )}
                                                <Flex
                                                  onClick={() => {
                                                    handleIntegrations()
                                                    handleReadNotification(list?.id);
                                                  }}
                                                >
                                                  <Flex
                                                    row
                                                    center
                                                    between
                                                    className={styles.hoverStyle}
                                                  >
                                                    <Flex
                                                      className={styles.wordContainer}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        {list?.verb}
                                                      </Text>
                                                    </Flex>
                                                    <Flex row style={{cursor:'pointer'}}>
                                                      <Flex end style={{cursor:'pointer'}}>
                                                        <Text
                                                          style={{
                                                            cursor:'pointer',
                                                            marginLeft: 3,
                                                            fontSize: '13px',
                                                            display: 'flex',
                                                          }}
                                                          color="gray"
                                                        >
                                                          {moment(list?.timestamp).fromNow()}
                                                        </Text>
                                                      </Flex>
                                                      <Flex width={15} style={{cursor:'pointer'}}>
                                                        {list?.unread && (
                                                          <div
                                                            className={styles.readStyle}
                                                          />
                                                        )}
                                                      </Flex>
                                                    </Flex>
                                                  </Flex>
                                                </Flex>
                                              </>
                                            ) : (
                                              <>
                                                {index === 0 ? (
                                                  ''
                                                ) : (
                                                  <hr className={styles.hr_line} />
                                                )}

                                                <Flex
                                                  onClick={() => {
                                                    setJd(list.action_object_object_id);
                                                    setCandi(list.target_object_id);
                                                    setProfile(true);
                                                    if (
                                                      list.description.toLowerCase() ===
                                                      'messages'
                                                    ) {
                                                      setMessageTab(1);
                                                    } else {
                                                      setMessageTab(0);
                                                    }
                                                    handleReadNotification(list.id);
                                                  }}
                                                >
                                                  <Flex
                                                    row
                                                    center
                                                    between
                                                    className={styles.hoverStyle}
                                                  >
                                                    <Flex
                                                      className={styles.wordContainer}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontSize: '13px',
                                                        }}
                                                      >
                                                        {list.verb}
                                                      </Text>
                                                    </Flex>
                                                    <Flex row style={{cursor:'pointer'}}>
                                                      <Flex end style={{cursor:'pointer'}}>
                                                        <Text
                                                          style={{
                                                            cursor:'pointer',
                                                            marginLeft: 3,
                                                            fontSize: '13px',
                                                            display: 'flex',
                                                            // width: '28%',
                                                          }}
                                                          color="gray"
                                                        >
                                                          {moment(
                                                            list.timestamp,
                                                          ).fromNow()}
                                                        </Text>
                                                      </Flex>
                                                      <Flex width={15} style={{cursor:'pointer'}}>
                                                        {list.unread && (
                                                          <div
                                                            className={styles.readStyle}
                                                          />
                                                        )}
                                                      </Flex>
                                                    </Flex>
                                                  </Flex>
                                                </Flex>
                                              </>))
                                      )}
                                    </>
                                  )}
                                </Flex>
                              );
                            },
                          )}
                      </Flex>
                    )}
                  {isData && isData.others && isData.others.length !== 0 && (
                    <Flex columnFlex>
                      {isData &&
                        isData.others.map(
                          (list: OthersEntity, index: number) => {
                            let getPath;
                            if (
                              list?.description?.toLowerCase() === 'bulkimport'
                            ) {
                              getPath = `/bulk_import?tab=${list.action_object_object_id === null ? 0 : 1}${list.action_object_object_id === null ? '' : `&jd_ids=${list.action_object_object_id}`}`;
                            }
                            return (
                              <Flex key={index + list.id}>
                                {index === 0 && (
                                  <Text
                                    style={{
                                      padding: '16px 16px 0px 16px',
                                      fontWeight: 600,
                                      fontSize: '13px',
                                    }}
                                    bold
                                  >
                                    OLDER
                                  </Text>
                                )}
                                {/* {list?.description?.toLowerCase() ===
                                'bulkimport' ? (
                                  <>
                                    {' '}
                                    {index === 0 ? (
                                      ''
                                    ) : (
                                      <hr className={styles.hr_line} />
                                    )}
                                    <LinkWrapper
                                      to={getPath}
                                      onClick={() => {
                                        handleReadNotification(list?.id);
                                        handlecleartab();
                                        sessionStorage.setItem('CandidateDropdown', '1');
                                      }}
                                    >
                                      <Flex
                                        row
                                        center
                                        between
                                        className={styles.hoverStyle}
                                      >
                                        <Flex className={styles.wordContainer}>
                                          <Text
                                            style={{
                                              fontSize: '13px',
                                            }}
                                          >
                                            {list?.verb}
                                          </Text>
                                        </Flex>
                                        <Flex row>
                                          <Flex end>
                                            <Text
                                              style={{
                                                marginLeft: 3,
                                                fontSize: '13px',
                                                display: 'flex',
                                                // width: '28%',
                                              }}
                                              color="gray"
                                            >
                                              {moment(list?.timestamp).fromNow()}
                                            </Text>
                                          </Flex>
                                          <Flex width={15}>
                                            {list?.unread && (
                                              <div
                                                className={styles.readStyle}
                                              />
                                            )}
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </LinkWrapper>{' '}
                                  </>
                                ) : list?.description?.toLowerCase() ===
                                  'candidatenotes' ? (
                                  <>
                                    {index === 0 ? (
                                      ''
                                    ) : (
                                      <hr className={styles.hr_line} />
                                    )}
                                    <Flex
                                      onClick={() => {
                                        setCandi(list?.target_object_id);
                                        setProfile(true);
                                        setMessageTab(1);
                                        handleReadNotification(list.id);
                                      }}
                                    >
                                      <Flex
                                        row
                                        center
                                        between
                                        className={styles.hoverStyle}
                                      >
                                        <Flex className={styles.wordContainer}>
                                          <Text
                                            style={{
                                              fontSize: '13px',
                                            }}
                                          >
                                            {list?.verb}
                                          </Text>
                                        </Flex>
                                        <Flex row>
                                          <Flex end>
                                            <Text
                                              style={{
                                                marginLeft: 3,
                                                fontSize: '13px',
                                                display: 'flex',
                                                // width: '28%',
                                              }}
                                              color="gray"
                                            >
                                              {moment(list?.timestamp).fromNow()}
                                            </Text>
                                          </Flex>
                                          <Flex width={15}>
                                            {list?.unread && (
                                              <div
                                                className={styles.readStyle}
                                              />
                                            )}
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  </>
                                ) : list?.description?.toLowerCase() ===             //Event Scheduler Code
                                'event' ? (
                                <>
                                  {index === 0 ? (
                                    ''
                                  ) : (
                                    <hr className={styles.hr_line} />
                                  )}
                                  <Flex
                                    onClick={() => {
                                      goMeetingScheduler(list.target_object_id)
                                      handleReadNotification(list?.id);
                                    }}
                                  >
                                    <Flex row className={styles.hoverStyle}>
                                      <Flex
                                        row
                                        between
                                        className={styles.listStyle}
                                      >
                                        <Flex
                                          className={styles.wordContainer}
                                        >
                                          <Text
                                            style={{
                                              fontSize: '13px',
                                            }}
                                          >
                                            {list.verb}
                                          </Text>
                                        </Flex>
                                        <Flex row>
                                          <Flex end>
                                            <Text
                                              style={{
                                                marginLeft: 3,
                                                fontSize: '13px',
                                                display: 'flex',
                                                // width: '28%',
                                              }}
                                              color="gray"
                                            >
                                              {moment(
                                                list?.timestamp,
                                              ).fromNow()}
                                            </Text>
                                          </Flex>
                                          <Flex width={15}>
                                            {list.unread && (
                                              <div
                                                className={styles.readStyle}
                                              />
                                            )}
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                </>
                                ) : (
                                  // <>
                                  //   {' '}
                                  //   {index === 0 ? (
                                  //     ''
                                  //   ) : (
                                  //     <hr className={styles.hr_line} />
                                  //   )}
                                  //   <Flex
                                  //     onClick={() => {
                                  //       setJd(list.action_object_object_id);
                                  //       setCandi(list.target_object_id);
                                  //       setProfile(true);
                                  //       if (
                                  //         list.description.toLowerCase() ===
                                  //         'messages'
                                  //       ) {
                                  //         setMessageTab(1);
                                  //       } else {
                                  //         setMessageTab(0);
                                  //       }
                                  //       handleReadNotification(list.id);
                                  //     }}
                                  //   >
                                  //     <Flex row className={styles.hoverStyle}>
                                  //       <Flex
                                  //         row
                                  //         between
                                  //         className={styles.listStyle}
                                  //       >
                                  //         <Flex
                                  //           className={styles.wordContainer}
                                  //         >
                                  //           <Text
                                  //             style={{
                                  //               fontSize: '13px',
                                  //             }}
                                  //           >
                                  //             {list.verb}
                                  //           </Text>
                                  //         </Flex>
                                  //         <Flex row end>
                                  //           <Flex>
                                  //             <Text
                                  //               style={{
                                  //                 marginLeft: 3,
                                  //                 fontSize: '13px',
                                  //                 display: 'flex',
                                  //                 // width: '28%',
                                  //               }}
                                  //               color="gray"
                                  //             >
                                  //               {moment(
                                  //                 list.timestamp,
                                  //               ).fromNow()}
                                  //             </Text>
                                  //           </Flex>
                                  //           <Flex width={15}>
                                  //             {list.unread && (
                                  //               <div
                                  //                 className={styles.readStyle}
                                  //               />
                                  //             )}
                                  //           </Flex>
                                  //         </Flex>
                                  //       </Flex>
                                  //     </Flex>
                                  //   </Flex>
                                  // </>
                                  list?.description?.toLowerCase() ===
                                    'database' ? (<>
                                      {index === 0 ? (
                                        ''
                                      ) : (
                                        <hr className={styles.hr_line} />
                                      )}

                                      <Flex
                                        onClick={() => {
                                          // setJd(list.action_object_object_id);
                                          // setCandi(list.target_object_id);
                                          // setProfile(true);
                                          // if (
                                          //   list.description.toLowerCase() ===
                                          //   'messages'
                                          // ) {
                                          //   setMessageTab(1);
                                          // } else {
                                          //   setMessageTab(0);
                                          // }
                                          godatabase()
                                          handleReadNotification(list?.id);
                                        }}
                                      >
                                        <Flex
                                          row
                                          center
                                          between
                                          className={styles.hoverStyle}
                                        >
                                          <Flex
                                            className={styles.wordContainer}
                                          >
                                            <Text
                                              style={{
                                                fontSize: '13px',
                                              }}
                                            >
                                              {list?.verb}
                                            </Text>
                                          </Flex>
                                          <Flex row>
                                            <Flex end>
                                              <Text
                                                style={{
                                                  marginLeft: 3,
                                                  fontSize: '13px',
                                                  display: 'flex',
                                                  // width: '28%',
                                                }}
                                                color="gray"
                                              >
                                                {moment(
                                                  list?.timestamp,
                                                ).fromNow()}
                                              </Text>
                                            </Flex>
                                            <Flex width={15}>
                                              {list?.unread && (
                                                <div
                                                  className={styles.readStyle}
                                                />
                                              )}
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </>) :
                                    (
                                      list?.description?.toLowerCase() ===
                                        'zitamatch' ? (<>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                          <LinkWrapper
                                            to={`/zita_match_candidate/${list?.action_object_object_id}`}
                                          >
                                            <Flex
                                              onClick={() => {
                                                // setJd(list.action_object_object_id);
                                                // setCandi(list.target_object_id);
                                                // setProfile(true);
                                                // if (
                                                //   list.description.toLowerCase() ===
                                                //   'messages'
                                                // ) {
                                                //   setMessageTab(1);
                                                // } else {
                                                //   setMessageTab(0);
                                                // }

                                                handleReadNotification(list?.id);
                                              }}
                                            >
                                              <Flex
                                                row
                                                center
                                                between
                                                className={styles.hoverStyle}
                                              >
                                                <Flex
                                                  className={styles.wordContainer}
                                                >
                                                  <Text
                                                    style={{
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    {list?.verb}
                                                  </Text>
                                                </Flex>
                                                <Flex row>
                                                  <Flex end>
                                                    <Text
                                                      style={{
                                                        marginLeft: 3,
                                                        fontSize: '13px',
                                                        display: 'flex',
                                                        // width: '28%',
                                                      }}
                                                      color="gray"
                                                    >
                                                      {moment(
                                                        list?.timestamp,
                                                      ).fromNow()}
                                                    </Text>
                                                  </Flex>
                                                  <Flex width={15}>
                                                    {list?.unread && (
                                                      <div
                                                        className={styles.readStyle}
                                                      />
                                                    )}
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </LinkWrapper>
                                        </>) : (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}

                                    <Flex
                                      onClick={() => {
                                        setJd(list?.action_object_object_id);
                                        setCandi(list?.target_object_id);
                                        setProfile(true);
                                        if (
                                          list?.description?.toLowerCase() ===
                                          'messages'
                                        ) {
                                          setMessageTab(1);
                                        } else {
                                          setMessageTab(0);
                                        }
                                        handleReadNotification(list?.id);
                                      }}
                                    >
                                      <Flex
                                        row
                                        center
                                        between
                                        className={styles.hoverStyle}
                                      >
                                        <Flex
                                          className={styles.wordContainer}
                                        >
                                          <Text
                                            style={{
                                              fontSize: '13px',
                                            }}
                                          >
                                            {list?.verb}
                                          </Text>
                                        </Flex>
                                        <Flex row>
                                          <Flex end>
                                            <Text
                                              style={{
                                                marginLeft: 3,
                                                fontSize: '13px',
                                                display: 'flex',
                                                // width: '28%',
                                              }}
                                              color="gray"
                                            >
                                              {moment(
                                                list?.timestamp,
                                              ).fromNow()}
                                            </Text>
                                          </Flex>
                                          <Flex width={15}>
                                            {list?.unread && (
                                              <div
                                                className={styles.readStyle}
                                              />
                                            )}
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  </>))
                                )} */}

                                {list?.description?.toLowerCase() !== "null" && list?.description?.toLowerCase() !== "" && list?.description !== null && list?.description !== undefined && (
                                  <>
                                    {list?.description?.toLowerCase() ===
                                      'bulkimport' ? (
                                      <>
                                        {' '}
                                        {index === 0 ? (
                                          ''
                                        ) : (
                                          <hr className={styles.hr_line} />
                                        )}
                                        <LinkWrapper
                                          to={getPath}
                                          onClick={() => {
                                            handleReadNotification(list?.id);
                                            handlecleartab();
                                            sessionStorage.setItem('CandidateDropdown', '1');
                                          }}
                                        >
                                          <Flex
                                            row
                                            center
                                            between
                                            className={styles.hoverStyle}
                                          >
                                            <Flex className={styles.wordContainer}>
                                              <Text
                                                style={{
                                                  fontSize: '13px',
                                                }}
                                              >
                                                {list?.verb}
                                              </Text>
                                            </Flex>
                                            <Flex row style={{cursor:'pointer'}}>
                                              <Flex end style={{cursor:'pointer'}}>
                                                <Text
                                                  style={{
                                                    cursor:'pointer',
                                                    marginLeft: 3,
                                                    fontSize: '13px',
                                                    display: 'flex',
                                                    // width: '28%',
                                                  }}
                                                  color="gray"
                                                >
                                                  {moment(list?.timestamp).fromNow()}
                                                </Text>
                                              </Flex>
                                              <Flex width={15} style={{cursor:'pointer'}}>
                                                {list?.unread && (
                                                  <div
                                                    className={styles.readStyle}
                                                  />
                                                )}
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </LinkWrapper>{' '}
                                      </>
                                    ) : list?.description?.toLowerCase() ===
                                      'candidatenotes' ? (
                                      <>
                                        {index === 0 ? (
                                          ''
                                        ) : (
                                          <hr className={styles.hr_line} />
                                        )}
                                        <Flex
                                          onClick={() => {
                                            setCandi(list?.target_object_id);
                                            setProfile(true);
                                            setMessageTab(5);
                                            handleReadNotification(list.id);
                                          }}
                                        >
                                          <Flex
                                            row
                                            center
                                            between
                                            className={styles.hoverStyle}
                                          >
                                            <Flex className={styles.wordContainer}>
                                              <Text
                                                style={{
                                                  fontSize: '13px',
                                                }}
                                              >
                                                {list?.verb}
                                              </Text>
                                            </Flex>
                                            <Flex row style={{cursor:'pointer'}}>
                                              <Flex end style={{cursor:'pointer'}}>
                                                <Text
                                                  style={{
                                                    cursor:'pointer',
                                                    marginLeft: 3,
                                                    fontSize: '13px',
                                                    display: 'flex',
                                                    // width: '28%',
                                                  }}
                                                  color="gray"
                                                >
                                                  {moment(list?.timestamp).fromNow()}
                                                </Text>
                                              </Flex>
                                              <Flex width={15} style={{cursor:'pointer'}}>
                                                {list?.unread && (
                                                  <div
                                                    className={styles.readStyle}
                                                  />
                                                )}
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </>
                                    ) : list?.description?.toLowerCase() === 'event' ? (
                                      <>
                                        {index === 0 ? (
                                          ''
                                        ) : (
                                          <hr className={styles.hr_line} />
                                        )}
                                        <Flex
                                          onClick={() => {
                                            goMeetingScheduler(list.target_object_id)
                                            handleReadNotification(list?.id);
                                          }}
                                        >
                                          <Flex row center between className={styles.hoverStyle}>
                                            <Flex className={styles.wordContainer}>
                                              <Text
                                                style={{
                                                  fontSize: '13px',
                                                }}
                                              >
                                                {list.verb}
                                              </Text>
                                            </Flex>
                                            <Flex row style={{cursor:'pointer'}}>
                                              <Flex end style={{cursor:'pointer'}}>
                                                <Text
                                                  style={{
                                                    cursor:'pointer',
                                                    marginLeft: 3,
                                                    fontSize: '13px',
                                                    display: 'flex',
                                                  }}
                                                  color="gray"
                                                >
                                                  {moment(list.timestamp).fromNow()}
                                                </Text>
                                              </Flex>
                                              <Flex width={15} style={{cursor:'pointer'}}>
                                                {list?.unread && (
                                                  <div className={styles.readStyle} />
                                                )}
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      </>
                                    ) : (
                                      // <>
                                      //   {' '}
                                      //   {index === 0 ? (
                                      //     ''
                                      //   ) : (
                                      //     <hr className={styles.hr_line} />
                                      //   )}
                                      //   <Flex
                                      //     onClick={() => {
                                      //       setJd(list.action_object_object_id);
                                      //       setCandi(list.target_object_id);
                                      //       setProfile(true);
                                      //       if (
                                      //         list.description.toLowerCase() ===
                                      //         'messages'
                                      //       ) {
                                      //         setMessageTab(1);
                                      //       } else {
                                      //         setMessageTab(0);
                                      //       }
                                      //       handleReadNotification(list.id);
                                      //     }}
                                      //   >
                                      //     <Flex row className={styles.hoverStyle}>
                                      //       <Flex
                                      //         row
                                      //         between
                                      //         className={styles.listStyle}
                                      //       >
                                      //         <Flex
                                      //           className={styles.wordContainer}
                                      //         >
                                      //           <Text
                                      //             style={{
                                      //               fontSize: '13px',
                                      //             }}
                                      //           >
                                      //             {list.verb}
                                      //           </Text>
                                      //         </Flex>
                                      //         <Flex row end>
                                      //           <Flex>
                                      //             <Text
                                      //               style={{
                                      //                 marginLeft: 3,
                                      //                 fontSize: '13px',
                                      //                 display: 'flex',
                                      //                 // width: '28%',
                                      //               }}
                                      //               color="gray"
                                      //             >
                                      //               {moment(
                                      //                 list.timestamp,
                                      //               ).fromNow()}
                                      //             </Text>
                                      //           </Flex>
                                      //           <Flex width={15}>
                                      //             {list.unread && (
                                      //               <div
                                      //                 className={styles.readStyle}
                                      //               />
                                      //             )}
                                      //           </Flex>
                                      //         </Flex>
                                      //       </Flex>
                                      //     </Flex>
                                      //   </Flex>
                                      // </>
                                      list?.description?.toLowerCase() ===
                                        'database' ? (
                                        <>
                                          {index === 0 ? (
                                            ''
                                          ) : (
                                            <hr className={styles.hr_line} />
                                          )}
                                           <LinkWrapper to={`/all_candidates/${list?.action_object_object_id}`}>
                                          <Flex
                                            onClick={() => {
                                              // setJd(list.action_object_object_id);
                                              // setCandi(list.target_object_id);
                                              // setProfile(true);
                                              // if (
                                              //   list.description.toLowerCase() ===
                                              //   'messages'
                                              // ) {
                                              //   setMessageTab(1);
                                              // } else {
                                              //   setMessageTab(0);
                                              // }
                                              // godatabase()
                                              localStorage.setItem('notification', 'true');
                                              handleReadNotification(list?.id);
                                            }}
                                          >
                                            <Flex
                                              row
                                              center
                                              between
                                              className={styles.hoverStyle}
                                            >
                                              <Flex
                                                className={styles.wordContainer}
                                              >
                                                <Text
                                                  style={{
                                                    fontSize: '13px',
                                                  }}
                                                >
                                                  {list?.verb}
                                                </Text>
                                              </Flex>
                                              <Flex row style={{cursor:'pointer'}}>
                                                <Flex end style={{cursor:'pointer'}}>
                                                  <Text
                                                    style={{
                                                      cursor:'pointer',
                                                      marginLeft: 3,
                                                      fontSize: '13px',
                                                      display: 'flex',
                                                      // width: '28%',
                                                    }}
                                                    color="gray"
                                                  >
                                                    {moment(
                                                      list?.timestamp,
                                                    ).fromNow()}
                                                  </Text>
                                                </Flex>
                                                <Flex width={15} style={{cursor:'pointer'}}>
                                                  {list?.unread && (
                                                    <div
                                                      className={styles.readStyle}
                                                    />
                                                  )}
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                          </LinkWrapper>
                                        </>
                                      ) : (
                                        list?.description?.toLowerCase() ===
                                          'zitamatch' ? (<>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}
                                            <LinkWrapper
                                              to={`/zita_match_candidate/${list?.action_object_object_id}`}
                                            >
                                              <Flex
                                                onClick={() => {
                                                  // setJd(list.action_object_object_id);
                                                  // setCandi(list.target_object_id);
                                                  // setProfile(true);
                                                  // if (
                                                  //   list.description.toLowerCase() ===
                                                  //   'messages'
                                                  // ) {
                                                  //   setMessageTab(1);
                                                  // } else {
                                                  //   setMessageTab(0);
                                                  // }

                                                  handleReadNotification(list?.id);
                                                }}
                                              >
                                                <Flex
                                                  row
                                                  center
                                                  between
                                                  className={styles.hoverStyle}
                                                >
                                                  <Flex
                                                    className={styles.wordContainer}
                                                  >
                                                    <Text
                                                      style={{
                                                        fontSize: '13px',
                                                      }}
                                                    >
                                                      {list?.verb}
                                                    </Text>
                                                  </Flex>
                                                  <Flex row>
                                                    <Flex end>
                                                      <Text
                                                        style={{
                                                          cursor:'pointer',
                                                          marginLeft: 3,
                                                          fontSize: '13px',
                                                          display: 'flex',
                                                          // width: '28%',
                                                        }}
                                                        color="gray"
                                                      >
                                                        {moment(
                                                          list?.timestamp,
                                                        ).fromNow()}
                                                      </Text>
                                                    </Flex>
                                                    <Flex width={15}>
                                                      {list?.unread && (
                                                        <div
                                                          className={styles.readStyle}
                                                        />
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </LinkWrapper>
                                          </>
                                        ) : list?.description?.toLowerCase() === 'integration' ? (
                                          <>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}
                                            <Flex
                                              onClick={() => {
                                                handleIntegrations()
                                                handleReadNotification(list?.id);
                                              }}
                                            >
                                              <Flex
                                                row
                                                center
                                                between
                                                className={styles.hoverStyle}
                                              >
                                                <Flex
                                                  className={styles.wordContainer}
                                                >
                                                  <Text
                                                    style={{
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    {list?.verb}
                                                  </Text>
                                                </Flex>
                                                <Flex row style={{cursor:'pointer'}}>
                                                  <Flex end style={{cursor:'pointer'}}>
                                                    <Text
                                                      style={{
                                                        cursor:'pointer',
                                                        marginLeft: 3,
                                                        fontSize: '13px',
                                                        display: 'flex',
                                                      }}
                                                      color="gray"
                                                    >
                                                      {moment(list?.timestamp).fromNow()}
                                                    </Text>
                                                  </Flex>
                                                  <Flex width={15} style={{cursor:'pointer'}}>
                                                    {list?.unread && (
                                                      <div
                                                        className={styles.readStyle}
                                                      />
                                                    )}
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </>
                                        ) : (
                                          <>
                                            {index === 0 ? (
                                              ''
                                            ) : (
                                              <hr className={styles.hr_line} />
                                            )}

                                            <Flex
                                              onClick={() => {
                                                setJd(list?.action_object_object_id);
                                                setCandi(list?.target_object_id);
                                                setProfile(true);
                                                if (
                                                  list?.description?.toLowerCase() ===
                                                  'messages'
                                                ) {
                                                  setMessageTab(1);
                                                } else {
                                                  setMessageTab(0);
                                                }
                                                handleReadNotification(list?.id);
                                              }}
                                            >
                                              <Flex
                                                row
                                                center
                                                between
                                                className={styles.hoverStyle}
                                              >
                                                <Flex
                                                  className={styles.wordContainer}
                                                >
                                                  <Text
                                                    style={{
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    {list?.verb}
                                                  </Text>
                                                </Flex>
                                                <Flex row style={{cursor:'pointer'}}>
                                                  <Flex end style={{cursor:'pointer'}}>
                                                    <Text
                                                      style={{
                                                        cursor:'pointer',
                                                        marginLeft: 3,
                                                        fontSize: '13px',
                                                        display: 'flex',
                                                        // width: '28%',
                                                      }}
                                                      color="gray"
                                                    >
                                                      {moment(
                                                        list?.timestamp,
                                                      ).fromNow()}
                                                    </Text>
                                                  </Flex>
                                                  <Flex width={15} style={{cursor:'pointer'}}>
                                                    {list?.unread && (
                                                      <div
                                                        className={styles.readStyle}
                                                      />
                                                    )}
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                            </Flex>
                                          </>
                                        ))
                                    )}
                                  </>
                                )}
                              </Flex>
                            );
                          },
                        )}
                    </Flex>
                  )}
                </Flex>
                <hr className={styles.hr_lineforbutton} />
                <div>
                  <Button
                    className={styles.model_open}
                    onClick={() => setmodelopen(true)}
                  >
                    Clear All
                  </Button>
                </div>
              </>
            ) : (
              <Flex>
                <Flex row center between className={styles.headerStyle}>
                  <Text color="theme" style={{ fontSize: '14px' }} bold>
                    Notifications
                  </Text>
                  <Flex onClick={handleclose} style={{ cursor: 'pointer' }}>
                    <SvgClose width={10} height={10} fill={'#888888'} />
                  </Flex>
                </Flex>
                <hr className={styles.line} />

                <Flex style={{ textAlign: 'center' }}>
                  <Flex
                    style={{
                      position: 'absolute',
                      top: '47%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  >
                    <SvgBell fill={'#888888'} height={16} width={16} />
                  </Flex>

                  <Text
                    size={13}
                    style={{
                      position: 'absolute',
                      top: '47%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      marginTop: '7px',
                      color: '#888888',
                    }}
                  >
                    <br />
                    You don’t have any notification.
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        )}
      </div>

      <Flex ref={dropDownRef} onClick={myfunction}>
        <Modal open={modelopen} data-bs-backdrop="static">
          <Flex className={styles.model}>
            <Flex className={styles.confirm_title}>
              This action will clear all the notifications.
            </Flex>
            <Flex className={styles.confirm_txt}>Are you sure to proceed?</Flex>
            <Flex row end style={{ marginTop: '10px' }}>
              <Flex>
                <Button
                  style={{ backgroundColor: '#888888', borderColor: '#888888' }}
                  onClick={() => setmodelopen(false)}
                >
                  Cancel
                </Button>
              </Flex>
              <Flex>
                <Button
                  className={styles.Btn_clear}
                  onClick={handleDelete}
                  style={{ marginLeft: '10px' }}
                >
                  Clear
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    </Flex>
  );
};

export default Notification;
