import * as React from 'react';
const SvgCanSourcing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      fill="#581845"
      d="M6.376 6.71q-1.315 0-2.251-.985-.937-.985-.937-2.37 0-1.383.937-2.37Q5.06.002 6.376 0q1.315 0 2.252.986t.936 2.37q0 1.383-.936 2.369-.937.985-2.252.985m0-1.677q.658 0 1.126-.493t.468-1.185-.468-1.184a1.5 1.5 0 0 0-1.126-.493q-.657 0-1.125.493a1.66 1.66 0 0 0-.469 1.184q0 .692.469 1.185.468.492 1.125.493m8.289 10.736-1.992-2.097a5 5 0 0 1-.897.42 3 3 0 0 1-1.016.167q-1.495 0-2.541-1.1t-1.046-2.674T8.22 7.81t2.54-1.1 2.541 1.1q1.047 1.1 1.046 2.674 0 .566-.16 1.07a5.4 5.4 0 0 1-.398.943l1.993 2.097q.219.231.219.587a.82.82 0 0 1-.22.587.74.74 0 0 1-.557.231.74.74 0 0 1-.558-.23m-3.905-3.187q.838 0 1.414-.608.579-.608.578-1.49 0-.88-.578-1.488a1.88 1.88 0 0 0-1.414-.608q-.837 0-1.415.608t-.578 1.489.578 1.489 1.415.608m-4.364.839H1.594a1.5 1.5 0 0 1-1.126-.493A1.66 1.66 0 0 1 0 11.743v-.65q0-.713.339-1.321a2.26 2.26 0 0 1 .936-.923 12 12 0 0 1 2.292-.922q1.275-.378 2.829-.378-.24.377-.408.807a6 6 0 0 0-.27.892 9 9 0 0 0-2.131.43 10 10 0 0 0-1.575.681.74.74 0 0 0-.308.304.9.9 0 0 0-.11.43v.65h4.125q.1.462.269.88.17.42.408.798"
    />
  </svg>
);
export default SvgCanSourcing;