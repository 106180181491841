import { Formik, FormikProps } from 'formik';
import escapeRegExp from 'lodash/escapeRegExp'; // eslint-disable-line
import {
  useMemo,
  useRef,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { set } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import SvgIntomark from '../../icons/Intomark';
import SvgRefresh from '../../icons/SvgRefresh';
import Card from '../../uikit/Card/Card';
import Flex from '../../uikit/Flex/Flex';
import { InputSearch, InputSwitch } from '../../uikit';
import { Button } from '../../uikit';
import { enterKeyPress, isEmpty } from '../../uikit/helper';
import InputCheckBox from '../../uikit/InputCheckbox/InputCheckBox';
import InputRadio from '../../uikit/InputRadio/InputRadio';
import InputText from '../../uikit/InputText/InputText';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import Text from '../../uikit/Text/Text';
import { MAX_DISPLAYED_OPTIONS } from '../constValue';
import { AppDispatch } from '../../store';
import SvgArrowUp from '../../icons/SvgArrowUp';
import SvgFilterRefresh from '../../icons/SvgFilterRefresh';
import { myDataBaseDataMiddleWare } from './store/middleware/mydatabasemiddleware';
import {
  experienceOption,
  jobTypeData,
  myDataSkillList,
  matching_data,
  candidate_source,
} from './mock';
import styles1 from './switch.module.css';
import styles from './mydatabasefilter.module.css';
import { MyDataFormProps } from './MyDataBaseScreen'; // eslint-disable-line

type Props = {
  formik: FormikProps<MyDataFormProps>;
  filterFormik: FormikProps<MyDataFormProps>;
  qualificationOption: {
    value: string;
    label: string;
    checked: boolean;
    onChange: () => void;
    width: number;
  }[];
  hanldeRefresh: () => void;
  setmatch_val: (val?: any) => void;
  setchange?: any;
  qaValue: string;
  skillsOptionsList: any;
  tabKey: string;
  isPage: number;
  addFavFilter: string;
  isSortOptions: {
    value: string;
    label: string;
  };
  setSortOptions: Dispatch<
    SetStateAction<{
      value: string;
      label: string;
    }>
  >;
  setTabKey: any;
  candidate_location?: any;
  isZitamatchfilter?:any;
  setZitamatchfilter:(val?: any) => void;
  setZitamatch:(val?: any) => void;
  setcandidate:(val?: any) => void;
  isZitamatch?:any
  iscandidate?:any
};
const MyDataBaseFilter = ({
  formik,
  filterFormik,
  qualificationOption,
  hanldeRefresh,
  setchange,
  qaValue,
  skillsOptionsList,
  tabKey,
  isPage,
  addFavFilter,
  isSortOptions,
  setSortOptions,
  setmatch_val,
  setTabKey,
  candidate_location,
  isZitamatchfilter,
  setZitamatchfilter,
  setZitamatch,
  isZitamatch,
  setcandidate,
  iscandidate,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isSkills, setSkills] = useState<any>();
  const [isRelocate, setRelocate] = useState(false);
  const [isSearch, setSearch] = useState('');
  const [isOut, setOut] = useState(false);
  const [experience, setexperience] = useState('');
  const [present, setpresent] = useState(true);
  const [jobname, setjobname] = useState('');
  const [job, setjob] = useState(true);
  const [location, setlocation] = useState('');
  const [skill, setskill] = useState([]);
  const [qual, setqual] = useState([]);
  const [qual1, setqual1] = useState([]);
  const [isqualification, setqualification] = useState([]);
  const [ismatching, setmatching] = useState<any>('Both');
  const [showmatch, setshowmatch] = useState<any>('Both');
  const [newjobname, setnewjobname] = useState('');
  const [newexperience, setnewexperience] = useState('');
  const [newqual, setnewqual] = useState([]);
  const [newlocation, setnewlocation] = useState('');
  const [newskill, setnewskill] = useState([]);
  const [newrelocate, setnewrelocate] = useState(false);
  const [hiddenskills1, sethiddenskill1] = useState([]);
  const [candidatetype, setcandidatetype] = useState('');
  const [newcandidatetype, setnewcandidatetype] = useState('');
  const [isCheckbox, setCheckBox] = useState(false);
  const [isAppliedCandidate,setAppliedCandidate]=useState(false)

  const [qualificationValue, setQualificationValue] = useState<
    | {
        value: string;
        label: string;
        checked: boolean;
        onChange: () => void;
      }[]
    | undefined
  >();
  const selectInputRef = useRef<any>();
  const myRef = useRef<any>();
  const dropDownRef = useRef(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [skildata, setskildata] = useState([]);
  const click = () => {
    setShowDropDown(!showDropDown);
  };

  const filteredOptions = useMemo(() => {
    if (!isSkills) {
      return myDataSkillList;
    }
    const matchByStart = [];
    const matchByInclusion = [];
    const regByInclusion = new RegExp(escapeRegExp(isSkills), 'i');
    const regByStart = new RegExp(`^${escapeRegExp(isSkills)}`, 'i');
    for (const option of myDataSkillList) {
      if (regByInclusion.test(option.label)) {
        if (regByStart.test(option.label)) {
          matchByStart.push(option);
        } else {
          matchByInclusion.push(option);
        }
      }
    }
    return [...matchByStart, ...matchByInclusion];
  }, [isSkills]);


  useEffect(()=>{
    setcandidate(newcandidatetype)
  },
  [newcandidatetype])

  const handleselectskill = (option: any) => {
    formik.setFieldValue('skillValue', option);
    setskildata(option);
    setchange(true);
  };

  const hiddenSkills = skill.slice(4, skill.length);

  const slicedOptions = useMemo(
    () => filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS),
    [filteredOptions],
  );
  useEffect(() => {
    if (isRelocate === true) {
      formik.setFieldValue('reLocateValue', '1');
    }
    if (isRelocate !== true) {
      formik.setFieldValue('reLocateValue', '0');
    }
  }, [isRelocate]);

  useEffect(() => {
    if (isSearch !== '') setOut(true);
  }, [isSearch]);
  const handleLocation = () => {
    formik.setFieldValue('locationSearch', isSearch);
  };
  const handleClickOutside = (event: { target: any }) => {
    if (myRef.current && !myRef.current.contains(event.target) && isOut) {
      handleLocation();
      setOut(false);
    }
  };
  useEffect(() => {
    if (typeof Window !== 'undefined') {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (myRef) {
        if (typeof Window !== 'undefined') {
          document.removeEventListener('click', handleClickOutside, true);
        }
      }
    };
  });
  useEffect(() => {
    if (formik.values.jobType === '') {
      setjobname('');
    } else if (formik.values.jobType === '6') {
      setjobname('Full Time');
    } else if (formik.values.jobType === '3') {
      setjobname('Contract');
    }
  }, [formik.values.jobType, formik.values.skillValue]);

  // useEffect(() => {
  //   if (formik.values.jobType === "1") {
  //      setexperience("Active");
  //   } else if (formik.values.jobType === "4") {
  //     setdone("Inactive");
  //   } else if (formik.values.jobType === "2,5,6") {
  //     setdone("Draft");
  //   } else if (formik.values.jobType === "") {
  //     setdone("All");
  //     setdata("");
  //     setdate("");
  //     setTitle("");
  //     setlocationdata("");
  //   }
  //   setTitle(formik.values.jobTitle);
  // }, [
  //   formik.values.jobType,
  //   formik.values.jobTitle,
  // ]);
  useEffect(() => {
    if (formik.values.locationSearch !== '') {
      setlocation(formik.values.locationSearch);
    }
  }, [formik.values.locationSearch]);
  useEffect(() => {
    if (formik.values.skillValue.length !== 0) {
      setskill(
        formik.values.skillValue
          .filter((name) => name.value)
          .map((filter) => filter.value),
      );
    }
  }, [formik.values.skillValue]);
  useEffect(() => {
    if (formik.values.experience.value === '') {
      setexperience('');
      setpresent(false);
    } else if (formik.values.experience.value === '0-1') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '1-2') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '3-5') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '6-10') {
      setexperience(formik.values.experience.label);
    } else if (formik.values.experience.value === '10+') {
      setexperience(formik.values.experience.label);
    }
  }, [formik.values.experience.value]);
  //candidate_sourcing useffect
  useEffect(() => {
    if (formik.values.candidatesource.value === '') {
      setcandidatetype('');
    } else {
      setcandidatetype(formik.values.candidatesource.label);
    }
  }, [formik.values.candidatesource.value]);

  const viewfilterrefresh = () => {
    hanldeRefresh();
    setCheckBox(false);
    setAppliedCandidate(false);
    setSearch('');
    setRelocate(false);
    // formik.resetForm();
    setSkills('');
    setnewjobname('');
    setnewexperience('');
    setnewqual([]);
    setnewlocation('');
    setnewskill([]);
    setnewrelocate(false);
    sethiddenskill1([]);
    setskill([]);
    setexperience('');
    setlocation('');
    setshowmatch('Both');
    setmatching('Both');
    setmatch_val('');
    setTabKey('');
    setnewcandidatetype('');
    setchange(false);
    setZitamatch(false);
    setZitamatchfilter(false);
  };

  const sortrefresh = () => {
    hanldeRefresh();
    setAppliedCandidate(false);
    setCheckBox(false)
    setSearch('');
    setTabKey('');
    setnewcandidatetype('');
    setRelocate(false);
    setshowmatch('Both');
    setmatching('Both');
    setmatch_val('');
    setSkills('');
    setnewjobname('');
    setnewexperience('');
    setnewqual([]);
    setnewlocation('');
    setnewskill([]);
    setnewrelocate(false);
    sethiddenskill1([]);
    setexperience('');
    setlocation('');
    setskill([]);
    setZitamatch(false);
    setZitamatchfilter(false);
    dispatch(
      myDataBaseDataMiddleWare({
        jobTitle: filterFormik.values.jobTitle,
        fav: addFavFilter,
        experience: filterFormik.values.experience.value,
        educationLevel: qaValue,
        typeofJob: filterFormik.values.jobType,
        location: filterFormik.values.locationSearch,
        skill_match: skillsOptionsList,
        relocate: filterFormik.values.reLocateValue,
        candidate: formik.values.searchValue,
        userType: tabKey,
        sort: '',
        page: isPage + 1,
        applicant_only: filterFormik.values.applicantOnly,
        zitamatchfilter:isZitamatchfilter ? "1" : "0",
      }),
    );
  };

  const handleCheckboxChange = () => {
    const newValue = !isCheckbox ? '1' : '0'; // Toggle between '1' and '0'
    filterFormik.setFieldValue('applicantOnly', newValue); // Update Formik field value
    setCheckBox(!isCheckbox); // Toggle local checkbox state
    setchange(true)
  };

  // filterv refresh function
  const clearfilters = () => {
    if (isSortOptions.value !== 'match') {
      sortrefresh();
      setSortOptions({
        value: 'match',
        label: 'Match Score',
      });
    } else {
      viewfilterrefresh();
    }
  };

  // close function
  const closeexp = () => {
    setexperience('');
    formik.setFieldValue('experience', experienceOption[0]);
    setnewexperience('');
    setchange(false);
  };
  // close function candidate
  const closecandidate = () => {
    setCheckBox(false)
    setTabKey('');
    setcandidatetype('');
    formik.setFieldValue('candidatesource', candidate_source[0]);
    setnewcandidatetype('');
    setchange(false);
    filterFormik.setFieldValue('applicantOnly', '0'); 
  };
  const closequal = () => {
    setqual([]);
    // const obj=qualificationOption.map(name=>name.checked===true).findIndex(name=>name.);
    //  qualificationOption[objIndex].checked = false;
  };
  const closeQualification = (doc: {
    value: string;
    label: string;
    checked: boolean;
    onChange: () => void;
  }) => {
    doc.onChange();
  };

  const handlefunction = (doc) => {
    var gremove = newqual.filter((obj) => obj.label !== doc.label);
    setnewqual(gremove);
    closeQualification(doc);
    setchange(false);
  };

  const closejob = () => {
    setjobname('');
    setnewjobname('');
    formik.setFieldValue('jobType', '');
    setchange(false);
  };
  const clearmatching = () => {
    setshowmatch('Both');
    setmatching('Both');
    setmatch_val('');
  };

  const closerelocate = () => {
    setRelocate(false);
    setnewrelocate(false);
  };

  const closezitamatchfilter = () => {
    setZitamatch(false);
    setZitamatchfilter(false);
  };

  const closecandidatefilter = () => {
    setAppliedCandidate(false);
    setCheckBox(false);
    formik.setFieldValue('applicantOnly','0')
  };

  const locationsrh = () => {
    // setlocation('');
    // setnewlocation('');
    setSearch('');
    setchange(true);
  };
  const skillval = (id, val) => {
    var gremove = skildata.filter((obj) => obj.label !== val.label);
    setskildata(gremove);
    setnewskill(gremove);
    formik.setFieldValue('skillValue', gremove);
    setchange(false);
    if (skildata.length < 5) {
      sethiddenskill1(undefined);
    }
    // if(skill.length===0){
    //   setskill([]);
    //   formik.setFieldValue("skillValue","");
    // }
    //   skill.splice(id,1) ;
    //   formik.values.skillValue=skill;
    //   setskill(formik.values.skillValue)
  };
  const handlerelocate1 = () => {
    setRelocate(!isRelocate);
    setchange(true);
  };

  const handlerelocate2 = () => {
    setZitamatch(!isZitamatch);
    setchange(true);
  };


  useEffect(() => {
    setQualificationValue(
      qualificationOption.filter((option) => option.checked),
    );
  }, [qualificationOption]);

  useEffect(() => {
    const ans = qualificationOption
      .filter((chek) => chek.checked !== false)
      .map((name) => name.value);
    if (qualificationOption.length !== 0 && ans.length !== 0) {
      setqual(ans);
    }
  }, [qualificationOption]);

  const closestatus1 = (id) => {
    const objIndex = qualificationOption.findIndex(
      (obj, index) => index === id,
    );
    qualificationOption[objIndex].checked = false;
  };

  const expe = formik.values.experience.label === 'All';
  const job_type = isEmpty(formik.values.jobType);
  const locsrc = isEmpty(formik.values.locationSearch);
  const skillvul = formik.values.skillValue.length === 0;
  const reloc = formik.values.reLocateValue === '0';
  const total = expe && job_type && locsrc && skillvul && reloc;

  const RenderQuickFilter = (props: {
    doc?: { label: string; value: any };
    onClose: () => void;
  }) => {
    const { doc, onClose } = props;
    if (doc === undefined) {
      return null;
    }
    if (doc && (doc.value === '' || doc.value === 'any')) {
      return null;
    }

    return (
      <>
        {doc.label !== 'any' ? (
          <Text className={styles.quickfil}>
            {doc.label}{' '}
            <SvgIntomark className={styles.stylesvg} onClick={onClose} />
          </Text>
        ) : (
          setnewqual(null)
        )}
      </>
    );
  };

  const handlechange = () => {
    setShowDropDown(false);
    setnewjobname(jobname);
    setnewexperience(experience);
    setshowmatch(ismatching);
    setmatch_val(ismatching);
    setnewqual(qualificationValue);
    setnewlocation(isSearch);
    setlocation(isSearch);
    setnewskill(skill);
    setnewrelocate(isRelocate);
    sethiddenskill1(hiddenSkills);
    setnewcandidatetype(candidatetype);
    handleLocation();
    setZitamatchfilter(isZitamatch)
    setAppliedCandidate(isCheckbox)
    setchange(false);
  };
  const handlerelocate = () => {
    setRelocate(!isRelocate);
    setchange(true);
  };

  const handlejoblist = (jobList: any) => {
    formik.setFieldValue('jobType', jobList);
    setchange(true);
  };

  const isDefaultFilter = () => {
    const skills = newskill || [];
    const qualification = newqual || [];
    if (
      qualification?.length === 0 &&
      skills?.length === 0 &&
      newjobname === '' &&
      newexperience === '' &&
      newcandidatetype === '' &&
      newlocation === '' &&
      newrelocate !== true &&
      isZitamatchfilter!==true&&
      isAppliedCandidate!==true&&
      showmatch === 'Both'
    ) {
      return true;
    }

    return false;
  };

  // function for location input field changes update in state.
  const handlelocationupdate = (e) => {
    if (e !== '') {
      if (e.target.innerText !== '' && e !== '') {
        setSearch(e.target.innerText);
      } else if (e !== '') {
        const inputValue = e.target.value;
        if (inputValue.length === 1 && inputValue[0] === ' ') {
          return;
        }
        setSearch(inputValue);
      }

      setchange(true);
    }
  };

  return (
    <>
      <Flex row>
        {console.log("aaaaa",newcandidatetype.length)}
        <div className={styles.quickfilters}>
          <Text size={13} className={''} style={{ marginTop: '3px' }}>
            Applied Filters :
          </Text>
          {ismatching !== undefined &&
          showmatch !== undefined &&
          ismatching !== 'Both' &&
          showmatch !== 'Both' ? (
            <Text className={styles.quickfil}>
              {showmatch}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => clearmatching()}
              />
            </Text>
          ) : null}
          {newjobname === '' ? null : (
            <Text className={styles.quickfil}>
              {newjobname}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => closejob()}
              />
            </Text>
          )}
          {newcandidatetype === '' ? null : (
            <Text className={styles.quickfil}>
              {newcandidatetype}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => closecandidate()}
              />
            </Text>
          )}
          {newexperience === '' ? null : (
            <Text className={styles.quickfil}>
              {newexperience}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => closeexp()}
              />
            </Text>
          )}
          {isDefaultFilter() ? (
            <Text className={styles.quickfil}>{'All'}</Text>
          ) : (
            <Flex row wrap style={{ display: 'contents' }}>
              {newqual &&
                newqual.map((doc, index) => (
                  <RenderQuickFilter
                    key={index}
                    doc={{ label: doc.label, value: doc.value }}
                    onClose={() => handlefunction(doc)}
                  />
                ))}

              {newskill.length === 0
                ? null
                : skildata.slice(0, 4).map((skills, index) => (
                    <Text className={styles.quickfil} key={skills}>
                      {skills.label}{' '}
                      <SvgIntomark
                        className={styles.stylesvg}
                        onClick={() => skillval(index, skills)}
                      />
                    </Text>
                  ))}

              {hiddenskills1 &&
                hiddenskills1.length > 0 &&
                hiddenSkills.length > 0 && (
                  <Text
                    className={styles.quickfil}
                  >{`Skills : + ${hiddenSkills.length}`}</Text>
                )}
            </Flex>
          )}

          {newlocation === '' ? null : (
            <Text className={styles.quickfil}>
              {newlocation}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={() => locationsrh()}
              />
            </Text>
          )}
          {newrelocate ? (
            <Text className={styles.quickfil}>
              {'Willing to Relocate'}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={closerelocate}
              />
            </Text>
          ) : null}

         {isZitamatchfilter ? (
            <Text className={styles.quickfil}>
              {'Zita Match'}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={closezitamatchfilter}
              />
            </Text>
          ) : null}


{isAppliedCandidate ? (
            <Text className={styles.quickfil}>
              {'Applied Candidates'}{' '}
              <SvgIntomark
                className={styles.stylesvg}
                onClick={closecandidatefilter}
              />
            </Text>
          ) : null}
          
        </div>
        <div
          ref={dropDownRef}
          className={styles.drop_down}
          style={{ zIndex: 1 }}
        >
          <Flex
            row
            className={styles.drop_down_header}
            style={{ cursor: 'pointer' }}
          >
            <Flex
              style={{ width: '90%', cursor: 'pointer' }}
              height={30}
              center
              onClick={click}
            >
              <Flex row center style={{ cursor: 'pointer' }}>
                <Text
                  bold
                  color="theme"
                  size={13}
                  style={{ cursor: 'pointer' }}
                >
                  {showDropDown ? 'Hide Filters' : 'View Filters'}
                </Text>

                <Flex
                  center
                  style={{ cursor: 'pointer', margin: '3px 0  0 5px ' }}
                >
                  <SvgArrowUp
                    width={10}
                    height={10}
                    strokeWidth={25}
                    viewBox="0 0 380 380"
                    style={{
                      transform: showDropDown ? 'scaleY(-1)' : 'none',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex title={'Clear Filters'} style={{ cursor: 'pointer' }}>
              <SvgFilterRefresh
                width={16}
                height={16}
                onClick={clearfilters}
                className={styles.filtersvg}
              />
            </Flex>
          </Flex>
          <div
            className={`${styles.drop_down_menus} ${
              showDropDown ? styles.show : ''
            }`}
          >
            <Flex className={styles.mtstyle}>
              <Flex className={styles.skillContainer}>
                <Text
                  color="primary"
                  bold
                  size={13}
                  style={{ marginBottom: '5px' }}
                >
                  Candidate Source
                </Text>
                <SelectTag
                  value={formik.values.candidatesource}
                  options={candidate_source}
                  onChange={(option) => {
                    formik.setFieldValue('candidatesource', option);
                    setTabKey(option?.value);
                    setchange(true);
                  }}
                />
              </Flex>
              {!isEmpty(filterFormik.values.jobTitle) && (
            <Flex row center marginTop={10}>
              {console.log("ramya",candidatetype)}
              {candidatetype === 'Applicants' && (
                <InputCheckBox
                  label="Show only applied candidates"
                  onClick={handleCheckboxChange}
                  checked={isCheckbox}
                />
               )} 
            </Flex>
          )}
            </Flex>
            <Flex className={styles.mtstyle}>
              <Text
                color="primary"
                size={13}
                bold
                className={styles.jobTextStyle}
              >
                Job Type
              </Text>
              <Flex row center className={styles.filterstyle}>
                {jobTypeData.map((jobList) => {
                  return (
                    <Flex
                      row
                      key={jobList.value}
                      width={jobList.width}
                      marginBottom="8px"
                    >
                      <InputRadio
                        label={jobList.value}
                        checked={jobList.label === formik.values.jobType}
                        onClick={() => handlejoblist(jobList.label)}
                      />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>

            <Flex className={styles.mtstyle}>
              <Flex className={styles.skillContainer}>
                <Text
                  color="primary"
                  bold
                  size={13}
                  style={{ marginBottom: '5px' }}
                >
                  Experience
                </Text>
                <SelectTag
                  labelBold
                  // label="Experience"
                  value={formik.values.experience}
                  options={experienceOption}
                  onChange={(option) => {
                    formik.setFieldValue('experience', option);
                    setchange(true);
                  }}
                />
              </Flex>
            </Flex>

            <Flex className={styles.mtstyle}>
              <Text
                color="primary"
                size={13}
                bold
                style={{ marginBottom: '5px' }}
                className={styles.qualificationTextStyle}
              >
                Qualification
              </Text>
              <Flex row center wrap>
                {qualificationOption.map((qualificationList) => {
                  return (
                    <Flex
                      row
                      key={qualificationList.value}
                      className={styles.qualificationRadioStyle}
                      width={qualificationList.width}
                    >
                      <InputCheckBox
                        label={qualificationList.value}
                        checked={qualificationList.checked}
                        onChange={qualificationList.onChange}
                        onClick={() => setchange(true)}
                      />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
            <Flex className={styles.mtstyle}>
              <div className={styles.skillContainer}>
                <Text
                  color="primary"
                  size={13}
                  bold
                  style={{ marginBottom: '5px' }}
                >
                  Skills
                </Text>
                <SelectTag
                  // label="Skills"
                  labelBold
                  ref={selectInputRef}
                  isMulti
                  filter
                  options={slicedOptions}
                  onInputChange={(value) => setSkills(value)}
                  onChange={handleselectskill}
                  isSearchable
                  isCreate
                  value={formik.values.skillValue}
                />
              </div>
            </Flex>

            <Flex className={styles.mtstyle}>
              <div>
                <Text
                  color="primary"
                  size={13}
                  bold
                  style={{ marginBottom: '5px' }}
                >
                  Location
                </Text>
                <InputSearch
                  initialValue={isSearch}
                  placeholder="Enter candidate location"
                  options={candidate_location}
                  CrossiconSpace
                  truncatewidth
                  setFieldValue={(e) => handlelocationupdate(e)}
                  style={styles.boxstyle}
                  onkeyPress={(e) => handlelocationupdate(e)}
                  onChange={(e) => handlelocationupdate(e)}
                />
                {isSearch?.trim() !== '' && (
                  <button className={styles.crossIcon} onClick={locationsrh}>
                    <Flex style={{ cursor: 'pointer' }}>
                      <SvgIntomark width={14} height={14} fill="#888888" />
                    </Flex>
                  </button>
                )}
              </div>
            </Flex>

            {/* The matchng filter work when they select job */}
            {!isEmpty(formik.values.jobTitle) && (
              <div>
                <Flex className={styles.matching_data}>
                  <Text
                    color="primary"
                    size={13}
                    bold
                    style={{ marginBottom: '5px' }}
                  >
                    Matching Status
                  </Text>
                  <Flex row center className={styles.filterstyle}>
                    {matching_data.map((jobList) => {
                      return (
                        <Flex
                          row
                          key={jobList.value}
                          width={jobList.width}
                          marginBottom="8px"
                        >
                          <InputRadio
                            label={jobList.label}
                            checked={jobList.label === ismatching}
                            onClick={() => setmatching(jobList.label)}
                          />
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </div>
            )}

            <div className={styles.mtstyle}>
              <Flex row>
                <Flex className={styles.switch}>
                  <InputSwitch onClick={handlerelocate1} checked={isRelocate} />
                </Flex>
                <Text color="primary" className={styles.toggletext}>
                  Willing to Relocate
                </Text>
              </Flex>
            </div>
            {formik.values.jobTitle!=="" ?(
              <div className={styles.mtstyle}>
              <Flex row>
                <Flex className={styles.switch}>
                  <InputSwitch onClick={handlerelocate2} checked={isZitamatch} / >
                </Flex>
                <Text color="primary" className={styles.toggletext} >
                  Zita Match
                </Text>
              </Flex>
            </div>
            ):(
            <div className={styles.mtstyle}>
              <Flex row>
                <Flex className={styles.switch}>
                  <InputSwitch onClick={handlerelocate2} checked={isZitamatch} title={'Select job title to view'}  tooltip_distance={10} disabled={true}/ >
                </Flex>
                <Text color="primary" className={styles.toggletext} >
                  Zita Match
                </Text>
              </Flex>
            </div>)}
            <Flex row className={styles.filterContainer}>
              <Flex marginRight={10}>
                <Button types="secondary" onClick={clearfilters}>
                  Reset
                </Button>
              </Flex>
              <Flex>
                <Button className={styles.buyBtn} onClick={handlechange}>
                  Apply
                </Button>
              </Flex>
            </Flex>
          </div>
        </div>
      </Flex>
    </>
  );
};
export default MyDataBaseFilter;
