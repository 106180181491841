import { boolean } from 'yup';
import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import Modal from '../../uikit/Modal/Modal';
import Text from '../../uikit/Text/Text';
import { Button } from '../../uikit';
import SvgClose from '../../icons/SvgClose';

type Props = {
  setmodalopen?: (val: any) => void;
  ismodalopen?: boolean;
  totalcount?: number;
  creditlimit?: number;
  allowingdata?: any;
  setNoCount?: (val: any) => void;
  handleunlockavailablecount?: (val?: any) => void;
};

const Talentsourcingmodalcountexite = ({
  setmodalopen,
  ismodalopen,
  totalcount,
  creditlimit,
  allowingdata,
  setNoCount,
  handleunlockavailablecount,
}: Props) => {
  return (
    <Modal open={ismodalopen}>
      <Flex style={{ padding: '25px', backgroundColor: 'white' }} width={603}>
        <Flex end onClick={() => setmodalopen(false)} marginBottom={15}>
          <SvgClose
            width={10}
            height={10}
            fill={'#888888'}
            cursor={'pointer'}
          />
        </Flex>
        <Flex>
          <Text>
            You&rsquo;ve chosen <Text bold>{totalcount}</Text> candidates but
            you only have <Text bold>{creditlimit}</Text> contact unlock credit,
            sufficient for{' '}
            <Text bold style={{ marginRight: '2.5px' }}>
              {allowingdata.length}
            </Text>
            candidate. For the others, please buy more credits.
          </Text>
        </Flex>
        <Flex row end marginTop={20}>
          <Flex
            marginRight={10}
            onClick={() => handleunlockavailablecount(allowingdata)}
          >
            <Button types="secondary">Unlock</Button>
          </Flex>
          <Flex
            onClick={() => {
              setNoCount(true);
              setmodalopen(false);
            }}
          >
            <Button>Buy</Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
export default Talentsourcingmodalcountexite;
