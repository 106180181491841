import { useEffect, useState } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import Flex from '../../uikit/Flex/Flex';
import { Pangination } from '../../uikit';
import { option } from '../myjobposting/mock';
import BulkAction from './BulkAction';
import TalentCardMap from './TalentCardMap';
import { DataEntity } from './talentSourcingTypes';

type experienceOptionsType = {
  value: string;
  label: string;
};

type Props = {
  searchData?: DataEntity[];

  handleUnlockSubmit: (arg: string) => void;
  searchLoader: boolean;
  isBachelors: boolean;
  isDoctorate: boolean;
  isMasters: boolean;

  isAny: boolean;
  isRelocate: boolean;
  isExperience: experienceOptionsType;
  handleCandidateView: (hashKey: string) => void;
  source_limit: number;
  setSourceLimit: (arg: number) => void;
  setCandidatesLimit: (arg: string) => void;
  isCandidatesLimit: string;
  setSuccess: (arg: boolean) => void;
  setNoLimit: (arg: boolean) => void;
  setNoPermission: (arg: boolean) => void;
  candi_list?: string[];
  setNoCount: (arg: boolean) => void;
  setCandiList: (arg: string[]) => void;
  setFree: (arg: boolean) => void;
  planID?: number;
  update: any;
  val: any;
  isCheck: any;
  setIsCheck: any;
  isSubmitLoader?: any;
  pageNumber?: any;
  handleSetPage?: any;
  resume_unlock_credits?: any;
  add_on_plans?: any;
  client_url?: any;
  setopensubcription?: (val: any) => void;
  lockedCandidatesCount?: any;
  setunlockcan: any;
  unlockcan: any;
  setSearchData: any;
  setpagecount?: any;
  ispagecount?: any;
  setformikdata?: any;
  talent_sourcing?: any;
  // setUnlockLoader: (arg: boolean) => void;
  linkedindata: any;
  candidate_ids: any;
  setunlockmodal: any;
  setprofiledownload?: any;
  isCheckAll?: any;
  setIsCheckAll?: (arg: any) => void;
};

const TalentCardList = ({
  searchData,
  client_url,
  handleUnlockSubmit,
  setopensubcription,
  searchLoader,
  isBachelors,
  isDoctorate,
  isMasters,
  isAny,
  isExperience,
  isRelocate,
  handleCandidateView,
  source_limit,
  setSourceLimit,
  setCandidatesLimit,
  isCandidatesLimit,
  setSuccess,
  setNoLimit,
  setNoPermission,
  add_on_plans,
  candi_list,
  setNoCount,
  setCandiList,
  setFree,
  planID,
  update,
  val,
  isCheck,
  setIsCheck,
  isSubmitLoader,
  pageNumber,
  handleSetPage,
  resume_unlock_credits,
  lockedCandidatesCount,
  setunlockcan,
  unlockcan,
  setSearchData,
  setpagecount,
  ispagecount,
  setformikdata,
  talent_sourcing,
  linkedindata,
  candidate_ids,
  setunlockmodal,
  setprofiledownload,
  isCheckAll,
  setIsCheckAll,
}: // setUnlockLoader,
Props) => {
  const [count, setcount] = useState(0);
  const [stylevalue, setstylevalue] = useState(false);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;
  const length: any = candidate_ids?.length;

  const pageCount = Math.ceil(length / usersPerPage);

  //const [isCheck, setIsCheck] = useState([]);
  // const checkArray = isCheckArray.length === 0 ? false : true;
  // select box function
  const handleSelectAll = (vale) => {
    if (vale.target.checked) {
      const idArray = linkedindata.map((candidate) => candidate.id);
      setIsCheckAll(!isCheckAll);
      setIsCheck([...idArray]);
    } else {
      setIsCheckAll(!isCheckAll);
      setIsCheck([]);
    }

    //setIsCheckAll(!isCheckAll);
    // update(!val);
    // setIsCheck(
    //   searchData &&
    //     searchData
    //       .slice(pagesVisited, pagesVisited + usersPerPage)
    //       .map((li) =>
    //         candi_list?.includes(li.id.toString())
    //           ? li.candidate_hash
    //           : li.candidate_hash,
    //       ),
    // );
    // setunlockcan(
    //   searchData &&
    //     searchData
    //       .slice(pagesVisited, pagesVisited + usersPerPage)
    //       .map((li)=>
    //       candi_list?.includes(li.id.toString()) ? false :li.candidate_hash,
    //     ),
    // )
    // if (isCheckAll) {
    //   setIsCheck([]);
    //   setIsCheckAll(false);
    //   update(false);
    // }
  };

  const handleClick = (e, value) => {
    if (!e.target.checked) {
      setIsCheck(isCheck.filter((item: any) => item !== value.id));
      setIsCheckAll(false);
    } else {
      if (isCheck.length + 1 === linkedindata.length) {
        setIsCheckAll(true);
      }
      setIsCheck([...isCheck, value.id]);
    }
  };
  // useEffect(() => {
  //   const valIndex = sessionStorage.getItem('index');
  //   const ans = isCheck.length.toString() === valIndex;
  //   const styleval = Number(valIndex) > 9;
  //   setstylevalue(styleval);

  //   if (isCheckAll && isCheck.length.toString() !== valIndex) {
  //     setIsCheckAll(false);
  //     update(false);
  //   }
  // }, [isCheck, stylevalue]);

  // if (isCheck.length === searchData?.length && isCheckAll === false) {
  //   setIsCheckAll(true);
  //   update(true);
  // }
  // custom styles for page selection text box and dropdown.
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#581845' : '#fff',
      color: state.isSelected ? '#fff' : '#000',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: state.isSelected ? 'none' : '#D7C7D2',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #581845' : '1px solid #d7c7d2',
      fontSize: '12px',
      minHeight: '16px',
      lineHeight: '16px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        border: '1px solid #581845',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      margin: '5px 0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s ease',
      height: '16px',
      marginTop: '-20px',
    }),
    menu: (provided) => ({
      ...provided,
      top: 'auto',
      bottom: 22,
    }),
  };

  const list_candidate =
    searchData && searchData.slice(pagesVisited, pagesVisited + usersPerPage);
  return (
    <>
      <BulkAction
        // setUnlockLoader={setUnlockLoader}
        resume_unlock_credits={resume_unlock_credits}
        setCandiList={setCandiList}
        client_url={client_url}
        setNoCount={setNoCount}
        setopensubcription={setopensubcription}
        add_on_plans={add_on_plans}
        setNoLimit={setNoLimit}
        setNoPermission={setNoPermission}
        setSuccess={setSuccess}
        setCandidatesLimit={setCandidatesLimit}
        isCandidatesLimit={isCandidatesLimit}
        source_limit={source_limit}
        setSourceLimit={setSourceLimit}
        handleSelectAll={handleSelectAll}
        isCheckAll={isCheckAll}
        searchResult={candidate_ids?.length}
        isCheckArray={isCheck}
        setFree={setFree}
        planID={planID}
        setIsCheck={setIsCheck}
        lockedCandidatesCount={lockedCandidatesCount}
        unlockcan={unlockcan}
        setunlockcan={setunlockcan}
        list_candidate={list_candidate}
        candi_list={candi_list}
        setSearchData={setSearchData}
        setunlockmodal={setunlockmodal}
        setprofiledownload={setprofiledownload}
        setIsCheckAll={setIsCheckAll}
      />
      <Flex
        style={{
          width: '100%',
          overflowY: 'scroll',
          height: window.innerHeight - 320,
          padding: '0 0 8px 0',
          alignContent: 'flex-start',
        }}
      >
        <Flex wrap row>
          {linkedindata?.map((dataList, index) => {
            return (
              <TalentCardMap
                candi_list={candi_list}
                handleCandidateView={handleCandidateView}
                isCheck={isCheck}
                handleClick={handleClick}
                key={dataList.first_name + index}
                talentList={dataList}
                index={index}
                handleUnlockSubmit={handleUnlockSubmit}
              />
            );
          })}
        </Flex>
        <Flex marginBottom={10} style={{ paddingTop: '10px' }}>
          {linkedindata?.length !== 0 && isSubmitLoader !== true && (
            <Flex center row between marginBottom={10} flex={12}>
              <Flex flex={4}></Flex>
              <Flex flex={4} middle center>
                <Pangination
                  maxPages={pageCount - 1}
                  currentPage={pageNumber}
                  setCurrentPage={handleSetPage}
                />
              </Flex>
              <Flex row center end flex={4} marginRight={15}></Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default TalentCardList;
// { mapfuction() }
