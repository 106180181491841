import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Flex, Text } from '../../uikit';
import SvgSourceCredits from '../../icons/SvgSourceCredits';
import styles from './CandidateSourcingScreen.module.css';
import CandidateSourcingTab from './CandidateSourcingTab/CandidateSourcingTab';

type Props = {
  setCandidateTab?: any;
  isCandidateTab?: any;
};

export default function CandidateSourcingScreen({
  setCandidateTab,
  isCandidateTab,
}: Props): JSX.Element {
  const [isCredit, setCredit] = useState(true);
  const [isCandidatetabhandle, setCandidatetabhandle] = useState(null);

  const { resume_unlock_credits } = useSelector(
    ({ SubscriptionReducers }: RootState) => {
      return {
        resume_unlock_credits:
          SubscriptionReducers.user_credits?.resume_unlock_credits,
      };
    },
  );

  useEffect(() => {
    setCandidatetabhandle((prevIsCandidatetab) => {
      if (isCandidateTab === '2') {
        return prevIsCandidatetab;
      } else {
        return isCandidateTab;
      }
    });
  }, [isCandidateTab]);

  return (
    <>
      <div className={styles.overAllContainer} style={{ height: window.innerHeight - 64 }}>
        <Flex className={styles.overAlll}>
          <Flex row className={styles.ribbon} between>
            <Flex marginTop={9} marginLeft={8}>
              <Text size={16} bold color="theme">
                Candidate Sourcing
              </Text>
            </Flex>
            <Flex>
              <div className={styles.triangle}></div>
            </Flex>
          </Flex>

          <p
            style={{
              color: '#333333',
              marginBottom: '0px',
              padding: '10px 0px',
              fontSize: '13px',
            }}
          >
            Unlock profiles and create your own database to match candidates
            with posted jobs.
          </p>

          <Flex className={styles.tabFlex}>
            <Flex className={styles.candidatesText} row center>
              <Flex title="Contact Unlock Credits">
                <SvgSourceCredits width={16} height={16} />
              </Flex>
              <Flex marginLeft={2}>
                <Text bold>: {resume_unlock_credits}</Text>
              </Flex>
            </Flex>
            <CandidateSourcingTab
              setCandidateTab={setCandidateTab}
              isCandidateTab={isCandidateTab}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
}
