import Flex from '../../uikit/Flex/Flex';
import Loader from '../../uikit/Loader/Loader';
import Modal from '../../uikit/Modal/Modal';
import Text from '../../uikit/Text/Text';
import styles from './unlockloadermodal.module.css';

type Props = {
  open: boolean;
  title: string;
  linkedin?:boolean
};
const UnlockLoaderModal = ({ open, title ,linkedin}: Props) => {
  return (
    <div>
      {linkedin?(
        <div >
        <Modal open={open} linkedin={true}>
        <Flex row  center className={styles.overAll1}>
          <Loader size={'medium'} withOutOverlay />
          <Text className={styles.textStyle1}>{title}</Text>
        </Flex>
      </Modal>
      </div>
      ):(
    <Modal open={open}>
      <Flex row  center className={styles.overAll}>
        <Loader size={'medium'} withOutOverlay />
        <Text className={styles.textStyle}>{title}</Text>
      </Flex>
    </Modal>
    )}
    </div>
  );
};

export default UnlockLoaderModal;
