import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import Totalcount from '../../globulization/TotalCount';
import SvgJobPost from '../../icons/SvgJobPost';
import SvgNoData from '../../icons/SvgNoData';
import Flex from '../../uikit/Flex/Flex';
import Text from '../../uikit/Text/Text';
import Loader from '../../uikit/Loader/Loader';
import Button from '../../uikit/Button/Button';
import {
  getBlur,
  getFocus,
  copyToClipboard,
  isEmpty,
} from '../../uikit/helper';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import { jobCreateNonDs } from '../../appRoutesPath';
import { AppDispatch, RootState } from '../../store';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import SvgInfo from '../../icons/SvgInfo';
import useUnsavedChangesWarning from '../common/useUnsavedChangesWarning';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { permissionMiddleWare } from '../Login/store/middleware/loginMiddleWare';
import SingleButton from '../common/SingleButton';
import SvgModuleicon from '../../icons/SvgModuleicon';
import { postedOn } from './mock';
import {
  myJobPostingInitalMiddleWare,
  myJobPostingDataMiddleWare,
} from './store/middleware/myjobpostingmiddleware';
import styles from './myjobpostingscreen.module.css';
import Table from './Table';
import MyJobsPostingFilter, { MyJobFormProps } from './MyJobsPostingFilter';

const cx = classNames.bind(styles);
const initial: MyJobFormProps = {
  jobTitle: '',
  jobId: '',
  postedOn: postedOn[0],
  jobType: '',
  location: '',
};

const MyJobPostingScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isPage, setPage] = useState(0);
  const [isLoad, setIsLoad] = useState(true);
  const history = useHistory();
  const [change, setchange] = useState(false);
  const { onDirty, onPristine, routerPrompt } = useUnsavedChangesWarning();
  const [isReload, setReload] = useState(false);
  const [remote, setremote] = useState(false);
  const [isopensubcription, setopensubcription] = useState(false);
  const [isinfo, setinfo] = useState(false);
  const [isNoPermission, setNoPermission] = useState(false);
  const [ispagecount, setpagecount] = useState<any>(null);
  const [issettingstate, setsettingstate] = useState<any>(false); 
  const [istriggerremote,settriggerremote] = useState<any>(false);

  useEffect(() => {
    setIsLoad(true);
    localStorage.setItem('freeCheck', 'true');
    dispatch(myJobPostingInitalMiddleWare()).then((res) => { });
  }, []);
  useEffect(() => {
    localStorage.setItem('defaultclick', 'true');
    localStorage.removeItem('jd_id');
    dispatch(SubsriptionMiddleWare());
  }, []);

  // while add-on purchased after they call API's
  const useQuery = new URLSearchParams(window.location.search);
  const query = useQuery;
  const session: any = query.get('session');
  useEffect(() => {
    dispatch(permissionMiddleWare());
    dispatch(SubsriptionMiddleWare());
    dispatch(myJobPostingInitalMiddleWare());
  }, [session]);

  const {
    location_list,
    job_ids,
    all_job,
    job_title,
    final_list,
    Jobs_List,
    is_loadingone,
    len_list,
    is_loading,
    career_page_url,
    Permission,
    is_plan,
    current_plan,
    current_jd_count,
    super_user,
    Last_plan,
    job_post,
  } = useSelector(
    ({
      myJobPosingReducers,
      myJobPostingDataReducers,
      permissionReducers,
      SubscriptionReducers,
    }: RootState) => ({
      // Jobs_List:0,
      Jobs_List: myJobPostingDataReducers.Jobs_List,
      location_list: myJobPosingReducers.location_list,
      job_ids: myJobPosingReducers.job_ids,
      all_job: myJobPosingReducers,
      job_title: myJobPosingReducers.job_title,
      final_list: myJobPostingDataReducers.final_list,
      len_list: myJobPostingDataReducers.len_list,
      is_loading: myJobPostingDataReducers.isLoading,
      is_loadingone: myJobPosingReducers.isLoading,
      career_page_url: myJobPostingDataReducers.career_page_url,
      domain: myJobPostingDataReducers.domain,
      Permission: permissionReducers.Permission,
      is_plan: permissionReducers.is_plan,
      current_plan: SubscriptionReducers.current_plan,
      Last_plan: SubscriptionReducers.Last_plan,
      current_jd_count: SubscriptionReducers.current_jd_count,
      super_user: permissionReducers.super_user,
      job_post: SubscriptionReducers.job_post,
    }),
  ); 
  document.title = 'Job Posting';

  useEffect(() => {
    setpagecount(job_post);
  }, [job_post]);

  useEffect(() => {
    if (!is_plan) {
      sessionStorage.setItem('MyAccountDropdown', '1')
      history.push('/account_setting/subscription');
    }
  });

  const formik = useFormik({
    initialValues: initial,
    onSubmit: () => { },
  });

  const handleSetPagination = (a: number) => {
    setPage(a);
    if (final_list.length !== 0) {
      getFocus('myjobpostscreen___input');
      getBlur('myjobpostscreen___input');
    }
  };
  useEffect(() => {
    if (change === true && ispagecount !== null) {
      setIsLoad(true);
      dispatch(
        myJobPostingDataMiddleWare({
          jobTitle: formik.values.jobTitle,
          jobId: formik.values.jobId,
          postedOn: formik.values.postedOn.value,
          jobType: formik.values.jobType,
          location: formik.values.location,
          page: isPage + 1,
          remote: remote,
          pagecount: ispagecount,
        }),
      ).then((res: any) => {
        dispatch(SubsriptionMiddleWare()).then(() => {
          setsettingstate(true);
          setIsLoad(false);
        });
      });
    }
  }, [isPage, remote]);
  useEffect(() => {
    if (change === false && ispagecount !== null) {
      setIsLoad(true);
      dispatch(
        myJobPostingDataMiddleWare({
          jobTitle: formik.values.jobTitle,
          jobId: formik.values.jobId,
          postedOn: formik.values.postedOn.value,
          jobType: formik.values.jobType,
          location: formik.values.location,
          page: isPage + 1,
          remote: remote,
          pagecount: ispagecount,
        }),
      ).then((res: any) => {
        dispatch(SubsriptionMiddleWare()).then(() => {
          setsettingstate(true);
          setIsLoad(false);
        });
      });
    }
  }, [isPage, change, formik.values, remote, ispagecount]);
  useEffect(() => {
    if (isReload) {
      setReload(false);
    } else if (!isReload) {
      onPristine();
    }
  }, [isReload]);

  const handleRelocate = () => {
    setremote(!remote);
    setchange(true);
  };

  return (
    <>
      {!isLoad && (
        <Flex className={styles.overFlowContainer}>
          <SingleButton
            btnTitle="OK"
            title={`Please contact your company admin to ${Last_plan?.includes(current_plan) ? 'purchase' : 'upgrade'
              }.`}
            open={isNoPermission}
            btnOnclick={() => setNoPermission(false)}
          />
          <div>
            <Flex row className={styles.titleContainer}>
              {/* <SvgJobPost width={15.71} height={16} /> */}
              <Text
                bold
                size={16}
                style={{ marginLeft: 8, color: '#581845' }}
                className={styles.postingcl}
              >
                Job Listings
              </Text>
              <div className={styles.triangle}></div>
            </Flex>
          </div>
          {current_jd_count === 0 &&
            super_user !== null &&
            super_user === false &&
            current_plan !== 1 &&
            !Last_plan?.includes(current_plan) && (
              <Flex row center className={styles.warningFlex}>
                <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
                <Text
                  style={{ color: '#333333' }}
                  className={styles.warningText}
                >
                  <Text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                    bold
                  >
                    Heads Up!{' '}
                  </Text>
                  <Text>To create a job, you have the option to either </Text>

                  <Text
                    bold
                    color="theme"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setNoPermission(true)}
                  >
                    Upgrade
                  </Text>

                  <Text> your subscription or explore our </Text>

                  <Text
                    color="theme"
                    bold
                    style={{ cursor: 'pointer' }}
                    onClick={() => setNoPermission(true)}
                  >
                    Add-on{' '}
                  </Text>

                  <Text>features.</Text>
                </Text>
              </Flex>
            )}
          {current_jd_count === 0 &&
            super_user !== null &&
            super_user === false &&
            current_jd_count === 0 &&
            (current_plan === 1 || Last_plan?.includes(current_plan)) && (
              <Flex row center className={styles.warningFlex}>
                <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
                <Text
                  style={{ color: '#333333' }}
                  className={styles.warningText}
                >
                  <Text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                    bold
                  >
                    Heads Up!{' '}
                  </Text>
                  <Text>To create a job, you must </Text>
                  {Last_plan?.includes(current_plan) ? (
                    <Text
                      bold
                      color="theme"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setNoPermission(true)}
                    >
                      Add-on
                    </Text>
                  ) : (
                    <Text
                      bold
                      color="theme"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setNoPermission(true)}
                    >
                      Upgrade
                    </Text>
                  )}
                  <Text> your subscription</Text>
                </Text>
              </Flex>
            )}
          {current_jd_count === 0 &&
            super_user !== null &&
            super_user === true &&
            current_jd_count === 0 &&
            (current_plan === 1 || Last_plan?.includes(current_plan)) && (
              <Flex row center className={styles.warningFlex}>
                <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
                <Text
                  style={{ color: '#333333' }}
                  className={styles.warningText}
                >
                  <Text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                    bold
                  >
                    Heads Up!{' '}
                  </Text>
                  <Text>To create a job, you must </Text>
                  <LinkWrapper onClick={()=>{sessionStorage.setItem('MyAccountDropdown','1')}} to={'/account_setting/subscription'}>
                    {Last_plan?.includes(current_plan) ? (
                      <Text bold color="theme" style={{ cursor: 'pointer' }}>
                        Add-on
                      </Text>
                    ) : (
                      <Text bold color="theme" style={{ cursor: 'pointer' }}>
                        Upgrade
                      </Text>
                    )}
                  </LinkWrapper>
                  <Text> your subscription</Text>
                </Text>
              </Flex>
            )}
          {current_jd_count === 0 &&
            super_user === true &&
            current_jd_count === 0 &&
            current_plan !== 1 &&
            !Last_plan?.includes(current_plan) && (
              <Flex row center className={styles.warningFlex}>
                <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
                <Text
                  style={{ color: '#333333' }}
                  className={styles.warningText}
                >
                  <Text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                    bold
                  >
                    Heads Up!{' '}
                  </Text>
                  <Text>To create a job, you have the option to either </Text>
                  <LinkWrapper onClick={()=>{sessionStorage.setItem('MyAccountDropdown','1')}} to={'/account_setting/subscription'}>
                    <Text bold color="theme" style={{ cursor: 'pointer' }}>
                      Upgrade
                    </Text>
                  </LinkWrapper>
                  <Text> your subscription or explore our </Text>
                  <LinkWrapper onClick={()=>{sessionStorage.setItem('MyAccountDropdown','1')}} to={'/account_setting/subscription'}>
                    <Text
                      color="theme"
                      bold
                      style={{ cursor: 'pointer' }}
                    // /account_setting/settings?tab=2
                    >
                      Add-on{' '}
                    </Text>
                  </LinkWrapper>
                  <Text>features.</Text>
                </Text>
              </Flex>
            )}
          {Jobs_List === 2 && (
            <Flex>
              <div className={cx('tabsContainer')}>
                <Flex row between className={styles.searchbox}>
                  <Flex row center>
                    <Flex>
                      <Totalcount name="Total Jobs Found " numbers={len_list} />
                    </Flex>
                    <Flex marginLeft={25}>
                      <Totalcount
                        name="Available Job Posting"
                        numbers={current_jd_count}
                      />
                    </Flex>
                    <Flex marginLeft={7} marginTop={6.5}>
                      <label
                        onMouseEnter={() => setinfo(true)}
                        onMouseLeave={() => setinfo(false)}
                      >
                        {/* <SvgInfo height={12} width={12} fill={'#FFC203'} /> */}
                        <SvgModuleicon />
                      </label>
                    </Flex>
                    {isinfo && (
                      <Flex
                        style={{
                          boxShadow: '3px 2px 9px 0px',
                          padding: ' 4px',
                          borderRadius: '4px',
                        }}
                        marginLeft={10}
                      >
                        Credit includes free and purchased credits
                      </Flex>
                    )}
                  </Flex>
                  <Flex row className={styles.twobutton} marginRight={10}>
                    {' '}
                    {Permission.includes('create_post') && (
                      <>
                        {current_jd_count !== 0 ? (
                          <LinkWrapper to={jobCreateNonDs}>
                            <Button className={styles.style1} types="primary">
                              <Text color="white" bold>
                                Post Job
                              </Text>
                            </Button>
                          </LinkWrapper>
                        ) : (
                          <Button
                            style={{ cursor: 'pointer' }}
                            className={styles.style1}
                            types="primary"
                            onClick={() => setopensubcription(true)}
                          >
                            <Flex row>
                              <Flex style={{ cursor: 'pointer' }}>
                                <Text color="white" bold>
                                  Post Job
                                </Text>
                              </Flex>
                              <Flex
                                marginLeft={5}
                                marginTop={1}
                                style={{ cursor: 'pointer' }}
                              >
                                <SvgSubcriptioncrown
                                  height={14}
                                  width={14}
                                  fill=""
                                />
                              </Flex>
                            </Flex>
                          </Button>
                        )}
                      </>
                    )}
                    <LinkWrapper
                      target={isEmpty(career_page_url) ? '_parent' : '_blank'}
                      onClick={()=>isEmpty(career_page_url) ? sessionStorage.setItem('MyAccountDropdown','1'): ''}
                      to={
                        isEmpty(career_page_url)
                          ? `/account_setting/build_careers_page`
                          : `/${career_page_url}/careers`
                      }
                    >
                      <Button className={styles.style2} types="primary">
                        View Careers Page
                      </Button>
                    </LinkWrapper>
                  </Flex>
                </Flex>
                <Flex>
                  <div className={cx('filterOverAll')}>
                    <MyJobsPostingFilter
                      formik={formik}
                      job_ids={job_ids}
                      job_title={job_title}
                      location_list={location_list}
                      setchange={setchange}
                      handleRelocate={handleRelocate}
                      remote={remote}
                      setremote={setremote}
                      setPage={setPage}
                      setsettingstate={setsettingstate}
                      issettingstate={issettingstate} 
                      settriggerremote={settriggerremote}
                      istriggerremote={istriggerremote}
                      
                    />
                  </div>

                  <Flex>
                    <Table
                      ispagecount={job_post}
                      setpagecount={setpagecount}
                      currentPage={isPage}
                      setCurrentPage={handleSetPagination}
                      setPage={setPage}
                    />
                  </Flex>
                </Flex>
              </div>
            </Flex>
          )}

          {len_list === 0 && Jobs_List !== 1 && (
            <Flex center style={{ height: '100%' }}>
              <Flex
                className="container"
                flex={1}
                center
                middle
                width={window.innerWidth - 570}
              >
                <Flex center middle>
                  <SvgNoData width={16} height={16} fill={'#888888'} />
                </Flex>
                <Text
                  style={{
                    color: 'gray',
                    fontSize: 13,
                  }}
                >
                  No job found
                </Text>
              </Flex>
            </Flex>
          )}
          {Jobs_List === 1 && (
            <Flex middle className={styles.overAll2}>
              <Flex center>
                <Flex center>
                  <Text className={styles.postyet1} size={13}>
                    No Job Posts - Yet !
                  </Text>
                </Flex>
                <Flex center>
                  <Text className={styles.postyet2} size={13}>
                    Looks like you haven’t posted any jobs
                  </Text>
                </Flex>
                <Flex center>
                  <Text className={styles.postyet3} size={13}>
                    No worries, just click on “Post Job” to kick start
                  </Text>
                </Flex>
                {Permission.includes('create_post') && (
                  <Flex center className={styles.postyet4}>
                    <LinkWrapper to={jobCreateNonDs}>
                      <Button className={styles.btnStyle} types="primary">
                        Post Job
                      </Button>{' '}
                    </LinkWrapper>{' '}
                  </Flex>
                )}{' '}
              </Flex>{' '}
            </Flex>
          )}
          {isopensubcription && (
            <SubscriptionModal
              openmodel={isopensubcription}
              setopensubcription={setopensubcription}
              addon_name={['Job Posting Credits']}
              differece_key={['job_post']}
              purpose={
                <div>
                  <strong>
                    <text
                      style={{
                        color: '#2E6ADD',
                        marginRight: '3px',
                        fontSize: '13px',
                      }}
                    >
                      Heads Up!
                    </text>
                  </strong>{' '}
                  <strong>Job Posting Limit Exceeds:</strong> To continue
                  posting new jobs, you can &apos;Upgrade your plan&apos; or
                  &apos;Buy additional Job Posting add-on&apos;.
                </div>
              }
              purpose_freetrial={
                <div>
                  <strong>
                    <text
                      style={{
                        color: '#2E6ADD',
                        marginRight: '3px',
                        fontSize: '13px',
                      }}
                    >
                      Heads Up!
                    </text>
                  </strong>
                  <strong>Job Posting Limit Exceeds:</strong> To continue
                  posting new jobs, you must &apos;Upgrade your plan&apos;.
                </div>
              }
              purpose_addon={
                <div>
                  <strong>
                    <text
                      style={{
                        color: '#2E6ADD',
                        marginRight: '3px',
                        fontSize: '13px',
                      }}
                    >
                      Heads Up!
                    </text>
                  </strong>
                  <strong>Job Posting Limit Exceeds:</strong> To continue
                  posting new jobs, you must &apos;Buy additional Job Posting
                  add-on&apos;.
                </div>
              }
            />
          )}
        </Flex>
      )}
      {isLoad && <Loader />}
    </>
  );
};
export default MyJobPostingScreen;
