import classNames from 'classnames/bind';
import SvgSwitchOff from '../../icons/SvgSwicthOff';
import SvgSwitchOn from '../../icons/SvgSwitchOn';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';
import CustomTooltip from '../Tooltip/tooltip';  // Ensure this import is correct
import styles from './inputswitch.module.css';

const cx = classNames.bind(styles);

type Props = {
  onClick?: (a: any, b?: any) => void;
  onBlur?: (args: any) => void;
  checked?: boolean;
  label?: import('react').ReactNode;
  disabled?: boolean;
  offFill?: string;
  title?: string;
  tooltip_distance?: number;  // Add tooltip_distance for custom tooltip positioning
};

const InputSwitch = ({ onClick, onBlur, checked, label, disabled, offFill, title, tooltip_distance }: Props) => {


  return title ? (
    <CustomTooltip title={title} tooltip_distance={tooltip_distance} direction_tooltip='right'>
       <div
      role={'button'}
      tabIndex={0}
      onClick={!disabled ? onClick : undefined}
      onBlur={onBlur}
      
      onKeyPress={() => {}}
    >
      <Flex row center className={`${styles.pointer}`} style={{fill:'#b3b3b3'}}>
        {!checked ? (
          <SvgSwitchOff fill={'#b3b3b3'} />
        ) : (
          <SvgSwitchOn className={styles.svgRadio} />
        )}
        <Text className={styles.labelStyle}>{label}</Text>
      </Flex>
    </div>
    </CustomTooltip>
  ) : (
    <div
    role={'button'}
    tabIndex={0}
    onClick={onClick}
    onBlur={onBlur}
    className={cx('overAll', { disabled })}
    onKeyPress={() => {}}
  >
    <Flex row center className={styles.pointer}>
      {!checked ? (
        <SvgSwitchOff fill={offFill} />
      ) : (
        <SvgSwitchOn className={styles.svgRadio} />
      )}
      <Text className={styles.labelStyle}>{label}</Text>
    </Flex>
  </div>
  );
};

export default InputSwitch;
