import Text from '../../uikit/Text/Text';
import { isEmpty } from '../../uikit/helper';
import ProgressBar from '../../uikit/ProgressBar/ProgressBar';
import SvgImportTick from '../../icons/SvgImportTick';
import Flex from '../../uikit/Flex/Flex';
import SvgDotMenu from '../../icons/SvgDotMenu';
import Action from './Action';
import { EmpPoolEntity } from './types';
import ContactAdd from './ContactAdd';
import EmailAdd from './EmailAdd';
import ExperienceAdd from './ExperienceAdd';
import ImportedOnAdd from './ImportedOnAdd';
import LocationAdd from './LocationAdd';
import QualificationAdd from './QualificationAdd';
import SkillsAdd from './SkillsAdd';
import Status from './Status';
import ValueAddName from './ValueAddName';

const getCurrentDate = () => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();
  return `${day}/${month}/${year}`;
};

export const Sampledata = [
  {
    name: 'Jacob',
    email: 'jacob@yahoo.com',
    contact: '+1-212-456-7890',
    location: 'United States',
    qualification: 'Masters',
    experience: '6 Years',
    skillsss: 'React, TypeScript, CSS, HTML',
    updated: getCurrentDate(),
  },
  {
    name: 'John Jameson',
    email: 'jamesonjj@yahoo.com',
    contact: '+ 1 819 555 5555',
    location: 'Canada',
    qualification: 'Masters',
    experience: '2 Years',
    skillsss: 'Wordpress, JavaScript',
    updated: getCurrentDate(),
  },
  {
    name: 'Hudson Chirs',
    email: 'chudson@yahoo.com',
    contact: '+44 20 7123 4567',
    location: 'London',
    qualification: 'Masters',
    experience: '3 Years',
    skillsss: 'Angular, React, Bootstrap',
    updated: getCurrentDate(),
  },
];

export const ApplicantSampledata = [
  {
    name: 'Jacob',
    email: 'jacob@yahoo.com',
    contact: '+1-212-456-7890',
    location: 'United States',
    qualification: 'Masters',
    experience: '6 Years',
    skillsss: 'React, TypeScript, CSS, HTML',
    updated: 'DD/MM/YYYY',
  },
  {
    name: 'John Jameson',
    email: 'jamesonjj@yahoo.com',
    contact: '+ 1 819 555 5555',
    location: 'Canada',
    qualification: 'Masters',
    experience: '2 Years',
    skillsss: 'Wordpress, JavaScript',
    updated: 'DD/MM/YYYY',
  },
  {
    name: 'Hudson Chirs',
    email: 'chudson@yahoo.com',
    contact: '+44 20 7123 4567',
    location: 'London',
    qualification: 'Masters',
    experience: '3 Years',
    skillsss: 'Angular, React, Bootstrap',
    updated: 'DD/MM/YYYY',
  },
];

export const columnsCandi = [
  {
    title: 'Name *',
    dataIndex: 'name',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.name}</Text>;
    },
  },
  {
    title: 'Email ID *',
    dataIndex: 'email',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.email}</Text>;
    },
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
    key: 'contact',
    flex: 11,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.contact}</Text>;
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    flex: 11,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.location}</Text>;
    },
  },
  {
    title: 'Qualification',
    dataIndex: 'qualification',
    key: 'qualification',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.qualification}</Text>;
    },
  },
  {
    title: 'Experience',
    dataIndex: 'work_exp',
    key: 'work_exp',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.experience}</Text>;
    },
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    key: 'skills',
    flex: 15,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.skillsss}</Text>;
    },
  },

  {
    title: 'Updated On',
    dataIndex: 'updated_on',
    key: 'updated_on',
    flex: 7,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.updated}</Text>;
    },
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    flex: 4,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <Flex center middle>
          <SvgImportTick />
        </Flex>
      );
    },
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    flex: 3,
    render: () => {
      return (
        <Flex center middle>
          <SvgDotMenu width={16} height={16} fill="gray" />
        </Flex>
      );
    },
    align: 'center',
  },
];

export const title = (
  hanldeProfileView: (arg: number) => void,
  setFeaturesBalance: (a: any) => void,
  searchValue: string,
  tabKey: string,
  total_count: number,
  completed: number,
  incompleted: number,
  pageNumber: number,
  emp_pool?: any,
) => [
  {
    title: 'Name *',
    dataIndex: 'first_name',
    key: 'first_name',
    flex: 12,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <Flex style={{ wordBreak: 'break-word' }}>
          <ValueAddName
            value={value}
            searchValue={searchValue}
            completed={completed}
            incompleted={incompleted}
            total_count={total_count}
            tabKey={tabKey}
            pageNumber={pageNumber}
          />
        </Flex>
      );
    },
  },
  {
    title: 'Email ID *',
    dataIndex: 'email',
    key: 'email',
    flex: 12,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <EmailAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
    key: 'contact',
    flex: 11,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <ContactAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    flex: 11,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <LocationAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Qualification',
    dataIndex: 'qualification',
    key: 'qualification',
    flex: 12,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <QualificationAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Experience',
    dataIndex: 'work_exp',
    key: 'work_exp',
    flex: 12,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <ExperienceAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    key: 'skills',
    flex: 15,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <SkillsAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
          emp_pool={emp_pool}
        />
      );
    },
  },

  {
    title: 'Updated On',
    dataIndex: 'updated_on',
    key: 'updated_on',
    flex: 7,
    render: (value: string) => {
      return <ImportedOnAdd value={value} />;
    },
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    flex: 4,
    render: (_a: any, value: EmpPoolEntity) => {
      return <Status value={value} />;
    },
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    flex: 3,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <Action
          value={value}
          setFeaturesBalance={setFeaturesBalance}
          hanldeProfileView={hanldeProfileView}
          searchValue={searchValue}
          applicant={false}
          tabKey={tabKey}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          pageNumber={pageNumber}
        />
      );
    },
    align: 'center',
  },
];

export const applicantTable = (
  setFeaturesBalance: (arg: number) => void,
  hanldeProfileView: (arg: number) => void,
  searchValue: string,
  tabKey: string,
  isJdId: string,
  total_count: number,
  completed: number,
  incompleted: number,
  pageNumber: number,
) => [
  {
    title: 'Name *',
    dataIndex: 'first_name',
    key: 'first_name',
    flex: 13,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <ValueAddName
          value={value}
          searchValue={searchValue}
          completed={completed}
          jdId={isJdId}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Email ID *',
    dataIndex: 'email',
    key: 'email',
    flex: 13,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <EmailAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          jdId={isJdId}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
    key: 'contact',
    flex: 11,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <ContactAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          jdId={isJdId}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  // {
  //   title: 'Match',
  //   dataIndex: 'match',
  //   key: 'match',
  //   flex: 7,
  //   render: (value: number, values: EmpPoolEntity) => {
  //     return (
  //       <>
  //         {value === null ? (
  //           <ProgressBar type="hr" percentage={0} block_descriptive={values.block_descriptive} checkingblur={true} />
  //         ) : (
  //           <ProgressBar
  //             type="hr"
  //             percentage={value}
  //             checkvalue={values}
  //             checkingblur={true}
  //             block_descriptive={values.block_descriptive} />
  //         )}
  //       </>
  //     );
  //   },
  // },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    flex: 12,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <LocationAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          jdId={isJdId}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Qualification',
    dataIndex: 'qualification',
    key: 'qualification',
    flex: 13,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <QualificationAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          jdId={isJdId}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Experience',
    dataIndex: 'work_exp',
    key: 'work_exp',
    flex: 13,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <ExperienceAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          incompleted={incompleted}
          jdId={isJdId}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    key: 'skills',
    flex: 12,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <SkillsAdd
          value={value}
          searchValue={searchValue}
          completed={completed}
          jdId={isJdId}
          incompleted={incompleted}
          total_count={total_count}
          tabKey={tabKey}
          pageNumber={pageNumber}
        />
      );
    },
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    flex: 5,
    render: (_a: any, value: EmpPoolEntity) => {
      return <Status value={value} jdId={isJdId} />;
    },
    align: 'center',
  },

  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    flex: 5,
    render: (_a: any, value: EmpPoolEntity) => {
      return (
        <Action
          value={value}
          setFeaturesBalance={setFeaturesBalance}
          hanldeProfileView={hanldeProfileView}
          searchValue={searchValue}
          applicant={true}
          tabKey={tabKey}
          jdId={isJdId}
          completed={completed}
          incompleted={incompleted}
          total_count={total_count}
          pageNumber={pageNumber}
        />
      );
    },
    align: 'center',
  },
];

export const ApplicantmappedData = [
  {
    title: 'Name *',
    dataIndex: 'name',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.name}</Text>;
    },
  },
  {
    title: 'Email ID *',
    dataIndex: 'email',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.email}</Text>;
    },
  },
  {
    title: 'Contact',
    dataIndex: 'contact',
    flex: 11,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.contact}</Text>;
    },
  },
  {
    title: 'Location',
    dataIndex: 'location',
    flex: 11,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.location}</Text>;
    },
  },
  {
    title: 'Qualification',
    dataIndex: 'qualification',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.qualification}</Text>;
    },
  },
  {
    title: 'Experience',
    dataIndex: 'experience',
    flex: 12,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.experience}</Text>;
    },
  },
  {
    title: 'Skills',
    dataIndex: 'skills',
    flex: 15,
    render: (_a: any, value: any) => {
      return <Text color="placeholder">{value.skillsss}</Text>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    flex: 4,
    render: () => {
      return (
        <Flex center middle>
          <SvgImportTick />
        </Flex>
      );
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    flex: 3,
    render: () => {
      return (
        <Flex center middle>
          <SvgDotMenu width={16} height={16} fill="gray" />
        </Flex>
      );
    },
  },
];
