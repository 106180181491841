import { createSlice } from '@reduxjs/toolkit';
import {
  LinkedinSourcingdata,
  unlockedId,
  LinkedinSourcingunloackedcandidates,
} from '../../talentSourcingTypes';
import {
  LinkedinSourcingSearchMiddleWare,
  unlockedcandidateget,
  linkedincandidateunlock,
} from '../middleware/candidateSoucringMiddleware';

const linkedinSourcingInitialState: LinkedinSourcingdata = {
  isLoading: false,
  error: '',
  candidates: [],
  candidates_ids: [],
};

const unlockedids: unlockedId = {
  isLoading: false,
  error: '',
  candidates_ids: [],
  file_path: '',
};

const unlockcandidate: LinkedinSourcingunloackedcandidates = {
  isLoading: false,
  error: '',
  data: [],
};

const linkedinSourcingReducer = createSlice({
  name: 'linkedin_sourcing',
  initialState: linkedinSourcingInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(LinkedinSourcingSearchMiddleWare.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(
      LinkedinSourcingSearchMiddleWare.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.candidates_ids = action.payload.candidates_ids;
      },
    );
    builder.addCase(
      LinkedinSourcingSearchMiddleWare.rejected,
      (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === 'string') {
          state.error = action.payload;
        }
      },
    );
  },
});

const unlockedcandidatesReducer = createSlice({
  name: 'linkedin_sourcing_ids',
  initialState: unlockedids,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(unlockedcandidateget.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(unlockedcandidateget.fulfilled, (state, action) => {
      state.isLoading = false;
      state.candidates_ids = action.payload.unlocked;
      state.file_path = action.payload.file_path;
    });
    builder.addCase(unlockedcandidateget.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

const linkedinunlockcandidateReducer = createSlice({
  name: 'linkedin_sourcing_ids',
  initialState: unlockcandidate,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(linkedincandidateunlock.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(linkedincandidateunlock.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(linkedincandidateunlock.rejected, (state, action) => {
      state.isLoading = false;
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      }
    });
  },
});

export const LinkedincandidateSourcingReducers =
  linkedinSourcingReducer.reducer;
export const UnlockcandidatesReducers = unlockedcandidatesReducer.reducer;
export const LinkedinunlockcandidateReducer =
  linkedinunlockcandidateReducer.reducer;
