import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useDispatch,
  useSelector,
  // useSelector
} from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import StarsRating from 'react-star-rate';
import SvgGmail from '../../../icons/SvgGmail';
import SvgDotMenu from '../../../icons/SvgDotMenu';
import SvgEdit from '../../../icons/SvgEdit';
import SvgTick from '../../../icons/SvgTick';
import SvgOutlook from '../../../icons/SvgOutlook';
import SvgClose from '../../../icons/SvgClose';
import SvgOutlookcalendar from '../../../icons/SvgOutlookcalendar';
import SvgGooglecalendar from '../../../icons/SvgGooglecalendar';
import SvgRight from '../../../icons/SvgRight';

import {
  AppDispatch,
  RootState,
  //  RootState
} from '../../../store';
import {
  Button,
  Card,
  Flex,
  InputRadio,
  LinkWrapper,
  Loader,
  Modal,
  Text,
  Toast,
} from '../../../uikit';
import {
  // addOauthMiddleware,
  outlookCallApiMiddleware,
  checkAuthMiddleware,
  googleCallApiMiddleware,
  IntergratemailMiddleWare,
  outlookCallbackMiddleware,
  googleCallbackMiddleware,
} from '../../applicantprofilemodule/store/middleware/applicantProfileMiddleware';

import {
  Google_Auth,
  Outlook_Auth,
  getEmail,
} from '../../emailintegrationmodule/store/middleware/emailIntegrationMiddleWare';
import { GARY_1, GARY_4 } from '../../../uikit/Colors/colors';
import Tour from '../../tour/tour';
import Highlighter from '../../tour/highlighter';
import { tourdataget } from '../../tour/store/middleware/middleware';
import Email from './Emailintegration';

import styles from './integrationscreen.module.css';

import {
  deleteOutlookMiddleware,
  deleteGoogleMiddleware,
} from './store/middleware/integrationmiddleware';
import CalenderConfig from './calendarconfigurations/calendarconfig';


export const googleApiKey = 'AIzaSyC4DoRmvUsYtcQWa2PkMzjEbf1BQpAnlok';

export const googleClientId =
  '836461204453-ukhhuh2fku1j0n0rep5cp1ops5mt1hei.apps.googleusercontent.com';

export const clientSecret = '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';

const IntegrationScreen = ({ }) => {
  const dispatch: AppDispatch = useDispatch();
  // const [showLogin, setLogin] = useState<any>(null);
  const [isMail, setMail] = useState('');
  // const [isChange, setChange] = useState(false);
  const [isLoginLoader, setLoginLoader] = useState(false);
  const [modelopen, setmodelopen] = useState(false);
  const [disconnectopen, setDisconnectopen] = useState(false);
  const [conflictopen, setConflictopen] = useState(false);

  const history = useHistory();
  const [step9, setstep9] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);
  const [rating, setrating] = useState(false);
  const [star, setstar] = useState(0);
  const [notes, setNotes] = useState('');
  const [error1, seterror1] = useState(false);
  const [error2, seterror2] = useState(false);

  const windowFeatures = 'left=100,top=100,width=320,height=320';
  const { email, events, Permission1, super_user, status, explore, user1, pipelineData } =
    useSelector(
      ({
        applicantIntegratemailReducers,
        permissionReducers,
        TourdataReducer,
        userProfileReducers,
        pipelinePageReducers
      }: RootState) => {
        return {
          email:
            applicantIntegratemailReducers.email !== undefined
              ? applicantIntegratemailReducers.email[0]?.email
              : '',
          mail: applicantIntegratemailReducers?.mail,
          events: applicantIntegratemailReducers?.events,
          Permission1: permissionReducers.Permission,
          super_user: permissionReducers.super_user,
          explore: TourdataReducer.is_first_login,
          status: TourdataReducer.is_steps,
          user1: userProfileReducers.user,
          pipelineData: pipelinePageReducers.pipeline,
        };
      },
    );

  // setting the helmat title
  document.title = 'Integrations';


  //toue useeffect
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep9(true);
    }
  }, [status]);

  useEffect(() => {
    /**
     *
     * After Granting Calendar Access, page redirects to this URL.
     * Google Calendar redirects "scope,code" params
     * Outlook Calendar redirects with "session_state,code,state" params
     *
     */

    localStorage.setItem('freeCheck', 'true');
    var url = new URL(window.location.href);

    if (url.searchParams.get('scope')) {
      // Google
      const code = url.searchParams.get('code');
      var username = localStorage.getItem('integrate');

      if (username === 'Mail') {
        dispatch(Google_Auth({ codeUrl: code })).then((res) => {
          dispatch(getEmail(undefined));
          sessionStorage.setItem('CommunicationDropdown', '1')
          history.push('/account_setting/integration');
          if (res.payload.data === false) {
            Toast('Email already exist.', 'MEDIUM', 'error');
          } else {
            if (res.payload.mess === false) {
              setmodelopen(!modelopen);
            } else {
              localStorage.setItem('integrationMailSuccess', 'true');
              window.location.reload();
            }
            // localStorage.setItem('integrationSuccess', 'true');
          }
        });
      } else {
        dispatch(googleCallbackMiddleware({ codeUrl: code })).then((res) => {
          dispatch(IntergratemailMiddleWare());
          sessionStorage.setItem('MyAccountDropdown', '1')
          history.push('/account_setting/integration');
          if (res.payload.data === false) {
            Toast('Email already exist.', 'MEDIUM', 'error');
          } else {
            if (res.payload.mess === false) {
              setmodelopen(!modelopen);
            } else {
              localStorage.setItem('integrationSuccess', 'true');
              window.location.reload();
            }
          }
        });
      }
    } else if (url.searchParams.get('session_state')) {
      // Outlook
      var user = localStorage.getItem('integrate');

      const access_urls = {
        code: url.searchParams.get('code'),
        state: url.searchParams.get('state'),
        session_state: url.searchParams.get('session_state'),
      };
      // setload(true);

      if (user === 'Mail') {
        dispatch(Outlook_Auth(access_urls)).then((res) => {
          dispatch(getEmail(undefined));
          sessionStorage.setItem('MyAccountDropdown', '1')
          history.push('/account_setting/integration');
          if (res.payload.status === false) {
            Toast('Email already exist.', 'MEDIUM', 'error');
          } else {
            if (res.payload.mess === false) {
              setmodelopen(!modelopen);
            } else {
              localStorage.setItem('integrationMailSuccess', 'true');
              window.location.reload();
            }
          }
        });
      } else {
        dispatch(outlookCallbackMiddleware(access_urls)).then((res) => {
          dispatch(IntergratemailMiddleWare());
          sessionStorage.setItem('MyAccountDropdown', '1')
          history.push('/account_setting/integration');
          if (res.payload.status === false) {
            Toast('Email already exist.', 'MEDIUM', 'error');
          } else {
            if (res.payload.mess === false) {
              setmodelopen(!modelopen);
            } else {
              localStorage.setItem('integrationSuccess', 'true');
              window.location.reload();
            }
            //  Toast('Outlook calendar Integrated Successfully', 'MEDIUM');
          }
        });
      }
    }
  }, []);

  const [tost, settost] = useState(false);

  const googleAuthHandler = () => {
    setLoginLoader(true);
    dispatch(googleCallApiMiddleware()).then((res) => {
      setLoginLoader(false);
      localStorage.setItem('integrate', 'calender');
      window.location.href = res.payload.url;
    });
  };
  const integrationSuccess = localStorage.getItem('integrationSuccess');
  const msAuthHandler = () => {
    setLoginLoader(true);

    dispatch(outlookCallApiMiddleware()).then((res) => {
      if (res.payload.success === true) {
        setLoginLoader(false);
        localStorage.setItem('integrate', 'calender');
        window.location.href = res.payload.authorization_url;
      }
    });
  };

  // google radio button function
  const handleGoogleRadio = () => {
    googleAuthHandler();
    setActive(1);
    setIsGoogle(1);

    if (connected && isGoogle === 0) {
      setConnected(0);
      setIsGoogle(1);
    }
    // setConflictopen(true)
  };
  const disconnectfun = () => {
    setmodelopen(!modelopen);

    // Toast('Details not saved', 'LONG', 'error');
  };

  // outlook radio button function
  const handleOutlookRadio = () => {
    msAuthHandler();
    setActive(1);
    setIsGoogle(0);
    if (connected && isGoogle === 1) {
      setConnected(0);
      setIsGoogle(0);
    }
    // setConflictopen(true)
    // Toast('Details saved successfully', 'LONG');
  };

  // google disconnect button function
  const handleDisconnectGoogle = () => {
    if (events === true) {
      setDisconnectopen(true);
    } else {
      dispatch(deleteGoogleMiddleware()).then((res) => {
        if (res.payload.delete === true) {
          setActive(0);
          setIsGoogle(2);
          setConnected(0);
        }
        setmodelopen(!modelopen);

        Toast('Google calendar disconnected successfully.', 'SHORT', 'error');
      });
    }
  };
  const handleDisconnectGoogleCheck = () => {
    dispatch(deleteGoogleMiddleware()).then((res) => {
      if (res.payload.delete === true) {
        setActive(0);
        setIsGoogle(2);
        setConnected(0);
      }
      setmodelopen(!modelopen);

      Toast('Google calendar disconnected successfully.', 'SHORT', 'error');
    });
  };

  // outlook disconnect button function
  const handleDisconnectOutlook = () => {
    if (events === true) {
      setDisconnectopen(true);
    } else {
      dispatch(deleteOutlookMiddleware()).then((res) => {
        if (res.payload.delete === true) {
          setActive(0);
          setIsGoogle(2);
          setConnected(0);
        }
        setmodelopen(!modelopen);

        Toast('Outlook calendar disconnected successfully.', 'SHORT', 'error');
      });
    }
  };
  const handleDisconnectCheck = () => {
    // setDisconnectopen(false);
    dispatch(deleteOutlookMiddleware()).then((res) => {
      if (res.payload.delete === true) {
        setActive(0);
        setIsGoogle(2);
        setConnected(0);
      }
      setmodelopen(!modelopen);

      Toast('Outlook calendar disconnected successfully.', 'SHORT', 'error');
    });
  };

  const [isGoogle, setIsGoogle] = useState(0);
  const [active, setActive] = useState(0);
  const [connected, setConnected] = useState(0);
  const checkAuth = () => {
    dispatch(IntergratemailMiddleWare());
    dispatch(checkAuthMiddleware()).then((res) => {
      if (res.payload.status === true) {
        if (res.payload.account === 'google') {
          setIsGoogle(1);
          setConnected(1);
        } else {
          setIsGoogle(0);
          setConnected(1);
        }
        setActive(1);
      } else {
        setActive(0);
        setIsGoogle(2);
      }
    });
  };
  useEffect(() => {
    // Name('Integrations');
    checkAuth();
  }, []);

  const loaderupdate = (val) => {
    setLoginLoader(val);
  };

  function outlookconfig(): void {
    throw new Error('Function not implemented.');
  }
  function Configuration() {
    setConflictopen(true);
  }

  function CloseConfiguration() {
    setConflictopen(false);
  }
  const handleCancel = () => {
    setmodelopen(false);
    setDisconnectopen(false);
  };
  const handleRatingChange = (newRating) => {
    setstar(newRating);
  };
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const back_navigation = () => {
    history.push('/account_setting');
  };

  return (
    <>
      <Flex row className={styles.ribbon} marginBottom={5} between>
        <Flex marginTop={9} marginLeft={8}>
          <Text size={16} bold color="theme">
            Integrations
          </Text>
        </Flex>
        <Flex>
          <div className={styles.triangle}></div>
        </Flex>
      </Flex>
      <Flex row>
        <Flex row marginTop={5} marginLeft={16}>
          <Flex
            style={{
              fontSize: '15px',
              color: '#581845',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => back_navigation()}
          >
            Account Settings
          </Flex>
          <Flex marginTop={7} marginLeft={5}>
            <SvgRight fill={'#581845'}></SvgRight>
          </Flex>
          <Flex marginLeft={5} style={{ fontSize: '15px', fontWeight: 'bold' }}>
            Integrations
          </Flex>
        </Flex>
      </Flex>
      <Flex className={styles.overAll}>
        {status && (
          <Highlighter
            left={"38px"}
            top={"462px"}
            text={"Integration"}
          />
        )}
        {step9 &&
          <Tour
            process={() => {
              if (Permission1.includes('manage_account_settings') && Permission1.includes('create_post') &&
                Permission1.includes('bulkImport_candidates') && Permission1.includes('talent_sourcing')
              ) {
                return "9/9";
              }
              else if (Permission1.includes('manage_account_settings') && Permission1.includes('create_post') &&
                Permission1.includes('bulkImport_candidates')) {
                return "8/8";
              }
              else if (Permission1.includes('manage_account_settings') && Permission1.includes('talent_sourcing') &&
                Permission1.includes('bulkImport_candidates')) {
                return "8/8";
              }
              else if (Permission1.includes('talent_sourcing') && Permission1.includes('create_post') &&
                Permission1.includes('bulkImport_candidates')) {
                return "8/8";
              }
              else if (Permission1.includes('manage_account_settings') && Permission1.includes('create_post') &&
                Permission1.includes('talent_sourcing')) {
                return "7/7";
              }
              else if (Permission1.includes('manage_account_settings') && Permission1.includes('create_post')) {
                return "6/6";
              }
              else if (Permission1.includes('bulkImport_candidates') && Permission1.includes('talent_sourcing')) {
                return "7/7";
              }
              else if (Permission1.includes('bulkImport_candidates') && Permission1.includes('create_post')) {
                return "7/7";
              }
              else if (Permission1.includes('manage_account_settings') && Permission1.includes('talent_sourcing')) {
                return "6/6";
              }
              else if (Permission1.includes('manage_account_settings') && Permission1.includes('bulkImport_candidates')) {
                return "7/7";
              }
              else if (Permission1.includes('create_post') && Permission1.includes('talent_sourcing')) {
                return "6/6";
              }
              else if (Permission1.includes('create_post')) {
                return "5/5";
              }
              else if (Permission1.includes('talent_sourcing')) {
                return "5/5";
              }
              else if (Permission1.includes('bulkImport_candidates')) {
                return "6/6";
              }
              else if (Permission1.includes('manage_account_settings')) {
                return "5/5";
              }
              else {
                return "4/4";
              }
              return null;
            }
            }
            previousonclick={() => {
              setstep9(false)
              if (super_user) {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0')
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem('wk_id', pipelineData[0]?.wk_id.toString());
                history.push("/account_setting/hiring_workflow");
              }
              else {
                if (Permission1.includes('manage_account_settings')) {
                  sessionStorage.setItem('JobsDropdown', '1');
                  sessionStorage.setItem('ViewJobsDropdown', '0');
                  sessionStorage.setItem('CandidateDropdown', '0');
                  sessionStorage.setItem('CommunicationDropdown', '0');
                  sessionStorage.setItem('BrandDropdown', '0');
                  sessionStorage.setItem('MyAccountDropdown', '0');
                  sessionStorage.setItem('SourcingCandidateDropdown', '0')
                  sessionStorage.setItem('template', '1');
                  sessionStorage.setItem('pipeline', '1');
                  sessionStorage.setItem('wk_id', pipelineData[0]?.wk_id.toString());
                  history.push("/account_setting/hiring_workflow");
                }
                else {
                  sessionStorage.setItem('JobsDropdown', '1');
                  sessionStorage.setItem('ViewJobsDropdown', '0');
                  sessionStorage.setItem('CandidateDropdown', '0');
                  sessionStorage.setItem('CommunicationDropdown', '0');
                  sessionStorage.setItem('BrandDropdown', '0');
                  sessionStorage.setItem('MyAccountDropdown', '0');
                  sessionStorage.setItem('SourcingCandidateDropdown', '0')
                  sessionStorage.setItem('template', '1');
                  sessionStorage.setItem('pipeline', '1');
                  sessionStorage.setItem('wk_id', pipelineData[0]?.wk_id.toString());
                  history.push("/account_setting/hiring_workflow");
                }
              }
            }}
            finish
            nextonclick={() => {
              setstep9(false)
              setcurrentindex(9)
              setLoginLoader(true)
              dispatch(tourdataget({ skip_id: 9, restart_status: false, explore: 0 }))
                .then((res) => {
                  if (res) {
                    setLoginLoader(false)
                    setrating(true)
                    sessionStorage.setItem('JobsDropdown', '0');
                    sessionStorage.setItem('ViewJobsDropdown', '0');
                    sessionStorage.setItem('CandidateDropdown', '0');
                    sessionStorage.setItem('CommunicationDropdown', '0');
                    sessionStorage.setItem('BrandDropdown', '0');
                    sessionStorage.setItem('MyAccountDropdown', '1');
                    sessionStorage.setItem('SourcingCandidateDropdown', '0')
                    sessionStorage.setItem('template', '0');
                    sessionStorage.setItem('pipeline', '0');
                    sessionStorage.setItem('wk_id', '0');
                    history.push("/account_setting/profiles")
                  }
                })

            }}
            borderTop
            previous
            types={"arrowLeft"}
            top={"360px"}
            left={"205px"}
            text={'<div style="font-weight: bold;">Imagine an integrated workspace with \'Calendar\' and \'Email\'.</div> <div style="margin-top:10px">Coordinate <div style="font-weight: bold; display: contents">interviews</div> and <div style="font-weight: bold; display: contents">communicate</div> with candidates seamlessly, directly within <div style="font-weight: bold; display: contents">Zita ATS</div>.<div> <div style="margin-top:10px">It’s about making the most of your time, without ever leaving the platform.</div>'}
          // text={"Imagine an integrated workspace with 'Calendar' and 'Email'. Coordinate interviews and communicate with candidates seamlessly, directly within Zita ATS. It’s about making the most of your time, without ever leaving the platform."}
          />}
        {/* {rating && (
          <Modal open={rating}>
            <Flex className={styles.backgroundstyle}>
              <Text
                size={16}
                bold
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                Review & Comments
              </Text>
              <Flex
                style={{ justifyContent: 'center', marginTop: '5px' }}
                className={styles.ratingStar}
              >
                <StarsRating
                  count={5}
                  value={star}
                  onChange={handleRatingChange}
                />
              </Flex>
              {error2 && star === 0 && (
                <Text color="error">This field is required.</Text>
              )}
              <Flex marginTop={5}>
                <textarea
                  style={{
                    height: '100px',
                    padding: '5px',
                    width: '280px',
                    border: '1px solid #D7C7D2',
                    borderRadius: '5px',
                  }}
                  value={notes}
                  onChange={handleNotesChange}
                  placeholder="Enter your notes here..."
                />
              </Flex>
              {error1 && notes.trim().length === 0 && (
                <Text color="error">This field is required.</Text>
              )}
              <Flex
                row
                center
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                <Button
                  types="secondary"
                  onClick={() => {
                    setrating(false);
                    history.push("/account_setting/profile")
                    setLoginLoader(true)
                    sessionStorage.setItem('JobsDropdown', '0');
                    sessionStorage.setItem('ViewJobsDropdown', '0');
                    sessionStorage.setItem('CandidateDropdown', '0');
                    sessionStorage.setItem('CommunicationDropdown', '0');
                    sessionStorage.setItem('BrandDropdown', '0');
                    sessionStorage.setItem('MyAccountDropdown', '1');
                    dispatch(tourdataget({ skip_id: 9, restart_status: false, explore: 0 }))
                      .then((res) => {
                        if (res) {
                          setLoginLoader(false)
                          history.push("/account_setting/profile")
                        }
                      })
                  }}
                >
                  Cancel
                </Button>

                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    if (notes.trim().length === 0 && star === 0) {
                      seterror1(true);
                      seterror2(true);
                    } else if (notes.trim().length === 0) {
                      seterror1(true);
                    } else if (star === 0) {
                      seterror2(true);
                    } else {
                      if (notes.trim().length !== 0 && star !== 0) {
                        setLoginLoader(true);
                        setrating(false);
                        dispatch(
                          tourdataget({
                            notes: notes,
                            rating: star,
                          }),
                        ).then((res) => {
                          if (res) {
                            setLoginLoader(false);
                            history.push("/account_setting/profile")
                          }
                        });
                      }
                    }
                  }}
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )} */}
        <Email loaderupdate={loaderupdate} />
        {isLoginLoader && <Loader />}
        <Flex columnFlex>
          <Flex row between>
            <Flex>
              <Text size={14} bold>
                Calendar Integration
              </Text>
              <Text>
                Integrate your calendar with zita to schedule your meetings
              </Text>
            </Flex>
            <Flex>
              {active ? (
                <>
                  <Flex row end>
                    <ActionsButton
                      Configuration={Configuration}
                      connected={connected}
                      active={active}
                    />
                  </Flex>
                </>
              ) : (
                ''
              )}
            </Flex>
          </Flex>

          <Flex row marginTop={10}>
            <Flex flex={3} marginRight={20}>
              {connected === 1 && active === 1 && isGoogle === 0 ? (
                <Card className={styles.selectCard}>
                  <Flex end style={{ position: 'absolute', right: '10px' }}>
                    <SvgTick />
                  </Flex>
                  <Flex row start className={styles.cardHeader}>
                    {/* <SvgOutlookMail /> */}
                    <SvgOutlookcalendar />
                    {integrationSuccess === 'true' &&
                      Toast(
                        'Outlook calendar integrated successfully.',
                        'SHORT',
                      )}

                    {integrationSuccess === 'true' &&
                      localStorage.removeItem('integrationSuccess')}
                    <Text bold size={14} style={{ marginLeft: '10px' }}>
                      Outlook Calendar
                    </Text>
                  </Flex>

                  <Text style={{ marginTop: '10px' }}>Connected as</Text>
                  <Text color="theme" style={{ marginTop: '1px' }}>
                    {email}
                  </Text>

                  <Flex className={styles.borderbottom1}></Flex>
                  <Button
                    className={styles.btn}
                    onClick={() => disconnectfun()}
                  >
                    <Flex row center style={{ cursor: 'pointer' }}>
                      <SvgEdit width={12} height={12} fill="#581845" />
                      <Text color="theme" bold style={{ marginLeft: '5px' }}>
                        Edit Configuration
                      </Text>
                    </Flex>
                  </Button>
                </Card>
              ) : (
                // Calendar Integration outlook mail out box
                <Card className={styles.cardStruture}>
                  <Flex row start className={styles.cardHeader}>
                    <SvgOutlookcalendar></SvgOutlookcalendar>
                    {/* {  Toast('Outlook google Integrated Successfully', 'MEDIUM')   } */}
                    <Text bold size={14} style={{ marginLeft: '10px' }}>
                      Outlook Calendar
                    </Text>
                  </Flex>

                  <Text style={{ marginTop: '10px' }}>
                    Connect your calendar with Outlook calendar service.
                  </Text>
                  <Flex className={styles.borderbottom1}></Flex>

                  {connected === 1 && active === 1 && isGoogle === 1 ? (
                    <Button
                      className={styles.btn}
                      onClick={() => handleOutlookRadio()}
                      disabled
                    >
                      <Text style={{ color: '#581845' }} bold>
                        Connect With Outlook
                      </Text>
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      onClick={() => handleOutlookRadio()}
                    >
                      <Text style={{ color: '#581845' }} bold>
                        Connect With Outlook
                      </Text>
                    </Button>
                  )}
                </Card>
              )}
            </Flex>
            <Flex flex={3}>
              {connected === 1 && active === 1 && isGoogle === 1 ? (
                <>
                  <Card className={styles.selectCard}>
                    {integrationSuccess === 'true' &&
                      Toast(
                        'Google calendar integrated successfully.',
                        'SHORT',
                      )}

                    {integrationSuccess === 'true' &&
                      localStorage.removeItem('integrationSuccess')}
                    <Flex end style={{ position: 'absolute', right: '10px' }}>
                      <SvgTick />
                    </Flex>
                    {/* {      Toast('Google Calender Integrated Successfully', 'MEDIUM') } */}
                    <Flex row start className={styles.cardHeader}>
                      {/* <SvgOutlookMail /> */}
                      <SvgGooglecalendar></SvgGooglecalendar>

                      <Text
                        // color="theme"
                        bold
                        size={14}
                        style={{ marginLeft: '10px' }}
                      >
                        Google Calendar
                      </Text>
                    </Flex>

                    <Text style={{ marginTop: '10px' }}>Connected as</Text>
                    <Text color="theme" style={{ marginTop: '1px' }}>
                      {email}
                    </Text>

                    {/* {modelopen===false&&
                 Toast('Google calendar Integrated Successfully', 'MEDIUM')
                } */}
                    {/* google calendar outview box edit*/}
                    <Flex className={styles.borderbottom1}></Flex>
                    <Button
                      className={styles.btn}
                      onClick={() => disconnectfun()}
                    >
                      <Flex row center style={{ cursor: 'pointer' }}>
                        <SvgEdit width={12} height={12} fill={'#581845'} />
                        <Text color="theme" bold style={{ marginLeft: '5px' }}>
                          Edit Configuration
                        </Text>
                      </Flex>
                    </Button>
                  </Card>
                </>
              ) : (
                <Card className={styles.cardStruture}>
                  <Flex row start className={styles.cardHeader}>
                    <SvgGooglecalendar></SvgGooglecalendar>

                    <Text bold size={14} style={{ marginLeft: '10px' }}>
                      Google Calendar
                    </Text>
                  </Flex>

                  <Text style={{ marginTop: '10px' }}>
                    Connect your calendar with Google calendar service.
                  </Text>
                  <Flex className={styles.borderbottom1}></Flex>
                  {connected === 1 && active === 1 && isGoogle === 0 ? (
                    <Button
                      className={styles.btn}
                      onClick={() => handleGoogleRadio()}
                      disabled
                    >
                      <Text style={{ color: '#581845' }} bold>
                        Connect With Google
                      </Text>
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      onClick={() => handleGoogleRadio()}
                    >
                      <Text style={{ color: '#581845' }} bold>
                        Connect With Google
                      </Text>
                    </Button>
                  )}
                </Card>
              )}
            </Flex>
            <Flex flex={8}></Flex>
          </Flex>
        </Flex>

        <Modal open={modelopen}>
          <Flex className={styles.editmodal}>
            {connected === 1 && active === 1 && isGoogle === 0 ? (
              <>
                {disconnectopen === true ? (
                  <>
                    {/* <Flex end onClick={() => setmodelopen(!modelopen)}>
                    <SvgClose width={10} height={10} fill={'#888888'} />
                  </Flex> */}
                    <Flex>
                      <Text
                        size={13}
                        color="black2"
                        style={{ marginTop: '5px' }}
                      >
                        If you disconnect, your slotters will be inactivated and
                        candidate will not be able to pick slots for the
                        interviews.
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <Flex>
                    <Flex row center between>
                      <Flex row center>
                        <SvgEdit fill={'#333333'} width={12} height={12} />
                        <Text size={14} bold style={{ marginLeft: '5px' }}>
                          Edit Configuration
                        </Text>
                      </Flex>
                      <Flex end onClick={handleCancel}>
                        <SvgClose width={10} height={10} fill={'#888888'} />
                      </Flex>
                    </Flex>
                    <div className={styles.borderbottom}> </div>
                    <Text size={13} style={{ marginTop: '10px' }}>
                      You have connected your calendar with Outlook calendar
                      service.
                    </Text>
                    <Flex row start className={styles.modelheadder}>
                      <SvgOutlookcalendar></SvgOutlookcalendar>
                      <Card className={styles.outlookEmailcard}>
                        <Flex style={{ padding: '10px' }}>
                          <Flex>Connected as</Flex>
                          <Flex>
                            <Text color="theme">{email}</Text>
                          </Flex>
                        </Flex>
                      </Card>
                    </Flex>
                    <Flex style={{ marginTop: '20px' }}>
                      Would you like to do any of the following actions?
                    </Flex>
                    <Flex
                      style={{
                        borderBottom: '1px solid #c3c3c3',
                        margin: '20px 0px',
                      }}
                    ></Flex>
                  </Flex>
                )}
                <Flex>
                  {disconnectopen === true ? (
                    <Flex row end marginTop={10}>
                      <Button
                        className={styles.cancelBtn}
                        onClick={handleCancel}
                        style={{ marginRight: '8px' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={styles.okButton}
                        onClick={() => handleDisconnectCheck()}
                      >
                        OK
                      </Button>
                    </Flex>
                  ) : (
                    <Flex end>
                      <span>
                        <Button
                          onClick={() => msAuthHandler()}
                          style={{
                            paddingRight: '24px',
                            paddingLeft: '24px',
                          }}
                        >
                          Change
                        </Button>
                        <Button
                          className={styles.disconnectbtn}
                          types="secondary"
                          onClick={() => handleDisconnectOutlook()}
                        >
                          Disconnect
                        </Button>
                      </span>
                    </Flex>
                  )}
                </Flex>
              </>
            ) : (
              ''
            )}

            {connected === 1 && active === 1 && isGoogle === 1 ? (
              <>
                {disconnectopen === true ? (
                  <>
                    {/* <Flex end onClick={() => setmodelopen(!modelopen)}>
                    <SvgClose width={10} height={10} fill={'#888888'} />
                  </Flex> */}
                    <Flex>
                      <Text
                        color="black2"
                        size={13}
                        style={{ marginTop: '5px' }}
                      >
                        If you disconnect, your slotters will be inactivated and
                        candidate will not be able to pick slots for the
                        interviews.
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <Flex>
                    {/* <Flex className={styles.borderbottom}></Flex> */}
                    <Flex row center between>
                      <Flex row center>
                        <SvgEdit fill={'#333333'} width={12} height={12} />
                        <Text size={14} bold style={{ marginLeft: '5px' }}>
                          Edit Configuration
                        </Text>
                      </Flex>
                      <Flex end onClick={() => setmodelopen(!modelopen)}>
                        <SvgClose width={10} height={10} fill={'#888888'} />
                      </Flex>
                    </Flex>
                    <div className={styles.borderbottom}></div>
                    <Text size={13} style={{ marginTop: '10px' }}>
                      You have connected your calendar with Google calendar
                      service.
                    </Text>
                    <Flex row start className={styles.modelheadder}>
                      <SvgGooglecalendar></SvgGooglecalendar>
                      <Card className={styles.outlookEmailcard}>
                        <Flex style={{ padding: '10px' }}>
                          <Flex>Connected as</Flex>
                          <Flex>
                            <Text color="theme">{email}</Text>
                          </Flex>
                        </Flex>
                      </Card>
                    </Flex>
                    <Flex style={{ marginTop: '20px' }}>
                      Would you like to do any of the following actions?
                    </Flex>
                    <Flex
                      style={{
                        borderBottom: '1px solid #c3c3c3',
                        margin: '20px 0px',
                      }}
                    ></Flex>
                  </Flex>
                )}
                <Flex>
                  {disconnectopen === true ? (
                    <Flex row end marginTop={10}>
                      <Button
                        className={styles.cancelBtn}
                        onClick={handleCancel}
                        style={{ marginRight: '8px' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={styles.okButton}
                        onClick={() => handleDisconnectGoogleCheck()}
                      >
                        OK
                      </Button>
                    </Flex>
                  ) : (
                    <Flex end>
                      <span>
                        <Button
                          onClick={() => googleAuthHandler()}
                          style={{
                            paddingRight: '24px',
                            paddingLeft: '24px',
                          }}
                        >
                          Change
                        </Button>

                        <Button
                          className={styles.disconnectbtn}
                          types="secondary"
                          onClick={() => handleDisconnectGoogle()}
                        >
                          Disconnect
                        </Button>
                      </span>
                    </Flex>
                  )}
                </Flex>
              </>
            ) : (
              ''
            )}
          </Flex>
        </Modal>

        {connected === 1 && active === 1 ? (
          <Modal open={conflictopen}>
            <CalenderConfig
              isGoogle={isGoogle}
              email={email}
              CloseConfiguration={CloseConfiguration}
            />
          </Modal>
        ) : (
          ''
        )}
      </Flex>
    </>
  );
};

interface ActionButtonProps {
  Configuration: () => void;
  connected: number;
  active: number;
}

const ActionsButton: React.FC<ActionButtonProps> = ({
  Configuration,
  connected,
  active,
}) => {
  function BtnClick() {
    // alert("BtnClick")
  }
  return (
    <Flex>
      <Dropdown>
        <Dropdown.Toggle
          style={{
            borderColor: 'unset',
            backgroundColor: 'unset',
            boxShadow: 'none',
            cursor: 'pointer',
            padding: '0px',
          }}
          id="dropdown-basic"
        // onClick={BtnClick }
        >
          <Button onClick={Configuration}>
            <Flex row center className={styles.pointer}>
              <Text bold style={{ cursor: 'pointer', color: '#fff' }}>
                Calendar Configurations
              </Text>
            </Flex>
          </Button>

          {/* <SvgDotMenu fill={'#black'} height={14} width={14} /> */}
        </Dropdown.Toggle>
        {/* { connected === 1 && active === 1 ? ( */}
        {/* <Dropdown.Menu>
          <Dropdown.Item onClick={Configuration}>
            <Flex row center className={styles.pointer}>
              <Text style={{ cursor: 'pointer' }}>Calendar Configurations</Text>
            </Flex>
          </Dropdown.Item>
        </Dropdown.Menu> */}
      </Dropdown>
    </Flex>
  );
};

export default IntegrationScreen;
