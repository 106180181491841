import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import Loader from '../../../uikit/Loader/Loader';
import Button from '../../../uikit/Button/Button';
import Flex from '../../../uikit/Flex/Flex';
import Text from '../../../uikit/Text/Text';
import { getFocus, isEmpty } from '../../../uikit/helper';
import LinkWrapper from '../../../uikit/Link/LinkWrapper';
import Card from '../../../uikit/Card/Card';
import Toast from '../../../uikit/Toast/Toast';
import SvgRight from '../../../icons/SvgRight';
import {
  isValidURL,
  mediaPath,
  nameRegex,
  THIS_FIELD_REQUIRED,
} from '../../constValue';
import ExpandTile from '../../../uikit/ExpandTile';
import useUnsavedChangesWarning from '../../common/useUnsavedChangesWarning';
import { logoMiddleWare } from '../store/middleware/accountsettingmiddleware';
import { tourdataget } from '../../tour/store/middleware/middleware';
import Tour from '../../tour/tour';
import Highlighter from '../../tour/highlighter';
import SvgInfo from '../../../icons/SvgInfo';
import BannerSetup, { BannerSetupTitle } from './BannerSetup';
import CareersPageURL, { CareerTitle } from './CareersPageURL';
import FooterSetup, { FooterSetupTitle } from './FooterSetup';
import HeaderSetup, { HeaderSetupTitle } from './HeaderSetup';
import PageSetup, { PageSetupTitle } from './PageSetup';
import styles from './buildyourcareerpageScreen.module.css';
import { formikFormTypes } from './formikTypes';
import {
  buildCareerMiddleWare,
  buildCareerPostMiddleWare,
  urlVerificationMiddleWare,
} from './store/middleware/buildyourcareerpagemiddleware';


type Props = {

};

const initial: formikFormTypes = {
  pagaeUrl: '',
  pageFontStyle: 'Helvetica',
  pageFontSize: '14',
  headerFontSize: '14',
  headerColor: { hex: '#fffff' },
  fontColor: { hex: '#333333' },
  menu1: '',
  menu1Url: 'https://',
  menu2: '',
  menu2Url: 'https://',
  menu3: '',
  menu3Url: 'https://',
  bannerHeadingText: '',
  bannerHeadingFontSize: '20',
  bannerTextFontSize: '14',
  bannerText: '',
  btnColor: { hex: '#581845' },
  footerColor: { hex: '#fffff' },
  aboutText: '',
};

// formik validation
const validationSchema = Yup.object().shape({
  pagaeUrl: Yup.string().required(THIS_FIELD_REQUIRED),
});

const BuildYourCareerPageScreen = ({
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [isReloadCareer, setReloadCareer] = useState(false);
  const [isInput, setInput] = useState(false);
  const [isFile, setFile] = useState<any>({});
  const [isFilelogo, setFilelogo] = useState<any>({});
  const [isCareerImgClick, setCareerImgClick] = useState(true);
  const [isPageSetUpImgClick, setPageSetUpImgClick] = useState(false);
  const [isHeaderSetUpImgClick, setHeaderSetUpImgClick] = useState(false);
  const [isBannerSetUpImgClick, setBannerSetUpImgClick] = useState(false);
  const [isFooterSetUpImgClick, setFooterSetUpImgClick] = useState(false);
  const [isSubmitLoader, setSubmitLoader] = useState(false);
  const [isBtnDisable, setBtnDisable] = useState(true);
  const [isUrlError, setUrlError] = useState(false);
  const { routerPrompt, onDirty, onPristine } = useUnsavedChangesWarning();

  const [step3, setstep3] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);

  const [tileState, setTileState] = useState<{
    careersPage: boolean;
    pageSetup: boolean;
    headerSetup: boolean;
    bannerSetup: boolean;
    buttonAndFooterSetup: boolean;
  }>({
    careersPage: true,
    pageSetup: false,
    headerSetup: false,
    bannerSetup: false,
    buttonAndFooterSetup: false,
  });

  // setting the helmat title
  document.title = 'Build Careers Page';

  // initial api call
  useEffect(() => {
    // Name('Build Your Careers Page');
    dispatch(buildCareerMiddleWare()).then((res) => {
      if (
        res.payload.career_page &&
        !isEmpty(res.payload.career_page.career_page_url)
      ) {
        setInput(false);
      } else {
        setInput(true);
      }
    });
  }, []);

  const {
    isLoading,
    career_page,
    company_detail,
    Permission1,
    super_user,
    status,
    explore,
    career_page_exists_build,
    isLoadingCareer,
    user,
    pipelineData,
  } = useSelector(
    ({
      buildCareerPageReducers,
      permissionReducers,
      TourdataReducer,
      userProfileReducers,
      pipelinePageReducers,
    }: RootState) => {
      return {
        isLoading: buildCareerPageReducers.isLoading,
        isLoadingCareer: buildCareerPageReducers.isLoading,
        career_page: buildCareerPageReducers.career_page,
        company_detail: buildCareerPageReducers.company_detail,
        Permission1: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
        career_page_url: permissionReducers.carrer_page,
        career_page_exists_build: buildCareerPageReducers.career_page_exists,
        user: userProfileReducers.user,
        pipelineData: pipelinePageReducers.pipeline,
      };
    },
  );

  // tour useeffect
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep3(true);
      sessionStorage.setItem('JobsDropdown', '0');
      sessionStorage.setItem('ViewJobsDropdown', '0');
      sessionStorage.setItem('CandidateDropdown', '0');
      sessionStorage.setItem('CommunicationDropdown', '0');
      sessionStorage.setItem('BrandDropdown', '1');
      sessionStorage.setItem('MyAccountDropdown', '0');
    }
  }, [status]);

  const imagefunction = () => {
    const formData = new FormData();
    if (isFilelogo.file !== undefined) {
      formData.append('logo', isFilelogo.file);
    } else {
      formData.append('logo', '');
    }

    dispatch(
      logoMiddleWare({
        formData,
      }),
    ).then((res: any) => {
      // dispatch(dashBoardMiddleWare());
      // dispatch(buildCareerMiddleWare())
    });
  };
  // formik submit
  const handleSubmit = (values: formikFormTypes) => {
    setReloadCareer(false);
    setSubmitLoader(true);
    const formData = new FormData();
    localStorage.setItem('career_page_url', values?.pagaeUrl);
    formData.append('career_page_url', values.pagaeUrl);

    formData.append('page_font', values.pageFontStyle);
    formData.append('page_font_size', values.pageFontSize);

    formData.append('header_font_size', values.headerFontSize);
    formData.append('header_color', values.headerColor.hex);
    formData.append('font_color', values.fontColor.hex);
    formData.append('menu_1', values.menu1);
    if (values.menu1Url !== 'https://') {
      formData.append('menu_1_url', values.menu1Url);
    }
    formData.append('menu_2', values.menu2);
    if (values.menu2Url !== 'https://') {
      formData.append('menu_2_url', values.menu2Url);
    }
    formData.append('menu_3', values.menu3);
    if (values.menu3Url !== 'https://') {
      formData.append('menu_3_url', values.menu3Url);
    }

    formData.append('banner_header_text', values.bannerHeadingText);
    formData.append('banner_font_size', values.bannerTextFontSize);
    formData.append('banner_heading_size', values.bannerHeadingFontSize);
    formData.append('banner_text', values.bannerText);
    if (isFile.file !== undefined) {
      formData.append('banner_img', isFile.file);
    }

    formData.append('about_us', values.aboutText);
    formData.append('button_color', values.btnColor.hex);
    formData.append('footer_color', values.footerColor.hex);

    imagefunction();

    dispatch(
      buildCareerPostMiddleWare({
        formData,
      }),
    ).then((res) => {
      setSubmitLoader(false);
      if (res.payload.success) {
        setInput(false);
        setBtnDisable(true);
        dispatch(buildCareerMiddleWare());
        Toast('Details saved successfully.', 'LONG');
        onPristine();
      } else {
        Toast('Details not saved.', 'LONG', 'error');
      }
    });
  };

  // formik validation
  const handleValid = (values: formikFormTypes) => {
    const errors: Partial<formikFormTypes> = {};
    if (!isEmpty(values.pagaeUrl) && !nameRegex.test(values.pagaeUrl)) {
      errors.pagaeUrl = '';
    }
    if (!isEmpty(values.pagaeUrl) && isUrlError) {
      errors.pagaeUrl = '';
    }
    // header setup
    if (
      !isEmpty(values.headerColor.hex) &&
      values.headerColor.hex.length > 20
    ) {
      errors.headerColor = { hex: '' };
    }
    if (isEmpty(values.headerColor.hex)) {
      errors.headerColor = { hex: THIS_FIELD_REQUIRED };
    }
    if (!isEmpty(values.fontColor.hex) && values.fontColor.hex.length > 20) {
      errors.fontColor = { hex: '' };
    }
    if (isEmpty(values.fontColor.hex)) {
      errors.fontColor = { hex: THIS_FIELD_REQUIRED };
    }
    if (!isEmpty(values.menu1) && values.menu1.length > 50) {
      errors.menu1 = '';
    }
    if (
      isEmpty(values.menu1) &&
      !isEmpty(values.menu1Url) &&
      values.menu1Url !== 'https://'
    ) {
      errors.menu1 = '';
    }
    if (!isEmpty(values.menu2) && values.menu2.length > 50) {
      errors.menu2 = '';
    }
    if (
      isEmpty(values.menu2) &&
      !isEmpty(values.menu2Url) &&
      values.menu2Url !== 'https://'
    ) {
      errors.menu2 = '';
    }
    if (!isEmpty(values.menu3) && values.menu3.length > 50) {
      errors.menu3 = '';
    }
    if (
      isEmpty(values.menu3) &&
      !isEmpty(values.menu3Url) &&
      values.menu3Url !== 'https://'
    ) {
      errors.menu3 = '';
    }
    if (
      !isEmpty(values.menu1) &&
      isValidURL(values.menu1Url) === false &&
      values.menu1Url !== 'https://'
    ) {
      errors.menu1Url = '';
    }
    if (!isEmpty(values.menu1) && values.menu1Url === 'https://') {
      errors.menu1Url = '';
    }

    if (
      !isEmpty(values.menu2) &&
      isValidURL(values.menu2Url) === false &&
      values.menu2Url !== 'https://'
    ) {
      errors.menu2Url = '';
    }
    if (!isEmpty(values.menu2) && values.menu2Url === 'https://') {
      errors.menu2Url = '';
    }

    if (
      !isEmpty(values.menu3) &&
      isValidURL(values.menu3Url) === false &&
      values.menu3Url !== 'https://'
    ) {
      errors.menu3Url = '';
    }
    if (!isEmpty(values.menu3) && values.menu3Url === 'https://') {
      errors.menu3Url = '';
    }
    // BannerSetup
    if (
      !isEmpty(values.bannerHeadingText) &&
      values.bannerHeadingText.length > 50
    ) {
      errors.bannerHeadingText = '';
    }
    if (!isEmpty(values.bannerText) && values.bannerText.length <= 150) {
      errors.bannerText = '';
    }
    if (!isEmpty(values.bannerText) && values.bannerText.length > 500) {
      errors.bannerText = '';
    }

    // FooterSetup

    if (!isEmpty(values.btnColor.hex) && values.btnColor.hex.length > 20) {
      errors.btnColor = { hex: '' };
    }
    if (isEmpty(values.btnColor.hex)) {
      errors.btnColor = { hex: THIS_FIELD_REQUIRED };
    }
    if (
      !isEmpty(values.footerColor.hex) &&
      values.footerColor.hex.length > 20
    ) {
      errors.footerColor = { hex: '' };
    }
    if (isEmpty(values.footerColor.hex)) {
      errors.footerColor = { hex: THIS_FIELD_REQUIRED };
    }
    if (!isEmpty(values.aboutText) && values.aboutText.length <= 150) {
      errors.aboutText = '';
    }
    if (!isEmpty(values.aboutText) && values.aboutText.length > 500) {
      errors.aboutText = '';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit: (value) => handleSubmit(value),
    validate: handleValid,
    validationSchema,
  });

  const logo =
    company_detail && !isEmpty(company_detail.logo)
      ? company_detail.logo
      : 'logo.png';

  const imgUrllogo =
    isFilelogo && isFilelogo.imagePreviewUrl === undefined
      ? `${mediaPath + logo}`
      : isFilelogo.imagePreviewUrl;

  const logoUrl =
    career_page && career_page.banner_img
      ? career_page.banner_img
      : 'slider1.jpg';

  const imgUrl =
    isFile && isFile.imagePreviewUrl === undefined
      ? `${mediaPath + logoUrl}`
      : isFile.imagePreviewUrl;

  // formik free fill initial value set
  useEffect(() => {
    if (career_page) {
      formik.setFieldValue('pagaeUrl', career_page.career_page_url);
    }

    if (career_page && !isEmpty(career_page.page_font_size)) {
      formik.setFieldValue(
        'pageFontSize',
        career_page.page_font_size.toString(),
      );
    } else {
      formik.setFieldValue('pageFontSize', '14');
    }

    if (career_page && !isEmpty(career_page.page_font)) {
      formik.setFieldValue('pageFontStyle', career_page.page_font);
    } else {
      formik.setFieldValue('pageFontStyle', 'Helvetica');
    }

    if (career_page && !isEmpty(career_page.header_font_size)) {
      formik.setFieldValue(
        'headerFontSize',
        career_page.header_font_size.toString(),
      );
    } else {
      formik.setFieldValue('headerFontSize', '14');
    }

    if (career_page && !isEmpty(career_page.header_color)) {
      formik.setFieldValue('headerColor.hex', career_page.header_color);
    } else {
      formik.setFieldValue('headerColor.hex', '#ffffff');
    }
    if (career_page && !isEmpty(career_page.font_color)) {
      formik.setFieldValue('fontColor.hex', career_page.font_color);
    } else {
      formik.setFieldValue('fontColor.hex', '#333333');
    }

    if (career_page) {
      formik.setFieldValue('menu1', career_page.menu_1);
      formik.setFieldValue('menu2', career_page.menu_2);
      formik.setFieldValue('menu3', career_page.menu_3);
      formik.setFieldValue('aboutText', career_page.about_us);
      formik.setFieldValue('bannerText', career_page.banner_text);
    }

    if (career_page && !isEmpty(career_page.menu_1_url)) {
      formik.setFieldValue('menu1Url', career_page.menu_1_url);
    } else {
      formik.setFieldValue('menu1Url', 'https://');
    }

    if (career_page && !isEmpty(career_page.menu_2_url)) {
      formik.setFieldValue('menu2Url', career_page.menu_2_url);
    } else {
      formik.setFieldValue('menu2Url', 'https://');
    }

    if (career_page && !isEmpty(career_page.menu_3_url)) {
      formik.setFieldValue('menu3Url', career_page.menu_3_url);
    } else {
      formik.setFieldValue('menu3Url', 'https://');
    }

    if (career_page && !isEmpty(career_page.banner_header_text)) {
      formik.setFieldValue('bannerHeadingText', career_page.banner_header_text);
    } else {
      formik.setFieldValue('bannerHeadingText', '');
    }

    if (career_page && !isEmpty(career_page.banner_heading_size)) {
      formik.setFieldValue(
        'bannerHeadingFontSize',
        career_page.banner_heading_size.toString(),
      );
    } else {
      formik.setFieldValue('bannerHeadingFontSize', '20');
    }

    if (career_page && !isEmpty(career_page.banner_font_size)) {
      formik.setFieldValue(
        'bannerTextFontSize',
        career_page.banner_font_size.toString(),
      );
    } else {
      formik.setFieldValue('bannerTextFontSize', '14');
    }

    if (career_page && !isEmpty(career_page.button_color)) {
      formik.setFieldValue('btnColor.hex', career_page.button_color);
    } else {
      formik.setFieldValue('btnColor.hex', '#581845');
    }

    if (career_page && !isEmpty(career_page.footer_color)) {
      formik.setFieldValue('footerColor.hex', career_page.footer_color);
    } else {
      formik.setFieldValue('footerColor.hex', '#ffffff');
    }
  }, [career_page, isLoading]);

  // set url imgage
  const handleUrlClick = () => {
    setCareerImgClick(true);
    setPageSetUpImgClick(false);
    setHeaderSetUpImgClick(false);
    setBannerSetUpImgClick(false);
    setFooterSetUpImgClick(false);
  };
  // set page setup imgage
  const handlePageSetUpClick = () => {
    setPageSetUpImgClick(true);
    setCareerImgClick(false);
    setHeaderSetUpImgClick(false);
    setBannerSetUpImgClick(false);
    setFooterSetUpImgClick(false);
  };
  // set header setup imgage
  const handleHeaderSetUp = () => {
    setHeaderSetUpImgClick(true);
    setCareerImgClick(false);
    setPageSetUpImgClick(false);
    setBannerSetUpImgClick(false);
    setFooterSetUpImgClick(false);
  };
  // set banner setup imgage
  const handleBannerSetUp = () => {
    setBannerSetUpImgClick(true);
    setHeaderSetUpImgClick(false);
    setCareerImgClick(false);
    setPageSetUpImgClick(false);
    setFooterSetUpImgClick(false);
  };
  // set footer setup imgage
  const handleFooterSetUp = () => {
    setFooterSetUpImgClick(true);
    setBannerSetUpImgClick(false);
    setHeaderSetUpImgClick(false);
    setCareerImgClick(false);
    setPageSetUpImgClick(false);
  };

  const previewUrl = career_page && career_page.career_page_url;

  // url api call for validation
  useEffect(() => {
    if (!isEmpty(formik.values.pagaeUrl) && isInput) {
      dispatch(urlVerificationMiddleWare({ url: formik.values.pagaeUrl })).then(
        (res) => {
          if (
            res.payload.success === 1
            // career_page &&
            // career_page.career_page_url !== formik.values.pagaeUrl
          ) {
            setUrlError(true);
          } else {
            setUrlError(false);
          }
        },
      );
    }
  }, [formik.values.pagaeUrl]);

  const back_navigation = () => {
    history.push('/account_setting');
  };

  if (!step3 && isLoading) {
    return <Loader />;
  }

  return (
    <>
      {step3 && <Flex className={styles.overlaybackground}></Flex>}
      {status && (
        <Highlighter
          left={"38px"}
          top={"388px"}
          text={"Build Career Page"}
        />
      )}
      {step3 &&
        <Tour
          process={() => {
            if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '3/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post')
            ) {
              return '3/6';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '3/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/6';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '3/7';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/6';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/6';
            } else if (Permission1.includes('manage_account_settings')) {
              return '3/5';
            } else if (Permission1.includes('create_post')) {
              return '3/5';
            } else if (Permission1.includes('bulkImport_candidates')) {
              return '3/6';
            } else if (Permission1.includes('talent_sourcing')) {
              return '3/5';
            } else {
              return '3/5';
            }
            return null;
          }}
          skiponclick={() => {
            setstep3(false);
            setcurrentindex(3);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0')
            dispatch(tourdataget({ skip_id: 3, restart_status: 0, explore: 0 })).
              then((res) => {
                history.push("/account_setting/profiles")
              })
          }}
          previousonclick={() => {
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0')
            history.push("/account_setting/profiles")
            setstep3(false)
          }}
          nextonclick={() => {
            setstep3(false);
            if (Permission1.includes('create_post')) {
              sessionStorage.setItem('JobsDropdown', '1');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              history.push("/jobs/create_your_job")
            }
            else if (Permission1.includes('bulkImport_candidates')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '1');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0')
              history.push("/bulk_import")
            }
            else if (Permission1.includes('talent_sourcing')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '1');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              // sessionStorage.setItem('SourcingCandidateDropdown', '1')
              history.push("/candidates_sourcing?tab=0")
            }
            else {
              if (super_user) {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0')
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem('wk_id', pipelineData[0]?.wk_id.toString());
                history.push("/account_setting/hiring_workflow");
              } else {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0')
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem('wk_id', pipelineData[0]?.wk_id.toString());
                history.push("/account_setting/hiring_workflow");
              }
            }
          }}
          borderTop
          previous
          types={"arrowLeft"}
          top={"283px"}
          left={"205px"}
          // text={"Visualize your company's digital front door – the 'Careers Page'. This is where potential candidates get a glimpse of your culture and values. Through this portal, you’ll showcase open roles and invite talent to become part of your story. While on this tour, consider how you'll express your brand’s unique narrative"}
          text={'<div style="font-weight: bold; margin-bottom: 15px;">Visualize your company\'s digital front door – the \'Careers Page\'.</div> This is where potential candidates get a glimpse of your culture and values.<div style="margin-top:10px;">Through this portal, you’ll showcase open roles and invite talent to become part of your story.</div>'}
        />}

      <Flex row className={styles.ribbon} marginBottom={5} between>
        <Flex marginTop={9} marginLeft={8}>
          <Text size={16} bold color="theme">
            Build Careers Page
          </Text>
        </Flex>
        <Flex>
          <div className={styles.triangle}></div>
        </Flex>
      </Flex>

      {
        company_detail &&
          company_detail.no_of_emp === null
          ?
          career_page_exists_build === false && (
            <Flex row center className={styles.warningFlex}>
              <SvgInfo
                height={16}
                width={16}
                fill={'#2E6ADD'}
              />
              <Text
                style={{ color: '#333333' }}
                className={styles.warningText}
              >
                <Text
                  style={{
                    color: '#2E6ADD',
                    marginRight: '3px',
                    fontSize: '13px',
                  }}
                  bold
                >
                  Heads Up!{' '}
                </Text>
                In order to post a job, you must complete the
                required fields in the company profile and
                careers page.
              </Text>
            </Flex>
          )
          :
          // tabKey === '1' &&
          career_page_exists_build === false && (
            <Flex row center className={styles.warningFlex}>
              <SvgInfo
                height={16}
                width={16}
                fill={'#2E6ADD'}
              />
              <Text
                style={{ color: '#333333' }}
                className={styles.warningText}
              >
                <Text
                  style={{
                    color: '#2E6ADD',
                    marginRight: '3px',
                    fontSize: '13px',
                  }}
                  bold
                >
                  Heads Up!{' '}
                </Text>
                In order to post a job, you must complete the
                required fields in the careers page.
              </Text>
            </Flex>
          )}



      {isInput &&
        isLoadingCareer === false &&
        // tabKey === '1' &&
        career_page_exists_build === true && (
          <Flex row center className={styles.warningFlex}>
            <SvgInfo height={16} width={16} fill={'#2E6ADD'} />
            <Text
              style={{ color: '#333333' }}
              className={styles.warningText}
            >
              <Text
                style={{
                  color: '#2E6ADD',
                  marginRight: '3px',
                  fontSize: '13px',
                }}
                bold
              >
                Heads Up!{' '}
              </Text>
              Changing your careers page URL will change the URL
              for all jobs in your careers page. Note to change
              the jobs URL in your company’s careers page if
              connected.
            </Text>
          </Flex>
        )}
      <Flex row>
        <Flex row marginTop={5} marginLeft={16}>
          <Flex
            style={{
              fontSize: '15px',
              color: '#581845',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => back_navigation()}
          >
            Account Settings
          </Flex>
          <Flex marginTop={7} marginLeft={5}>
            <SvgRight fill={'#581845'}></SvgRight>
          </Flex>
          <Flex marginLeft={5} style={{ fontSize: '15px', fontWeight: 'bold' }}>
            Build Careers Page
          </Flex>
        </Flex>
      </Flex>

      <Flex
        row
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          paddingBottom: '10px',
        }}
      >
        {!step3 && isSubmitLoader && <Loader />}
        <Flex columnFlex width={'40%'} className={styles.leftFlex}>
          {isCareerImgClick && (
            <Card className={styles.careerPageCard}>
              <img
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_HOME_URL}media/careerpage/Career_Setup.png`}
                alt="Career Page URL"
              />
            </Card>
          )}
          {isPageSetUpImgClick && (
            <Card className={styles.pageCard}>
              <img
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_HOME_URL}media/careerpage/Page_Setup.png`}
                alt="Page Setup"
              />
            </Card>
          )}
          {isHeaderSetUpImgClick && (
            <Card className={styles.headerCard}>
              <img
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_HOME_URL}media/careerpage/Header_Setup.png`}
                alt="Header Setup"
              />
            </Card>
          )}
          {isBannerSetUpImgClick && (
            <Card className={styles.bannerCard}>
              <img
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_HOME_URL}media/careerpage/Banner_Setup.png`}
                alt="Banner Setup"
              />
            </Card>
          )}
          {isFooterSetUpImgClick && (
            <Card className={styles.footerCard}>
              <img
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_HOME_URL}media/careerpage/Footer_Setup.png`}
                alt="Footer Setup"
              />
            </Card>
          )}
        </Flex>
        <Flex columnFlex width={'60%'} className={styles.rightFlex}>
          <div style={{ width: '100%', padding: '0 10px' }}>
            <ExpandTile
              backgroundColor="#58184530"
              activeColor="#000000"
              title={<CareerTitle />}
              show={tileState?.careersPage}
              styles={{
                border: 'none',
                marginTop: '10px',
                boxShadow: '0 1px 4px 0 rgb(0 0 0 / 47%)',
              }}
              contentStyles={{ padding: '10px' }}
              onClick={() =>
                setTileState({
                  ...tileState,
                  careersPage: !tileState.careersPage,
                })
              }
            >
              <div
                onClick={handleUrlClick}
                tabIndex={-1}
                role="button"
                onKeyDown={() => { }}
              >
                <CareersPageURL
                  formik={formik}
                  career_page={career_page}
                  isInput={isInput}
                  setInput={setInput}
                  setReload={() => {
                    setReloadCareer(true);
                    setBtnDisable(false);
                  }}
                  isUrlError={isUrlError}
                />
              </div>
            </ExpandTile>
            <ExpandTile
              backgroundColor="#58184530"
              activeColor="#000000"
              title={<PageSetupTitle />}
              show={tileState?.pageSetup}
              styles={{
                border: 'none',
                marginTop: '10px',
                boxShadow: '0 1px 4px 0 rgb(0 0 0 / 47%)',
              }}
              contentStyles={{ padding: '10px' }}
              onClick={() =>
                setTileState({
                  ...tileState,
                  pageSetup: !tileState.pageSetup,
                })
              }
            >
              <div
                onClick={handlePageSetUpClick}
                tabIndex={-1}
                role="button"
                onKeyDown={() => { }}
              >
                <PageSetup
                  formik={formik}
                  setReload={() => {
                    setReloadCareer(true);
                    setBtnDisable(false);
                  }}
                />
              </div>
            </ExpandTile>
            <ExpandTile
              backgroundColor="#58184530"
              activeColor="#000000"
              title={<HeaderSetupTitle />}
              show={tileState?.headerSetup}
              styles={{
                border: 'none',
                marginTop: '10px',
                boxShadow: '0 1px 4px 0 rgb(0 0 0 / 47%)',
              }}
              contentStyles={{ padding: '10px' }}
              onClick={() =>
                setTileState({
                  ...tileState,
                  headerSetup: !tileState.headerSetup,
                })
              }
            >
              <div
                onClick={handleHeaderSetUp}
                tabIndex={-1}
                role="button"
                onKeyDown={() => { }}
              >
                <HeaderSetup
                  formik={formik}
                  company_detail={company_detail}
                  setFile={setFilelogo}
                  imgUrl={imgUrllogo}
                  isFilelogo={isFilelogo}
                  onDirty={onDirty}
                  setBtnDisable={setBtnDisable}
                  setReload={() => {
                    setReloadCareer(true);
                    setBtnDisable(false);
                  }}
                />
              </div>
            </ExpandTile>
            <ExpandTile
              backgroundColor="#58184530"
              activeColor="#000000"
              title={<BannerSetupTitle />}
              show={tileState?.bannerSetup}
              styles={{
                border: 'none',
                marginTop: '10px',
                boxShadow: '0 1px 4px 0 rgb(0 0 0 / 47%)',
              }}
              contentStyles={{ padding: '10px' }}
              onClick={() =>
                setTileState({
                  ...tileState,
                  bannerSetup: !tileState.bannerSetup,
                })
              }
            >
              <div
                onClick={handleBannerSetUp}
                tabIndex={-1}
                role="button"
                onKeyDown={() => { }}
              >
                <BannerSetup
                  setBtnDisable={setBtnDisable}
                  formik={formik}
                  setFile={setFile}
                  imgUrl={imgUrl}
                  setReload={() => {
                    setReloadCareer(true);
                    setBtnDisable(false);
                  }}
                />
              </div>
            </ExpandTile>
            <ExpandTile
              backgroundColor="#58184530"
              activeColor="#000000"
              title={<FooterSetupTitle />}
              show={tileState?.buttonAndFooterSetup}
              styles={{
                border: 'none',
                marginTop: '10px',
                boxShadow: '0 1px 4px 0 rgb(0 0 0 / 47%)',
              }}
              contentStyles={{ padding: '10px' }}
              onClick={() =>
                setTileState({
                  ...tileState,
                  buttonAndFooterSetup: !tileState.buttonAndFooterSetup,
                })
              }
            >
              <div
                onClick={handleFooterSetUp}
                tabIndex={-1}
                role="button"
                onKeyDown={() => { }}
              >
                <FooterSetup
                  formik={formik}
                  setReload={() => {
                    setReloadCareer(true);
                    setBtnDisable(false);
                  }}
                />
              </div>
            </ExpandTile>
          </div>
        </Flex>
      </Flex>

      <Flex row between className={styles.actions}>
        <Flex>
          {/* <LinkWrapper to="/"> */}
          <Button onClick={() => window.location.href = ('/')} types="primary">Dashboard</Button>
          {/* </LinkWrapper> */}
        </Flex>
        <Flex row>
          {isEmpty(previewUrl) ? (
            <Button types="secondary" className={styles.previewBtn} disabled>
              Preview
            </Button>
          ) : (
            <LinkWrapper
              target={'_blank'}
              replace
              to={`/${previewUrl}/careers`}
            >
              <Button types="secondary" className={styles.previewBtn}>
                Preview
              </Button>
            </LinkWrapper>
          )}
          <Button
            onClick={() => {
              if (
                !isEmpty(formik.errors.pagaeUrl) ||
                isEmpty(formik.errors.pagaeUrl)
              ) {
                getFocus('CareersPageURL___urlInput');
              }
              formik.handleSubmit();
            }}
            disabled={isBtnDisable}
          >
            Save
          </Button>
          {/* {routerPrompt} */}
        </Flex>
      </Flex>
    </>
  );
};

export default BuildYourCareerPageScreen;
