import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  LINKEDIN_SOURCING,
  LINKEDIN_UNLOCK_CANDIDATES,
  LINKEDIN_UNLOCK_CANDIDATES_IDS,
} from '../../../../actions/actions';
import {
  LinkedinSourcing,
  LinkedinUnlockCandidatesget,
  LinkedinUnlockCandidates,
} from '../../../../routes/apiRoutes';
import Toast from '../../../../uikit/Toast/Toast';
import { paramsSerializer } from '../../../../utility/helpers';
import { ERROR_MESSAGE } from '../../../constValue';
import {
  LinkedinSourcingSearchPayload,
  LinkedinSourcingunloackedcandidates,
  pdfview,
} from '../../talentSourcingTypes';

export const LinkedinSourcingSearchMiddleWare = createAsyncThunk(
  LINKEDIN_SOURCING,
  async (
    {
      location,
      job_title,
      pagecount,
      skill,
      linkedin_id,
      education,
      expirence_gte,
      expirence_lte,
    }: LinkedinSourcingSearchPayload,
    { rejectWithValue },
  ) => {
    try {
      const { data } = (await axios.get(LinkedinSourcing, {
        params: {
          location,
          job_title,
          skill,
          pagecount,
          linkedin_id,
          education,
          expirence_gte,
          expirence_lte,
        },
        paramsSerializer,
      })) as any;

      return data;
    } catch (error) {
      const typedError = error as Error;
      Toast(ERROR_MESSAGE, 'LONG', 'error');
      return rejectWithValue(typedError.message);
    }
  },
);

export const linkedincandidateunlock = createAsyncThunk(
  LINKEDIN_UNLOCK_CANDIDATES,
  async ({ formData }: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(LinkedinSourcing, formData);

      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);

export const unlockedcandidateget = createAsyncThunk(
  LINKEDIN_UNLOCK_CANDIDATES_IDS,
  async ({ candidates_id }: pdfview, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(LinkedinUnlockCandidatesget, {
        params: {
          candidates_id,
        },
        paramsSerializer,
      });

      return data;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError);
    }
  },
);
